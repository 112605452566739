import React, { Component } from 'react';
import OptimizerContext from './OptimizerContext'
import './OptimizerAlert.css';
import axios from 'axios'
import authHeader from '../authentication/authHeader'
import history from './../../history';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
class OptimizerAlert extends Component {
    static contextType = OptimizerContext
    intervalID;
    previousCompleted = 0;

    constructor(props) {
        super(props);
        this.state = {
            enabledGFFCancelButton: true,
            enabledGFFStopButton: true,
            showGFFStopedFeedback: false,
            showGFFCancelConfirmation: false,
            showGFFStopConfirmation: false,

            enabledRamdomSwapCancelButton: true,
            enabledRandomSwapStopButton: true,
            showRandomSwapStopedFeedback: false,
            showRandomSwapCancelConfirmation: false,
            showRandomSwapStopConfirmation: false,

            enabledWhatIfCancelButton: true,
            enabledWhatIfStopButton: true,
            showWhatIfStopedFeedback: false,
            showWhatIfCancelConfirmation: false,
            showWhatIfStopConfirmation: false,

            noProgressCounter: 0,
        }
    }

    componentDidMount() {
        this.getData();
    }
    componentWillUnmount() {
        clearTimeout(this.intervalID);
    }
    enableTimer = () => {
        this.intervalID = setTimeout(this.getData.bind(this), window['runConfig'].notification.startInterval);
    }
    cancelRandomSwapConfirmation = () => {
        this.setState({showRandomSwapCancelConfirmation: true})
    }
    hideCancelRandomSwapConfirmation = () => {
        this.setState({showRandomSwapCancelConfirmation: false})
    }
    stopRandomSwapConfirmation = () => {
        this.setState({showRandomSwapStopConfirmation: true})
    }
    hideStopRandomSwapConfirmation = () => {
        this.setState({showRandomSwapStopConfirmation: false})
    }
    cancelRandomSwap = () => {
        this.setState({enabledRamdomSwapCancelButton: false, showRandomSwapCancelConfirmation: false})
        axios.get('/cancelRandomSwap/', { headers: authHeader() })
        .then(response => {
            //do nothing
        })
        .catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        })
    }
    cancelGFFConfirmation = () => {
        this.setState({showGFFCancelConfirmation: true})
    }
    hideCancelGFFConfirmation = () => {
        this.setState({showGFFCancelConfirmation: false})
    }
    stopGFFConfirmation = () => {
        this.setState({showGFFStopConfirmation: true})
    }
    hideStopGFFConfirmation = () => {
        this.setState({showGFFStopConfirmation: false})
    }
    cancelGFF = () => {
        this.setState({enabledGFFCancelButton: false, showGFFCancelConfirmation: false})
        axios.get('/cancelGFF', { headers: authHeader() })
        .then(response => {
            //do nothing
        })
        .catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        })
    }
    stopRandomSwap = () => {
        this.setState({enabledRandomSwapStopButton: false, showStopConfirmation: false})
        axios.get('/stopRandomSwap', { headers: authHeader() })
        .then(response => {
            this.setState({showRandomSwapStopedFeedback: true})
        })
        .catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        })
    }
    stopGFF = () => {
        this.setState({enabledGFFStopButton: false, showGFFStopConfirmation: false})
        axios.get('/stopGFF', { headers: authHeader() })
        .then(response => {
            this.setState({showGFFStopedFeedback: true})
        })
        .catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        })
    }
    cancelWhatIfConfirmation = () => {
        this.setState({ showWhatIfCancelConfirmation: true })
    }
    hideCancelWhatIfConfirmation = () => {
        this.setState({ showWhatIfCancelConfirmation: false })
    }
    cancelWhatIf = () => {
        this.setState({ enabledWhatIfCancelButton: false, showWhatIfCancelConfirmation: false })
        axios.get('/cancelWhatIf', { headers: authHeader() })
            .then(response => {
                //do nothing
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    stopWhatIfConfirmation = () => {
        this.setState({ showWhatIfStopConfirmation: true })
    }
    hideStopWhatIfConfirmation = () => {
        this.setState({ showWhatIfStopConfirmation: false })
    }
    stopWhatIf = () => {
        this.setState({ enabledWhatIfStopButton: false, showWhatIfStopConfirmation: false })
        axios.get('/stopWhatIf', { headers: authHeader() })
            .then(response => {
                this.setState({ showWhatIfStopedFeedback: true })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    getData = () => {
        const { setOptimizerContext } = this.context
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.accessToken) {
            axios.get('/optimizationstatus/', { headers: authHeader() })
            .then(response => {
                setOptimizerContext({
                    status: response.data.status, 
                    completed: response.data.completed, 
                    total: response.data.total, 
                    clinicname: response.data.clinicname,
                    clinicid: response.data.clinicid,
                    enableTimer: this.enableTimer.bind(this),
                    type: response.data.type,
                    isRunning: response.data.status.length > 0,
                })
                if (this.previousCompleted === response.data.completed) {
                    if (this.state.noProgressCounter > 11) {
                        this.setState(prevState => {
                            return {
                                noProgressCounter: prevState.noProgressCounter + 1,
                                showGFFCancelConfirmation: false,
                            }
                        })
                    } else {
                        this.setState(prevState => {
                            return {
                                noProgressCounter: prevState.noProgressCounter + 1
                            }
                        })
                    }
                } else {
                    this.previousCompleted = response.data.completed
                    this.setState({noProgressCounter: 0})
                }
                if (response.data.status && response.data.status.length > 0) this.intervalID = setTimeout(this.getData.bind(this), window['runConfig'].notification.subsequentInterval);
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        }
    }
    notificationRandomBar = () => {
        const { optimizerContext } = this.context;
        if (optimizerContext.status === "Y" && this.state.noProgressCounter <= 12) {
            //Random swap is in progress
            return (
                <div style={{display:'inline'}}>
                    <ReactTooltip />
                    <span>An optimization is in progress for {optimizerContext.clinicname} - [{optimizerContext.completed} profiles assigned out of {optimizerContext.total}] DO NOT START another GFF or random swap or What-if</span>
                    <button type="button" className="btn btn-outline-danger ml-2" data-tip="Cancel Random Swap" onClick={this.cancelRandomSwapConfirmation} disabled={!this.state.enabledRamdomSwapCancelButton}>Cancel</button>
                    <Modal show={this.state.showRandomSwapCancelConfirmation} onHide={this.hideCancelRandomSwapConfirmation.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                        <Modal.Title>Cancel Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to cancel the running Optimization?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" data-tip="Yes" onClick={this.cancelRandomSwap.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="secondary" data-tip="No" onClick={this.hideCancelRandomSwapConfirmation.bind(this)}>
                            No
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        } else if (optimizerContext.status === "Y" && this.state.noProgressCounter > 12) {
            //Random swap got struck
            if (this.state.showRandomSwapStopedFeedback) {
                return (
                    <div style={{display:'inline'}}>
                        <span>An optimization was stopped for {optimizerContext.clinicname}</span>
                    </div>
                )
            } else {
                return (
                    <div style={{display:'inline'}}>
                        <ReactTooltip />
                        <span>An optimization is taking longer time than expected for {optimizerContext.clinicname} - [{optimizerContext.completed} profiles assigned out of {optimizerContext.total}] DO NOT START another GFF or random swap or What-if</span>
                        <button type="button" className="btn btn-outline-danger ml-2" data-tip="Stop Random Swap" onClick={this.stopRandomSwapConfirmation} disabled={!this.state.enabledRandomSwapStopButton}>Stop</button>
                        <Modal show={this.state.showRandomSwapStopConfirmation} onHide={this.hideStopRandomSwapConfirmation.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                            <Modal.Title>Stop Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to stop optimization?</Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.stopRandomSwap.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.hideStopRandomSwapConfirmation.bind(this)}>
                                No
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )
            }
        } else if (optimizerContext.status === "C") {
            return (
                //Random swap is getting cancelled
                <div style={{display:'inline'}}>
                    <span>An optimization is canceling for {optimizerContext.clinicname} - DO NOT START another GFF or random swap or What-if</span>
                    <button type="button" className="btn btn-outline-secondary ml-2" disabled data-tip="Cancel Random Swap">Cancel</button>
                </div>
            )
        }
    }
    notificationGFFBar = () => {
        const { optimizerContext } = this.context;
        if (optimizerContext.status === "Y" && this.state.noProgressCounter <= 12) {
            //GFF is in progress
            return (
                <div style={{display:'inline'}}>
                    <ReactTooltip />
                    <span>A GFF is in progress for {optimizerContext.clinicname} - [{optimizerContext.completed} profiles assigned out of {optimizerContext.total}] DO NOT START another GFF or random swap or What-if</span>
                    <button type="button" className="btn btn-outline-danger ml-2" data-tip="Cancel GFF" onClick={this.cancelGFFConfirmation} disabled={!this.state.enabledGFFCancelButton}>Cancel</button>
                    <Modal show={this.state.showGFFCancelConfirmation} onHide={this.hideCancelGFFConfirmation.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                        <Modal.Title>Cancel Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to cancel the running GFF?</Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" data-tip="Yes" onClick={this.cancelGFF.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="secondary" data-tip="No" onClick={this.hideCancelGFFConfirmation.bind(this)}>
                            No
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        } else if (optimizerContext.status === "Y" && this.state.noProgressCounter > 12) {
            //GFF got struck
            if (this.state.showGFFStopedFeedback) {
                return (
                    <div style={{display:'inline'}}>
                        <span>A GFF was stopped for {optimizerContext.clinicname}</span>
                    </div>
                )
            } else {
                return (
                    <div style={{display:'inline'}}>
                        <ReactTooltip />
                        <span>A GFF is taking longer time than expected for {optimizerContext.clinicname} - [{optimizerContext.completed} profiles assigned out of {optimizerContext.total}] DO NOT START another GFF or random swap or What-if</span>
                        <button type="button" className="btn btn-outline-danger ml-2" data-tip="Stop GFF" onClick={this.stopGFFConfirmation} disabled={!this.state.enabledGFFStopButton}>Stop</button>
                        <Modal show={this.state.showGFFStopConfirmation} onHide={this.hideStopGFFConfirmation.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                            <Modal.Title>Stop Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to stop GFF?</Modal.Body>
                            <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.stopGFF.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.hideStopGFFConfirmation.bind(this)}>
                                No
                            </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )
            }
        } else if (optimizerContext.status === "C") {
            return (
                //GFF is getting cancelled
                <div style={{display:'inline'}}>
                    <span>A GFF is cancelling for {optimizerContext.clinicname} - DO NOT START another GFF or random swap or What-if</span>
                    <button type="button" className="btn btn-outline-secondary ml-2" disabled data-tip="Cancel GFF">Cancel</button>
                </div>
            )
        }
    }
    notificationWhatIfBar = () => {
        const { optimizerContext } = this.context;
        if ((optimizerContext.status === "Y" || optimizerContext.status === "S") && this.state.noProgressCounter <= 12) {
            //What-if is in progress
            return (
                <div style={{ display: 'inline' }}>
                    <ReactTooltip />
                    <span>A What-if is in progress for {optimizerContext.clinicname} - [{optimizerContext.completed} tries out of {optimizerContext.total} - {optimizerContext.status === "S" ? 'checking constraints':'finding slots'}] DO NOT START another GFF or random swap or What-if</span>
                    <button type="button" className="btn btn-outline-danger ml-2" data-tip="Cancel What-if" onClick={this.cancelWhatIfConfirmation} disabled={!this.state.enabledWhatIfCancelButton}>Cancel</button>
                    <Modal show={this.state.showWhatIfCancelConfirmation} onHide={this.hideCancelWhatIfConfirmation.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Cancel Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to cancel the running What-if?<p>Cancelling will remove this solution.</p></Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.cancelWhatIf.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.hideCancelWhatIfConfirmation.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )
        } else if ((optimizerContext.status === "Y" || optimizerContext.status === "S") && this.state.noProgressCounter > 12) {
            //What-if got struck
            if (this.state.showWhatIfStopedFeedback) {
                return (
                    <div style={{ display: 'inline' }}>
                        <span>A What-if was stopped for {optimizerContext.clinicname}</span>
                    </div>
                )
            } else {
                return (
                    <div style={{ display: 'inline' }}>
                        <ReactTooltip />
                        <span>A What-if is taking longer time than expected for {optimizerContext.clinicname} - [{optimizerContext.completed} profiles assigned out of {optimizerContext.total}] DO NOT START another GFF or random swap or What-if</span>
                        <button type="button" className="btn btn-outline-danger ml-2" data-tip="Stop What-if" onClick={this.stopWhatIfConfirmation} disabled={!this.state.enabledWhatIfStopButton}>Stop</button>
                        <Modal show={this.state.showWhatIfStopConfirmation} onHide={this.hideStopWhatIfConfirmation.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Stop Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to stop What-if?<p>Cancelling will remove this solution.</p></Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.stopWhatIf.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.hideStopWhatIfConfirmation.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                )
            }
        } else if (optimizerContext.status === "C") {
            return (
                //GFF is getting cancelled
                <div style={{ display: 'inline' }}>
                    <span>A What-if is cancelling for {optimizerContext.clinicname} - DO NOT START another GFF or random swap or What-if</span>
                    <button type="button" className="btn btn-outline-secondary ml-2" disabled data-tip="Cancel GFF">Cancel</button>
                </div>
            )
        }
    }
    render() {
        const { optimizerContext } = this.context
        return(
            <div id="optimizer-alert">
               <div className={`alert alert-warning justify-content-center ${optimizerContext.isRunning ? "block" : "hidden"}`} role="alert">
                    <div className="message" style={{display:(optimizerContext.type === "random" ? 'block':'none')}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-bookmark-star-fill" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.178.178 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.178.178 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.178.178 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.178.178 0 0 1-.134-.098L8.16 4.1z"/>
                        </svg>&nbsp;
                        {
                            this.notificationRandomBar()
                        }
                    </div>
                    <div className="message" style={{display:(optimizerContext.type === "gff" ? 'block':'none')}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="red" className="bi bi-bookmark-star-fill" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.178.178 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.178.178 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.178.178 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.178.178 0 0 1-.134-.098L8.16 4.1z"/>
                        </svg>&nbsp;                        
                        {
                            this.notificationGFFBar()
                        }
                    </div>
                    <div className="message" style={{ display: (optimizerContext.type === "whatif" ? 'block' : 'none') }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" fill="red" className="bi bi-bookmark-star-fill" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5zM8.16 4.1a.178.178 0 0 0-.32 0l-.634 1.285a.178.178 0 0 1-.134.098l-1.42.206a.178.178 0 0 0-.098.303L6.58 6.993c.042.041.061.1.051.158L6.39 8.565a.178.178 0 0 0 .258.187l1.27-.668a.178.178 0 0 1 .165 0l1.27.668a.178.178 0 0 0 .257-.187L9.368 7.15a.178.178 0 0 1 .05-.158l1.028-1.001a.178.178 0 0 0-.098-.303l-1.42-.206a.178.178 0 0 1-.134-.098L8.16 4.1z" />
                        </svg>&nbsp;
                        {
                            this.notificationWhatIfBar()
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default OptimizerAlert
