import React, { Component } from 'react'
import './Standardcodes.css'
import Clinics from './Clinics'
import UserProfile from '../UserProfile'
import Skills from './skills/Skills'
import ResourceType from './resourcetype/ResourceType'
import Resources from './resources/Resources'
import ServiceType from './servicestype/ServicesType'
import Services from './services/Services'
import Organization from './ogranization/Organization'
import Client from './client/Client'
import AuthService from '../authentication/AuthService'
import history from '../../history'
class Standardcode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codetype: "",
            name: ''
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(event) {
        const { name, value } = event.target
        this.setState({ [name]: value })
    }
    componentDidMount() {
        const user = AuthService.getCurrentUser();
        if (!user) {
            history.push('/Errorpage')
        }
        this.setState({
            codetype: UserProfile.getPage()
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title" >
                    <h4>Standard Codes {this.state.name}</h4>
                </div>
                <div className="form-row align-items-center">
                    <div className="col-auto my-1">
                        <select className="form-control mr-sm-2" value={this.state.codetype} onChange={this.handleChange}
                            name="codetype">
                            <option value="0">-- Select Standard Code Type ---</option>
                            <option value="Organization">Organization</option>
                            <option value="Client">Client</option>
                            <option value="Clinics">Clinics</option>
                            <option value="Skills">Skills</option>
                            <option value="Resource Types">Resource Types</option>
                            <option value="Resources">Resources</option>
                            <option value="Service Types">Service Types</option>
                            <option value="Services">Services</option>
                        </select>
                    </div>
                </div>
                {this.state.codetype === 'Clinics'
                    ? <Clinics />
                    : this.state.codetype === 'Skills'
                        ? <Skills />
                        : this.state.codetype === 'Resource Types'
                            ? <ResourceType />
                            : this.state.codetype === 'Resources'
                                ? <Resources />
                                : this.state.codetype === 'Service Types'
                                    ? <ServiceType />
                                    : this.state.codetype === 'Services'
                                        ? <Services />
                                        : this.state.codetype === 'Organization'
                                            ? <Organization />
                                            : this.state.codetype === 'Client'
                                                ? <Client />
                                                : null
                }
            </div>
        )
    }
}

export default Standardcode
