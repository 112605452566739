import React, { Component } from "react";
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
import { RequiredTitle } from '../../utils/Util'
import ReactTooltip from 'react-tooltip';
import UserProfile from '../../UserProfile'
import history from '../../../history';
import axios from 'axios'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'

class OrganizationAdd extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            name: '',
            description: '',
            displayOrder: 1,
            activeStatus: 'Y',
            showError: false,
            errorMsg: '',
            organizations: [],
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/organizations', { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        displayOrder: Math.max.apply(Math, response.data.map(function (o) { return o.displayOrder + 1; })),
                        organizations: response.data,
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    saveClickHandler = () => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        const t = this.state.organizations.filter(e => e.name === this.state.name)
        if (t.length > 0) {
            this.setState({
                showError: true,
                errorMsg: `'${this.state.name}' name already exists`
            });
            return;
        }

        axios.post('/organization', {
            codesetId: 7,
            name: this.state.name,
            description: this.state.description,
            displayOrder: this.state.displayOrder,
            activeStatus: this.state.activeStatus,
            deletedYN: 'N',
            createdBy: AuthService.getCurrentUserName(),
            lastUpdatedBy: AuthService.getCurrentUserName()
        }, { headers: authHeader() })
            .then(response => {
                axios.get('/organizations', { headers: authHeader() })
                    .then(orgresp => {
                        let orgs = []
                        const aorgs = orgresp.data.filter(e => e.activeStatus === 'Y')
                        aorgs.forEach(e => {
                            orgs.push({ id: e.codesetItemId, name: e.name, displayOrder: e.displayOrder })
                        })
                        orgs.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                        let t = UserProfile.getGlobalClientParams()
                        t.organizations = orgs
                        localStorage.setItem("client", JSON.stringify(t));
                        UserProfile.setPage("Organization");
                        UserProfile.setOrganizationId(response.data.codesetItemId)
                        UserProfile.setHighlights([response.data.codesetItemId], 'success')
                        history.push('/standardcode');
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    cancelClickHandler = () => {
        UserProfile.setPage("Organization");
        UserProfile.setHighlights([], 'success')
        history.push('/standardcode');
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Organization</h4>
                </div>
                <RequiredTitle />
                <div style={{ display: (this.state.showError ? 'block' : 'none') }}>
                    <label htmlFor="error" style={{ color: 'red' }}>{this.state.errorMsg}</label>
                </div>
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto">Name<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="name"
                                    value={this.state.name}
                                    ref={this.setFocus}
                                    onChange={(e) => this.setState({ [e.target.name]: e.target.value, showError: false })}
                                    className="form-control" placeholder="Name" />
                                    {this.validator.message('Name', this.state.name, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td>Description<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><textarea className="form-control"
                                    name="description"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                    rows="3" placeholder="Description"></textarea>Maximum 200 characters
                                    {this.validator.message('Description', this.state.description, 'required|maxLength:200')}
                                </td>
                            </tr>
                            <tr>
                                <td>Display Order<span className='text-danger ml-1'>*</span></td>
                                <td><input type="text"
                                    name="displayOrder"
                                    value={this.state.displayOrder}
                                    onChange={(e) => this.setState({ [e.target.name]: e.target.value })}
                                    className="col-lg-2 form-control" pattern="[0-9]*" maxLength="3" />
                                    {this.validator.message('Display Order', this.state.displayOrder, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={(e) => this.setState({ [e.target.name]: e.target.value })} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.saveClickHandler} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClickHandler} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default OrganizationAdd