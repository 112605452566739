import React from 'react'

export const beforeMaskedValueChange = (newState, oldState, userInput) => {
    var { value } = newState;
    var selection = newState.selection;
    
    const t = value.split(':')
    const h = t[0].replaceAll('_', '')
    const m = t[1]?.replaceAll('_', '')
    if (parseInt(h) > 2 && parseInt(h) < 10 && h.length < 2) {
        value = '0' + t[0]
        selection = { start: 3, end: 3 }
    }
    if (t[1]?.includes('_')) {
        if (parseInt(m) > 5 && parseInt(m) < 10 && m.length === 1){
            value = h
            selection = { start: 3, end: 3 };
        }
    }
    return {
        value,
        selection
    };
}

export const RequiredTitle = () => (
    <h6><span className='text-danger'>*</span> Denotes Required</h6>
)

export const findPageIndex = (dataset, key, ids) => {
    const t = dataset.findIndex((e) => e[key] === ids[0])
    if (t === -1) return 1
    const tt = t + 1
    const q = parseInt(tt / 10)
    const r = tt % 10
    return r > 0 ?  q + 1 : q
}