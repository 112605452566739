import React, { Component } from 'react'
import history from '../../../history';
import { Form } from 'reactstrap';
import axios from 'axios'
import moment from 'moment'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
class HighLevelFilterCD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesList: [],
            serviceId: '',
            servicesTypeList: [],
            serviceTypeName: '',
            servicetypeId: '',
            clinicId: '',
            description: '',
            activeStatus: 'Y',
            errors: [],
            notassigningList: [],
            costMonday: [],
            costTuesday: [],
            highlevelFilter: []
        }
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.setState({
            clinicId: UserProfile.getClinicId(),
            serviceId: UserProfile.getServiceId(),
        })
        
        axios.get('/highlevelfilterforservice/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(), { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        highlevelFilter: response.data
                    })
                    this.getNotassigningpdk()
                } else {
                    this.insertHighlevelfilter()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getNotassigningpdk() {
        await axios.get('/notassigningcd/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(),{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        notassigningList: response.data
                    })
                    for (let index = 0; index < response.data.length; index++) {
                        var costCD = 'costCD' + index
                        document.getElementById(costCD).value = response.data[index].cdvalue
                    }
                }
                else {
                    this.getClinicstimingrows()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }

    async getNotassigningpdkold() {
        await axios.get('/notassigningpdk/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(),{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        notassigningList: response.data
                    })
                } else {
                    this.getClinicstimingrows()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async insertHighlevelfilter() {
        var today = new Date();
        await axios.post('/highlevelfilter', {
            clinicId: this.state.clinicId, serviceId: this.state.serviceId,
            costNotassigningPDk: '0', costStartingaservicePDStartk: '0', costNotassigningCD: '0',
            createdBy: AuthService.getCurrentUserName(), createdDate: moment(today).format('YYYY-MM-DD'),
            lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD')
        },{ headers: authHeader() })
            .then(response => {
                this.getClinicstimingrows()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }

    async getClinicstimingrows() {
let addNotassigningpdk=[]
        await axios.get('/clinicstimingrows/' + UserProfile.getClinicId(),{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    let starttime = response.data[0][0]
                    let timeintorg = response.data[0][2]
                    let timeint=timeintorg
                    for (let index = 0; index < response.data[0][4]; index++) {
                        addNotassigningpdk.push( this.addNotassigningpdk(timeint, index + 1))
                        timeint= timeint+timeintorg
                    }
                    Promise.all(addNotassigningpdk).then(() => this.getNotassigningpdk());
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async updateClinicNullDays() {
        await axios.put('/notassigningpdkupdate/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(),'',{ headers: authHeader() })
            .then(response => {
                alert('notassigningpdkupdate')
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async updateClinicDay() {
        await axios.get('/clinicstiming/' + this.state.clinicId,{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    for (let index = 0; index < response.data.length; index++) {
                        const element = response.data[index];
                        /* axios.put('/notassigningpdkupdateday/' + UserProfile.getSelectedName() + '/' + item[1] + '/' + moment(item[2], 'HH:mm:ss').format('HH:mm:ss') + '/' + moment(item[3], 'HH:mm:ss').format('HH:mm:ss')) */
                        axios.put('/notassigningpdkupdateday/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId() + '/' + response.data[index][1], { startTime: moment(response.data[index][2], 'HH:mm:ss').format('HH:mm:ss'), endTime: moment(response.data[index][3], 'HH:mm:ss').format('HH:mm:ss') },{ headers: authHeader() })
                            .then(response => {

                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })

                    }
                    this.getNotassigningpdk()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async addNotassigningpdk(duration, slot) {
        var today = new Date();
        await axios.post('/notassigningcd', {
            clinicId: this.state.clinicId, serviceId: this.state.serviceId,
            slot: slot, duration: duration, cdvalue: 0,
            createdBy: AuthService.getCurrentUserName(), createdDate: moment(today).format('YYYY-MM-DD'),
            lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD')
        },{ headers: authHeader() })
            .then(response => {

            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    Apply() {
        let selVal = document.getElementById('selectValue').value
        let timeVal = document.getElementById('selectSlot').value
        var sel = document.getElementById('selectSlot')
        var selTimeVal = sel.options[sel.selectedIndex].value
       
            if (selTimeVal == 'all') {
                for (let index = 0; index < this.state.notassigningList.length; index++) {
                    var costCD = 'costCD' + index
                    if (document.getElementById(costCD) !== null) {
                        document.getElementById(costCD).value = selVal
                    }
                }
            } else {
                var costCD = 'costCD' + selTimeVal
                if (document.getElementById(costCD) !== null) {
                    document.getElementById(costCD).value = selVal
                }
            }
    }
    Update() {
        let updateNotassigningList = []
        for (let index = 0; index < this.state.notassigningList.length; index++) {

            var costCD = 'costCD' + index
            var selValCD = 0
            if (document.getElementById(costCD) !== null) {
                selValCD = document.getElementById(costCD).value
            }
            let d = {
                id: this.state.notassigningList[index].id, clinicId: this.state.notassigningList[index].clinicId, serviceId: this.state.notassigningList[index].serviceId,
                slot: this.state.notassigningList[index].slot,duration:this.state.notassigningList[index].duration, cdvalue: selValCD >= 0 ? selValCD : this.state.notassigningList[index].cdvalue
            }
            updateNotassigningList.push(d)
        }
        axios.post('/updatenotassigningcd', updateNotassigningList,{ headers: authHeader() })
            .then(response => {
                this.updateHighlevelFilter()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    updateHighlevelFilter() {
        axios.get('/highlevelfilterforservice/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(),{ headers: authHeader() })
            .then(response => {
                axios.put('/highlevelfilter/' + response.data[0].filterId, {
                    filterId: response.data[0].filterId,
                    clinicId: response.data[0].clinicId, serviceId: response.data[0].serviceId, costNotassigningPDk: response.data[0].costNotassigningPDk,
                    costStartingaservicePDStartk: response.data[0].costStartingaservicePDStartk, costNotassigningCD: 1
                },{ headers: authHeader() })
                    .then(response => {
                        history.push('/clinic/'+UserProfile.getClinicId()+'/7')
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    Cancel() {
        history.push('/clinic/'+UserProfile.getClinicId()+'/7')
    }
    handleChange(event) {
        const { name, value } = event.target
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
        }))
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Score of Not assigning a service to any valid timeslot - C(D)</h4>
                </div>
                <Form id="ClinicServicesAdd" method="post">
                    <div className="section-title containerAdd">
                        <div className="d-flex justify-content-end form-row form-inline mb-2">
                        <ReactTooltip />
                            <button type="button" className="btn btn-secondary mr-2" onClick={this.Cancel.bind(this)} data-tip="Close">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.Update.bind(this)} data-tip="Update">Update</button>
                        </div>
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="col-auto"><b>Clinic Name</b></td>
                                    <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Services</td>
                                    <td className="col-auto">{UserProfile.getServiceName()}</td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Action</td>
                                    <td className="col-auto">Score of Not assigning a service to any valid timeslot - C(D)</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-end form-row form-inline mb-2">
                            <label className="form-check-label mr-2">Value:</label>
                            <input type="text" className="form-control col-md-1 form-group mr-2" placeholder="125" id="selectValue" autoFocus/>
			                <select className="form-control col-lg-2 mr-2" id="selectSlot">
                                <option value="-1">--  Select Slot --</option>
                                <option value='all'>All</option>
                                {this.state.notassigningList.length > 0
                                    && this.state.notassigningList.map((notassigning, i) => {
                                        return (
                                            <option key={i} value={i}>{notassigning.slot}</option>
                                        )
                                    })}
                            </select>
			                    <a href="#"><button type="button" className="btn btn-secondary mb-1" onClick={this.Apply.bind(this)} align="right" data-tip="Apply">Apply</button></a>
                                <ReactTooltip />
                        </div>
                        <div>
                            <table className="table table-striped table-bordered table-hover table-sm data-sortable='true'" >
                                <thead className="thead-dark">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" data-sortable="true"># of Slots</th>
                                        <th scope="col" data-sortable="true">Duration (minutes)</th>
                                        <th scope="col" data-sortable="true">C(D)</th>
                                    </tr>
                                </thead>
                                <tbody className="table-hover">
                                    {


                                        this.state.notassigningList.length > 0 && this.state.notassigningList.map((item, key) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{key + 1}</td>
                                                    <td>{item.slot}</td>
                                                    <td>{item.duration}</td>
                                                    <td>{item.cdvalue >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={'costCD' + key}
                                                        onChange={this.handleChange}
                                                        id={'costCD' + key} className="form-control col-lg-4" placeholder="" /> : ''} </td>

                                                </tr>

                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <div className="d-flex justify-content-end form-row form-inline">
                            <ReactTooltip />
                                <button type="button" className="btn btn-secondary mb-1" onClick={this.Cancel.bind(this)} data-tip="Close">Close</button>&nbsp;<button type="button" className="btn btn-primary mb-1" align="right" onClick={this.Update.bind(this)} data-tip="Update">Update</button>
                            </div>

                        </div>
                    </div>
                </Form>
            </div >
        )
    }
}

export default HighLevelFilterCD
