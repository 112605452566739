import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import multisort from 'multisort';
import { findPageIndex } from '../../utils/Util'
import { Store } from 'react-notifications-component'
import ocsHighlighRow from '../../utils/OcsHighlighRow'
class ClinicResources extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicresourceList: [],
            codesetItemId: '0',
            resourceTypeList: [],
            resourceList: [],
            resourceName: '',
            resourceId: '',
            resourceTypeName: '',
            resourceTypeId: '',
            showList: false,
            showModal: false,
            sortConfig: {
                'resourceName': { dataField: 'resourceName', order: 'asc' },
            },
        }
        this.AddOpen = this.AddOpen.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.getResourceList = this.getResourceList.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getResourceList()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.clinicresourceList, 'resourcesId', UserProfile.getHightlightIds())
        this.setState({ clinicresourceList: this.state.clinicresourceList });
        this.node.paginationContext.currPage = p;
    }
    getResourceList() {
        axios.get('/clinicresourcesforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
            .then(response2 => {
                axios.get('/resources', { headers: authHeader() })
                    .then(response3 => {
                        axios.get('/resourcetypes', { headers: authHeader() })
                            .then(response4 => {
                                let dataset = [...response2.data]
                                dataset = dataset.map((t) => ({ ...t, resourceName: response3.data.find((r) => r.codesetItemId === t.resourceId).name }))
                                dataset = dataset.map((t) => ({ ...t, resourceTypeName: response4.data.find((r) => r.codesetItemId === t.resourceTypeId).name }))
                                dataset.sort((a, b) => { return a.resourceName < b.resourceName ? -1 : a.resourceName > b.resourceName ? 1 : 0 })
                                this.setState({
                                    clinicresourceList: dataset,
                                    codesetItemId: UserProfile.getClinicId(),
                                    showList: true,
                                    resourceList: response3.data,
                                    resourceTypeList: response4.data
                                });
                                this.gotoPage.click();
                            })
                            .catch(function (error) {
                                console.log('response4 : ', error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log('response3 : ', error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log('response2 : ', error);
                history.push('/Errorpage')
            })
    }
    AddOpen() {
        history.push('/ClinicResourcesadd')
    }
    EditOpen(id) {
        history.push('/ClinicResourcesedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/clinicresourcesdel/' + this.state.resourceId, '', { headers: authHeader() })
            .then(response => {
                this.getResourceList()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `Resource '${this.state.resourceName}' for '${UserProfile.getClinicName()}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name) {
        this.setState({
            resourceId: id,
            resourceName: name,
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'sno',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '5%' }
            },
            {
                dataField: 'resourceName',
                text: 'Resource',
                sort: true,
                headerStyle: { width: '35%' },
            },
            {
                dataField: 'resourceTypeName',
                text: 'Resource Type',
                sort: true,
                headerStyle: { width: '36%' },
            },
            {
                dataField: 'quantity',
                text: 'Quantity',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right'
            },

            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '14%', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" data-tip="Edit" onClick={this.EditOpen.bind(this, row.resourcesId)}>Edit</button>&nbsp;
                        <button type="button" className="btn btn-primary" data-tip="Delete" onClick={this.handleShow.bind(this, row.resourcesId, this.state.resourceList.filter(resource => resource.codesetItemId === row.resourceId).map((x, i) => { return x.name }))}>Delete</button>
                    </div>
                ),
                align: 'center'
            }

        ];
        return (
            <div>
                <div className="form-row align-items-center my-1">
                    <div className="col">
                        <div className="float-right">
                            <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                                <div className="d-flex justify-content-end">
                                    <ReactTooltip />
                                    {this.state.clinicresourceList.length === this.state.resourceList.length ?
                                        <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" disabled onClick={this.AddOpen} >Add Resource</button>
                                        :
                                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen} >Add Resource</button>
                                    }
                                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <div>
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete the resource <b>{this.state.resourceName}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            keyField='resourcesId'
                            data={this.state.clinicresourceList}
                            columns={columns}
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory()}
                            noDataIndication="No Record(s)"
                            striped hover condensed
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default ClinicResources
