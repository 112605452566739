import React, { Component } from 'react'
import history from './../../history';
import UserProfile from '../UserProfile'
import axios from 'axios'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import ocsHighlighRow from '../utils/OcsHighlighRow'
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../utils/Util'
class Home extends Component {
    constructor(props) {
        super(props)
        this.daylist = []
        this.state = {
            solutionList: [],
            solutionListOrg: [],
            codesetItemId: '0',
            clinicList: [],
            oncosmartSystemId: '',
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            all: 'checked',
            fromDate: '',
            toDate: '',
            showModal: false,
            solutionid: '',
            selectedday: '',
            showTransModal: false,
            enableApplyButton: false,
            showDeleteAllModal: false,
            sortConfig: {
                'pid': { dataField: 'pid', order: 'asc' },
            },
        }
        this.handleChange = this.handleChange.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.handleChangecheckbox = this.handleChangecheckbox.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        let client = JSON.parse(localStorage.getItem('client'));
        UserProfile.setGlobalClientParams(client)
        axios.get('/aclinicslist/' + UserProfile.getGlobalClientParams().id, { headers: authHeader() })
            .then(response => {
                let cid = 0
                let ocsId = ''
                let fdt = ''
                let tdt = ''
                let btnEnabled = false
                let m = false
                let t = false
                let w = false
                let th = false
                let f = false
                let s = false
                let su = false
                if (UserProfile.getClinicId() > 0) {
                    const tt = UserProfile.getSelectedName().split(',')
                    ocsId = tt[2]
                    cid = UserProfile.getClinicId()
                    fdt = UserProfile.getNavParams().homeDates.fromDate
                    tdt = UserProfile.getNavParams().homeDates.toDate
                    btnEnabled = true
                    m = UserProfile.getNavParams().selectedWeekdays.monday
                    t = UserProfile.getNavParams().selectedWeekdays.tuesday
                    w = UserProfile.getNavParams().selectedWeekdays.wednesday
                    th = UserProfile.getNavParams().selectedWeekdays.thursday
                    f = UserProfile.getNavParams().selectedWeekdays.friday
                    s = UserProfile.getNavParams().selectedWeekdays.saturday
                    su = UserProfile.getNavParams().selectedWeekdays.sunday
                }
                this.setState({
                    clinicList: response.data,
                    oncosmartSystemId: ocsId,
                    fromDate: fdt,
                    toDate: tdt,
                    enableApplyButton: btnEnabled,
                    monday: m,
                    tuesday: t,
                    wednesday: w,
                    thursday: th,
                    friday: f,
                    saturday: s,
                    sunday: su,
                    all: !(m || t || w || th || f || s || su),
                    codesetItemId: cid,
                }, () => {
                    if (UserProfile.getClinicId() > 0) {
                        this.Apply(true)
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.solutionList, 'pid', UserProfile.getHightlightIds())
        this.setState({ solutionList: this.state.solutionList });
        this.node.paginationContext.currPage = p;
    }
    handleChange(event) {
        event.target.type === "date"
            ? this.setState({
                [event.target.name]: event.target.value,
            })
            : this.setState({
                [event.target.name]: event.target.value,
                codesetItemId: this.state.clinicList.filter(cli => cli.oncosmartSystemId === parseInt(event.target.value)).map((x, i) => { return x.codesetItemId }),
            })
        if (document.getElementById('oncosmartSystemId').selectedIndex > 0) {
            if (event.target.type === "select-one") {
                this.setState({
                    enableApplyButton: true,
                    fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
                    toDate: moment().subtract(0, 'days').format('YYYY-MM-DD')
                });
            }
        } else {
            this.setState({
                enableApplyButton: false,
                fromDate: '',
                toDate: '',
                solutionList: [],
                solutionListOrg: [],
                solutionid: ''
            });
        }
    }
    handleChangecheckbox(event) {
        const target = event.target
        if (target.name === 'all') {
            this.setState({
                [target.name]: target.checked,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
            }, () => {
                this.dayOfWeekChecked()
            })
        } else {
            if (target.checked) {
                this.setState({
                    [target.name]: target.checked,
                    all: false
                }, () => {
                    this.dayOfWeekChecked()
                })
            } else {
                this.setState({
                    [target.name]: target.checked,
                }, () => {
                    if (!(this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.sunday)) {
                        this.setState({
                            [target.name]: target.checked,
                            all: true
                        }, () => {
                            this.dayOfWeekChecked()
                        })
                    } else {
                        this.dayOfWeekChecked()
                    }
                })
            }
        }
    }
    dayOfWeekChecked() {
        let newArr = []
        if (this.state.all) {
            newArr = [...this.state.solutionListOrg]
        } else {
            let tt = []
            if (this.state.monday) tt = [...tt, 'Monday']
            if (this.state.tuesday) tt = [...tt, 'Tuesday']
            if (this.state.wednesday) tt = [...tt, 'Wednesday']
            if (this.state.thursday) tt = [...tt, 'Thursday']
            if (this.state.friday) tt = [...tt, 'Friday']
            if (this.state.saturday) tt = [...tt, 'Saturday']
            if (this.state.sunday) tt = [...tt, 'Sunday']
            for (let index = 0; index < this.state.solutionListOrg.length; index++) {
                const element = this.state.solutionListOrg[index];
                if (tt.includes(moment(element.day).format('dddd'))) {
                    newArr.push(element)
                }
            }
        }
        this.setState({ solutionList: newArr })
    }

    Apply(applyFilter = false) {
        let soluList = []
        let soluid = 0
        axios.get('/getListOfSolutionsForClinicAndLocation/' + this.state.oncosmartSystemId + '/' + this.state.fromDate + '/' + this.state.toDate, { headers: authHeader() })
            .then(response => {
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    soluid = element[0]
                    let d = {
                        id: index + 1, pid: element[0], day: element[1], date: element[2], description: element[3], cost: element[4], Assigned: element[5] + ' / ' + element[6], Unassigned: element[6],
                        transferYN: element[7], swapstatus: element[8]
                    }
                    soluList.push(d)
                }
                soluList.sort((a, b) => { return a.pid < b.pid ? -1 : a.pid > b.pid ? 1 : 0 })
                UserProfile.setClinicId(this.state.clinicList.filter(cli => cli.oncosmartSystemId === parseInt(this.state.oncosmartSystemId)).map((x, i) => { return x.codesetItemId }))
                this.setState({
                    solutionList: soluList,
                    solutionListOrg: soluList,
                    solutionid: soluid
                }, () => {
                    if (applyFilter) this.dayOfWeekChecked()
                });
                this.gotoPage.click();
                UserProfile.setHighlights([], 'success')
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    ViewSolution(id, day) {
        let d = (moment(day).day() + 1)
        UserProfile.setScheduleDate(day)
        UserProfile.setSelectedName(this.state.codesetItemId + "," + d + "," + this.state.oncosmartSystemId)
        UserProfile.setSelectedId(id)
        UserProfile.setClinicId(this.state.codesetItemId + '')
        UserProfile.setNavParams({
            selectedId: id,
            homeDates: { fromDate: this.state.fromDate, toDate: this.state.toDate },
            selectedWeekdays: { monday: this.state.monday, tuesday: this.state.tuesday, wednesday: this.state.wednesday, thursday: this.state.thursday, friday: this.state.friday, saturday: this.state.saturday, sunday: this.state.sunday }
        })
        UserProfile.setNavigatedFrom('/home')
        history.push('/viewsolutionlist')
    }
    DeleteSolution() {
        axios.put('/deleteInfoForSolutionID/' + this.state.solutionid, '', { headers: authHeader() })
            .then(response => {
                this.Apply()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `Deleted '${this.state.solutionid}' solution for '${UserProfile.getClinicName()}'`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    DeleteAllSolution() {
        axios.put('/deleteAllInfoForSolutionID/' + this.state.solutionid, '', { headers: authHeader() })
            .then(response => {
                this.Apply()
                this.handleDeleteAllClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `Deleted all '${this.state.selectedday}' solution(s) for '${UserProfile.getClinicName()}'`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleDeleteAllClose() {
        this.setState({
            showDeleteAllModal: false
        })
    }
    handleShow(id, day) {
        this.setState({
            solutionid: id,
            selectedday: day,
            showModal: true

        })
    }
    handleDeleteAllShow(id, day) {
        this.setState({
            solutionid: id,
            selectedday: day,
            showDeleteAllModal: true
        })
    }
    handlTransClose() {
        this.setState({
            showTransModal: false
        })
    }
    handlTransShow(id) {
        this.setState({
            solutionid: id,
            showTransModal: true

        })
    }
    TransferSoulution(id) {
        axios.put('/transferSolution/' + this.state.solutionid, '', { headers: authHeader() })
            .then(response => {
                this.Apply()
                this.handlTransClose()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleDownloadExcel(id, day) {
        fetch(window['runConfig'].apiUrl + 'downloadexcel/' + id, { headers: authHeader() })
            .then(response => {
                const filename = UserProfile.getClinicName() + "_" + day + "_" + id + '.xlsx'
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
    }

    render() {
        const columns = [
            {
                dataField: 'pid',
                text: 'Solution Id',
                sort: true,
                headerStyle: { width: '7%' }
            },
            {
                dataField: 'day',
                text: 'Day(s)',
                sort: true,
                headerStyle: { width: '10%' },
                formatter: (cell, row) => (moment(row.day).format('dddd'))
            },
            {
                dataField: 'date',
                text: 'Date & Time Created',
                sort: true,
                headerStyle: { width: '15%' },
                formatter: (cell, row) => (
                    moment(row.date).format('YYYY-MM-DD H:mm:ss')
                )
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                headerStyle: { width: '35%' }
            },
            {
                dataField: 'cost',
                text: 'Score',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right',
                formatter: (cell, row) => (
                    row.cost === 0 && row.swapstatus === null ? '-' : row.cost.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                )
            },
            {
                dataField: 'Assigned',
                text: 'Assigned / Unassigned',
                sort: true,
                headerStyle: { width: '10%' }
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '13%', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary btn-sm" data-tip="View Solution" onClick={this.ViewSolution.bind(this, row.pid, row.day)}>VS</button>&nbsp;
                        {row.transferYN === 'N' ?
                            <button type="button" className="btn btn-primary btn-sm" data-toggle="tooltip" data-placement="top" data-tip="Transfer Solution" onClick={this.handlTransShow.bind(this, row.pid)}>TS</button>
                            : <button type="button" className="btn btn-success btn-sm" disabled data-toggle="tooltip" data-placement="top" data-tip="Transferred Solution">TS</button>
                        }
                        &nbsp;<button type="button" className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" data-tip="Delete Solution" onClick={this.handleShow.bind(this, row.pid, row.day)}>D</button>
                        &nbsp;<button type="button" className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" data-tip="Delete All Solutions" onClick={this.handleDeleteAllShow.bind(this, row.pid, row.day)}>D-All</button>
                        &nbsp;<button type="button" className="btn btn-primary btn-sm mt-1" data-toggle="tooltip" data-placement="top" data-tip="Download Solution In Excel Format" onClick={this.handleDownloadExcel.bind(this, row.pid, row.day)}>Ex</button>
                    </div>
                )
            }

        ];
        const rowStyle = { height: '10px' };
        return (
            <div className="container-fluid">
                <div>
                    <h1>Welcome to OncoSmart Schedule Optimizer!</h1>
                </div>
                <br></br>
                <div className="section-title" >
                    <h4>List of Solutions </h4>
                </div>
                <div className="form-row align-items-center">
                    <div className="col-auto my-1">
                        <select className="form-control mr-sm-1" value={this.state.oncosmartSystemId}
                            name="oncosmartSystemId" id="oncosmartSystemId" onChange={this.handleChange}>
                            <option value="0">--  Select Clinic  --</option>
                            {this.state.clinicList.length > 0
                                && this.state.clinicList.map((clinic, i) => {
                                    return (
                                        <option key={i} value={clinic.oncosmartSystemId}>{clinic.name}</option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className="col-auto my-1">
                        Date:
                    </div>
                    <div className="col-auto my-1">
                        <input type="date" id='fromDate' name='fromDate' value={this.state.fromDate} onChange={this.handleChange} max="3000-12-31" min="1000-01-01" className="form-control" required />
                    </div>
                    <div className="col-auto my-1">
                        and
                    </div>
                    <div className="col-auto my-1">
                        <input type="date" id='toDate' name='toDate' value={this.state.toDate} onChange={this.handleChange} max="3000-12-31" min="1000-01-01" className="form-control" required />
                    </div>
                    <div className="col-auto my-1">
                        <ReactTooltip />
                        <button type="submit" className="btn btn-primary mb-1" onClick={this.Apply.bind(this)} data-tip="Apply" disabled={!this.state.enableApplyButton}>Apply</button>
                        <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="sunday"
                            value={this.state.sunday}
                            checked={this.state.sunday}
                            onChange={this.handleChangecheckbox}
                            id="sunday" />
                        <label className="form-check-label" htmlFor="sunday">Sun</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="monday"
                            value={this.state.monday}
                            checked={this.state.monday}
                            onChange={this.handleChangecheckbox}
                            id="monday" />
                        <label className="form-check-label" htmlFor="monday">Mon</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="tuesday"
                            value={this.state.tuesday}
                            checked={this.state.tuesday}
                            onChange={this.handleChangecheckbox}
                            id="tuesday" />
                        <label className="form-check-label" htmlFor="tuesday">Tue</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="wednesday"
                            value={this.state.wednesday}
                            checked={this.state.wednesday}
                            onChange={this.handleChangecheckbox}
                            id="wednesday" />
                        <label className="form-check-label" htmlFor="wednesday">Wed</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="thursday"
                            value={this.state.thursday}
                            checked={this.state.thursday}
                            onChange={this.handleChangecheckbox}
                            id="thursday" />
                        <label className="form-check-label" htmlFor="thursday">Thu</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="friday"
                            value={this.state.friday}
                            checked={this.state.friday}
                            onChange={this.handleChangecheckbox}
                            id="friday" />
                        <label className="form-check-label" htmlFor="friday">Fri</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="saturday"
                            value={this.state.saturday}
                            checked={this.state.saturday}
                            onChange={this.handleChangecheckbox}
                            id="saturday" />
                        <label className="form-check-label" htmlFor="saturday">Sat</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="all"
                            value={this.state.all}
                            checked={this.state.all}
                            onChange={this.handleChangecheckbox}
                            id="all" />
                        <label className="form-check-label" htmlFor="all">All</label>
                    </div>
                </div>
                <div style={{ display: (this.state.showTransModal ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                    <Modal show={this.state.showTransModal} onHide={this.handlTransClose.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Transfer Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to transfer the <b>solution {this.state.solutionid}</b>?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.TransferSoulution.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.handlTransClose.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div style={{ display: (this.state.showModal ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                    <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete the solution <b>{this.state.solutionid}</b>?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.DeleteSolution.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div style={{ display: (this.state.showDeleteAllModal ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                    <Modal show={this.state.showDeleteAllModal} onHide={this.handleDeleteAllClose.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete all the solutions belonging to solution <b>{this.state.solutionid}</b>?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.DeleteAllSolution.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.handleDeleteAllClose.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                {<BootstrapTable
                    remote={{
                        filter: false,
                        pagination: false,
                        sort: true,
                        cellEdit: false
                    }}
                    keyField='pid'
                    data={this.state.solutionList}
                    columns={columns}
                    rowStyle={rowStyle}
                    striped bordered hover condensed
                    ref={(n) => (this.node = n)}
                    pagination={paginationFactory()}
                    noDataIndication="No Record(s)"
                    rowClasses='table table-sm'
                    headerClasses="thread"
                    selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                    onTableChange={this.handleTableChange}
                    sort={{ sortCaret: this.headerSortingClasses }}
                />}
            </div>
        )
    }
}

export default Home
