import React, { Component } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ocsHighlighRow from '../../utils/OcsHighlighRow'
import multisort from 'multisort';
import { findPageIndex } from '../../utils/Util'
import { Store } from 'react-notifications-component'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import authHeader from '../../authentication/authHeader'
import history from '../../../history';
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

class Organization extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            dataset: [],
            deleteenable: [],
            selectedOrganizationId: '',
            selectedOrganizationName: '',
            showDeletionConf: false,
            sortConfig: {
                'name': { dataField: 'name', order: 'asc' },
            },
        }
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getOrganizations()
    }
    getOrganizations() {
        axios.get('/checkdeleteenable/7', { headers: authHeader() })
            .then(resp => {
                axios.get('/organizations', { headers: authHeader() })
                    .then(response => {
                        let dataset = [...response.data]
                        dataset.sort((a, b) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
                        this.setState({
                            dataset: dataset,
                            deleteenable: resp.data,
                        });
                        this.gotoPage.click();
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.dataset, 'codesetItemId', UserProfile.getHightlightIds())
        this.setState({ dataset: this.state.dataset });
        this.node.paginationContext.currPage = p;
    }
    addHandler = () => {
        history.push('/organizationadd')
    }
    editHandler(id) {
        UserProfile.setOrganizationId(id)
        history.push('/organizationedit/:' + id)
    }
    preDeleteHandler(id, name) {
        this.setState({
            selectedOrganizationId: id,
            selectedOrganizationName: name,
            showDeletionConf: true
        })
    }
    deletionConfCloseHandler() {
        this.setState({ showDeletionConf: false })
    }
    deleteHandler() {
        axios.delete('/organization/' + this.state.selectedOrganizationId, { headers: authHeader() })
            .then(response => {
                this.getOrganizations()
                this.deletionConfCloseHandler()
                Store.addNotification({
                    title: "Deleted",
                    message: `Organization '${this.state.selectedOrganizationName}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
                axios.get('/organizations', { headers: authHeader() })
                    .then(orgresp => {
                        let orgs = []
                        const aorgs = orgresp.data.filter(e => e.activeStatus === 'Y')
                        aorgs.forEach(e => {
                            orgs.push({ id: e.codesetItemId, name: e.name, displayOrder: e.displayOrder })
                        })
                        orgs.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                        let t = UserProfile.getGlobalClientParams()
                        t.organizations = orgs
                        localStorage.setItem("client", JSON.stringify(t));
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    render() {
        const columns = [
            {
                dataField: 'codesetItemId',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '5%' },
                align: 'right'
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                headerStyle: { width: '19%' }
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                headerStyle: { width: '19%' }
            },
            {
                dataField: 'displayOrder',
                text: 'Display Order',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'activeStatus',
                text: 'Active',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'center' },
                formatter: (cell, row) => (
                    row.activeStatus === 'Y' ? 'Yes' : 'No'
                ),
                align: 'center'
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '14%', textAlign: 'center' },
                formatter: (cellContent, row, index) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary mr-1" onClick={this.editHandler.bind(this, row.codesetItemId)} data-tip="Edit">Edit</button>
                        {this.state.deleteenable.find(x => x === row.codesetItemId) ?
                            <button type="button" className="btn btn-secondary" disabled data-tip="Delete">Delete</button>
                            :
                            <button type="button" className="btn btn-primary" onClick={this.preDeleteHandler.bind(this, row.codesetItemId, row.name)} data-tip="Delete">Delete</button>
                        }
                    </div>
                ),
                align: 'center'
            }
        ]
        return (
            <div>
                <div className="d-flex justify-content-end">
                    <ReactTooltip />
                    <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.addHandler.bind()}>Add Organization</button>
                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                </div>
                <Modal show={this.state.showDeletionConf} onHide={this.deletionConfCloseHandler.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the organization <b>{this.state.selectedOrganizationName}</b>?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" data-tip="Yes" onClick={this.deleteHandler.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="secondary" data-tip="No" onClick={this.deletionConfCloseHandler.bind(this)}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <BootstrapTable
                    remote={{
                        filter: false,
                        pagination: false,
                        sort: true,
                        cellEdit: false
                    }}
                    keyField='codesetItemId'
                    data={this.state.dataset}
                    columns={columns}
                    ref={(n) => (this.node = n)}
                    pagination={paginationFactory()}
                    noDataIndication="No Record(s)"
                    striped hover condensed
                    rowClasses='table table-sm'
                    headerClasses="thread"
                    selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                    onTableChange={this.handleTableChange}
                    sort={{ sortCaret: this.headerSortingClasses }}
                />
            </div>
        )
    }
}

export default Organization