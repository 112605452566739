import React, { Component } from 'react'
import history from './../../history';
import UserProfile from '../UserProfile'
import axios from 'axios'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../authentication/authHeader'
import ProgressBar from 'react-bootstrap/ProgressBar'
import ReactTooltip from 'react-tooltip';
import Alert from 'react-bootstrap/Alert'
import ocsHighlighRow from '../utils/OcsHighlighRow'
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../utils/Util'

class SolutionList extends Component {
    gffFeedback = ""
    gffVariant = ""
    constructor(props) {
        super(props)
        this.state = {
            solutionList: [],
            showTable: false,
            showModal: false,
            solutionid: '',
            selectedday: '',
            showTransModal: false,
            solutionidOrg: '',
            showProgressbar: true,
            showDeleteAllModel: false,
            gffFeedback: '',
            gffVariant: '',
            sortConfig: {
                'pid': { dataField: 'pid', order: 'asc' },
            },
        }
        this.getSolutionList = this.getSolutionList.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        const { data } = this.props.location;
        if (data !== undefined) {
            if (data.gffResponse === 'DONE') {
                this.setState({
                    gffFeedback: 'A GFF has been completed for ' + data.clinicname,
                    gffVariant: 'success'
                })
            } else {
                this.setState({
                    gffFeedback: 'A GFF has been cancelled for ' + data.clinicname,
                    gffVariant: 'info'
                })
            }
        }
        this.getSolutionList()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.solutionList, 'pid', UserProfile.getHightlightIds())
        this.setState({ solutionList: this.state.solutionList });
        this.node.paginationContext.currPage = p;
    }
    getSolutionList() {
        let soluList = []
        let soluid = 0

        if (UserProfile.getNavigatedFrom() === './solutionlist'){
            UserProfile.setSelectedId(UserProfile.getNavParams().selectedId)
            UserProfile.setSelectedName(UserProfile.getNavParams().selectedName)
        }

        axios.get('/solutionlist/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    soluid = response.data[0][0]
                    let d = {
                        id: index + 1, pid: element[0], day: element[1], date: element[2], description: element[3], cost: element[4], Assigned: element[5] + ' / ' + element[6], Unassigned: element[6],
                        transferYN: element[7], swapstatus: element[8]
                    }
                    soluList.push(d)
                }
                soluList.sort((a, b) => { return a.pid < b.pid ? -1 : a.pid > b.pid ? 1 : 0 })
                this.setState({
                    solutionList: soluList,
                    solutionid: UserProfile.getSelectedId(),
                    solutionidOrg: soluid,
                    showProgressbar: false
                });
                this.gotoPage.click();
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    ViewSolution(id) {
        UserProfile.setNavParams({ 
            selectedId: id, 
            selectedName: UserProfile.getSelectedName(),
            selectedWeekdays: UserProfile.getNavParams().selectedWeekdays,
            dayScheduleId: UserProfile.getNavParams().selectedId
        })
        UserProfile.setSelectedId(id)
        UserProfile.setNavigatedFrom('./solutionlist')
        history.push('/viewsolutionlist')
    }
    DeleteSolution(id) {
        UserProfile.setSelectedId(0)
        for (let index = 0; index < this.state.solutionList.length; index++) {
            if (this.state.solutionList[index].pid !== this.state.solutionid)
                UserProfile.setSelectedId(this.state.solutionList[index].pid)
        }
        axios.put('/deleteInfoForSolutionID/' + this.state.solutionid, '', { headers: authHeader() })
            .then(response => {
                this.getSolutionList()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `Deleted '${this.state.solutionid}' solution for '${UserProfile.getClinicName()}'`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    DeleteAllSolution(id) {
        UserProfile.setSelectedId(this.state.solutionidOrg)
        axios.put('/deleteAllInfoForSolutionID/' + this.state.solutionid, '', { headers: authHeader() })
            .then(response => {
                this.getSolutionList()
                this.handleDeleteAllClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `Deleted all '${this.state.selectedday}' solution(s) for '${UserProfile.getClinicName()}'`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleDeleteAllClose() {
        this.setState({
            showDeleteAllModel: false
        })
    }
    handleShow(id, day) {
        this.setState({
            solutionid: id,
            selectedday: day,
            showModal: true
        })
    }
    handleDeleteAllShow(id, day) {
        this.setState({
            solutionid: id,
            selectedday: day,
            showDeleteAllModel: true
        })
    }
    handleDownloadExcel(id, day) {
        fetch(window['runConfig'].apiUrl + 'downloadexcel/' + id, { headers: authHeader() })
            .then(response => {
                const filename = UserProfile.getClinicName() + "_" + day + "_" + id + '.xlsx'
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            });
    }
    handlTransClose() {
        this.setState({
            showTransModal: false
        })
    }
    handlTransShow(id) {
        this.setState({
            solutionid: id,
            showTransModal: true
        })
    }
    TransferSoulution(id) {
        UserProfile.setSelectedId(this.state.solutionidOrg)
        axios.put('/transferSolution/' + this.state.solutionid, '', { headers: authHeader() })
            .then(response => {
                this.setState({
                    transferstatus: response.data
                })
                this.getSolutionList()
                this.handlTransClose()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    BackClickHandler(e) {
        if (UserProfile.getNavParams().dayScheduleId !== undefined){
            UserProfile.setNavParams({
                selectedId: UserProfile.getNavParams().dayScheduleId,
                selectedWeekdays: UserProfile.getNavParams().selectedWeekdays,
            })
        }
        
        UserProfile.setHighlights([UserProfile.getNavParams().selectedId], 'info')
        history.push('/scheduler')
    }
    render() {
        const columns = [
            {
                dataField: 'pid',
                text: 'Solution Id',
                sort: true,
                headerStyle: { width: '7%' }
            },
            {
                dataField: 'day',
                text: 'Day(s)',
                sort: true,
                headerStyle: { width: '10%' }
            },
            {
                dataField: 'date',
                text: 'Date & Time Created',
                sort: true,
                headerStyle: { width: '15%' },
                formatter: (cell, row) => (
                    moment(row.date).format('YYYY-MM-DD H:mm:ss')
                )
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                headerStyle: { width: '35%' }
            },
            {
                dataField: 'cost',
                text: 'Score',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right',
                formatter: (cell, row) => (
                    row.cost === 0 && row.swapstatus == null ? '-' : row.cost.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                )
            },
            {
                dataField: 'Assigned',
                text: 'Assigned / Unassigned',
                sort: true,
                headerStyle: { width: '10%' },
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '13%' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary btn-sm" data-tip="View Solution" onClick={this.ViewSolution.bind(this, row.pid, row.day)}>VS</button>&nbsp;
                        {row.transferYN === 'N' ? <button type="button" className="btn btn-primary btn-sm" data-toggle="tooltip" data-placement="top" data-tip="Transfer Solution" onClick={this.handlTransShow.bind(this, row.pid)}>TS</button>
                            : <button type="button" className="btn btn-success btn-sm" disabled data-toggle="tooltip" data-placement="top" data-tip="Transferred Solution">TS</button>
                        }
                        &nbsp;<button type="button" className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" data-tip="Delete Solution" onClick={this.handleShow.bind(this, row.pid, row.day)}>D</button>
                        &nbsp;<button type="button" className="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" data-tip="Delete All Solutions" onClick={this.handleDeleteAllShow.bind(this, row.pid, row.day)}>D-All</button>
                        &nbsp;<button type="button" className="btn btn-primary btn-sm mt-1" data-toggle="tooltip" data-placement="top" data-tip="Download Solution In Excel Format" onClick={this.handleDownloadExcel.bind(this, row.pid, row.day)}>Ex</button>
                    </div>

                )
            }

        ];
        const rowStyle = { height: '10px' };
        return (
            <div>
                <div className="container-fluid">
                    <div className="section-title" >
                        <h4>List of Solutions <small className="text-muted">{UserProfile.getClinicName()} [{moment(UserProfile.getScheduleDate()).format("DD/MM/YYYY")} {moment(UserProfile.getScheduleDate()).format('dddd')}]</small></h4>
                    </div>
                </div>
                <Alert show={this.state.gffFeedback.length} variant={this.state.gffVariant} onClose={() => this.state.gffFeedback = ''} dismissible>
                    <p>{this.state.gffFeedback}</p>
                </Alert>
                <div style={{ display: (this.state.showProgressbar ? 'block' : 'none') }} >
                    <div >
                        <ProgressBar striped variant="info" animated now={100} label={'Generating the solution. Please wait...'} className="form-check-label font-weight-bold" />
                    </div>
                </div>

                <div className="container-fluid">
                    <div style={{ display: (this.state.showTransModal ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                        <Modal show={this.state.showTransModal} onHide={this.handlTransClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Transfer Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to transfer the <b>solution {this.state.solutionid}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.TransferSoulution.bind(this, this.state.solutionid)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handlTransClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div style={{ display: (this.state.showModal ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete the <b>solution {this.state.solutionid}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteSolution.bind(this, this.state.solutionid)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div style={{ display: (this.state.showDeleteAllModel ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                        <Modal show={this.state.showDeleteAllModel} onHide={this.handleDeleteAllClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete all the solutions in <b>{this.state.selectedday}</b> schedule?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteAllSolution.bind(this, this.state.solutionid)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleDeleteAllClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        <div className='d-flex justify-content-end mb-1'>
                            <button className="btn btn-sm btn-secondary" data-tip="Go Back" onClick={this.BackClickHandler.bind(this)}>Back</button>
                            <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                        </div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            keyField='pid'
                            data={this.state.solutionList}
                            columns={columns}
                            rowStyle={rowStyle}
                            striped bordered hover condensed
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory()}
                            noDataIndication="No Record(s)"
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default SolutionList
