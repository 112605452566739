import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class DonotbookPatientsAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            all: false,
            dayOfWeek: '',
            startTime: 'HH:mm',
            endTime: 'HH:mm',
            activeStatus: 'Y',
            clinicId: '',
            clinicServiceTypeList: [],
            servicetypeId: '',
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }

    componentDidMount() {
        axios.get('/servicetypeslist', { headers: authHeader() })
            .then(response1 => {
                axios.get('/clinicserviceTypesforClinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                    .then(response2 => {
                        this.setState({
                            serviceTypeList: response1.data,
                            clinicServiceTypeList: response2.data,
                            clinicId: UserProfile.getClinicId()
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addDonotbookPatientsAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        let donotbookRes = []
        this.dayOfWeekChecked().map(d => {
            const a = d
            donotbookRes.push({
                clinicId: this.state.clinicId, 
                servicetypeId: this.state.servicetypeId, 
                dayOfWeek: a, 
                startTime: this.state.startTime, 
                endTime: this.state.endTime,
                activeStatus: this.state.activeStatus, 
                deletedYN: 'N', 
                createdBy: AuthService.getCurrentUserName(),
                lastUpdatedBy: AuthService.getCurrentUserName()
            })
        })
        axios.post('/donotbook', donotbookRes, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights(response.data, 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/8')
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    dayOfWeekChecked() {
        let arr = []
        if (this.state.all === true) {
            arr = [1, 2, 3, 4, 5, 6, 7];
        } else {
            if (this.state.sunday === true) arr.push(1);
            if (this.state.monday === true) arr.push(2);
            if (this.state.tuesday === true) arr.push(3);
            if (this.state.wednesday === true) arr.push(4);
            if (this.state.thursday === true) arr.push(5);
            if (this.state.friday === true) arr.push(6);
            if (this.state.saturday === true) arr.push(7);
        }
        return arr;
    }
    cancelDonotbookPatientsAdd = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/8')
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        const type = target.type
        const checked = target.checked

        type == "checkbox" ?
            this.setState({
                [field]: checked,
            })
            :
            this.setState({
                [field]: value
            });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Do not book Patients</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                </td>
                            </tr>

                            <tr>
                                <td className="col-auto">Day(s)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-row col-auto my-1 d-flex justify-content-start">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="monday"
                                                value={this.state.monday}
                                                onChange={this.handleChange}
                                                ref={this.setFocus}
                                                id="monday" />
                                            <label className="form-check-label" htmlFor="monday">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="tuesday"
                                                value={this.state.tuesday}
                                                onChange={this.handleChange}
                                                id="tuesday" />
                                            <label className="form-check-label" htmlFor="tuesday">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="wednesday"
                                                value={this.state.wednesday}
                                                onChange={this.handleChange}
                                                id="wednesday" />
                                            <label className="form-check-label" htmlFor="wednesday">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="thursday"
                                                value={this.state.thursday}
                                                onChange={this.handleChange}
                                                id="thursday" />
                                            <label className="form-check-label" htmlFor="thursday">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="friday"
                                                value={this.state.friday}
                                                onChange={this.handleChange}
                                                id="friday" />
                                            <label className="form-check-label" htmlFor="friday">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="saturday"
                                                value={this.state.saturday}
                                                onChange={this.handleChange}
                                                id="saturday" />
                                            <label className="form-check-label" htmlFor="saturday">Sat</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="sunday"
                                                value={this.state.sunday}
                                                onChange={this.handleChange}
                                                id="sunday" />
                                            <label className="form-check-label" htmlFor="sunday">Sun</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="all"
                                                value={this.state.all}
                                                onChange={this.handleChange}
                                                id="all" />
                                            <label className="form-check-label" htmlFor="all">All</label>
                                        </div>
                                    </div>
                                    {this.validator.message('Days', this.state.sunday, 'required|anyOneCheckboxRequired:' + (this.state.sunday || this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.all))}
                                </td>
                            </tr>
                            <tr>
                                <td>Service Type<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.servicetypeId} name="servicetypeId" id="servicetypeId" onChange={this.handleChange}>
                                        <option value="0">-- Select Service Type --</option>
                                        {this.state.clinicServiceTypeList.length > 0
                                            && this.state.clinicServiceTypeList.map((services, i) => {
                                                return (
                                                    <option key={i} value={services[2]}>{this.state.serviceTypeList.filter(serviceType => serviceType.codesetItemId === services[2]).map((x, i) => { return x.name })}</option>
                                                )
                                            })}
                                    </select>
                                    {this.validator.message('servicetypeId', this.state.servicetypeId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Timings<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="input-group">
                                        <InputMask name="startTime" value={this.state.startTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        <span className='input-group-text'>and</span>
                                        <InputMask name="endTime" value={this.state.endTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {
                                            this.validator.message('startTime', this.state.startTime,
                                                'fromTimeToTimeRequired:' + this.state.startTime.replace(':', '`') + "," + this.state.endTime.replace(':', '`')
                                                + '|fromTimeToTimeIsValid:' + this.state.startTime.replace(':', '`') + "," + this.state.endTime.replace(':', '`'))
                                        }
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addDonotbookPatientsAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelDonotbookPatientsAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default DonotbookPatientsAdd
