import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class TimingsAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            all: false,
            dayOfWeek: '',
            clinicOpenTime: 'HH:mm',
            clinicCloseTime: 'HH:mm',
            treatmentRoomStartTime: 'HH:mm',
            treatmentRoomEndTime: 'HH:mm',
            labStartTime: 'HH:mm',
            labEndTime: 'HH:mm',
            clinicId: '',
            TimingsList: [],
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/timingsforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
            .then(response => {
                this.setState({
                    TimingsList: response.data,
                    clinicId: UserProfile.getClinicId()
                });
            })
            .catch(function (error) {
                console.log('response2 : ', error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addTimingsAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        if (this.dayOfWeekChecked().length > 0) {
            let timingsaddRes = []
            this.dayOfWeekChecked().map(d => {
                const a = d
                timingsaddRes.push({
                    clinicId: this.state.clinicId,
                    dayOfWeek: a,
                    clinicOpenTime: this.state.clinicOpenTime,
                    clinicCloseTime: this.state.clinicCloseTime,
                    treatmentRoomStartTime: this.state.treatmentRoomStartTime,
                    treatmentRoomEndTime: this.state.treatmentRoomEndTime,
                    labStartTime: this.state.labStartTime,
                    labEndTime: this.state.labEndTime,
                    deletedYN: 'N',
                    createdBy: AuthService.getCurrentUserName(),
                    lastUpdatedBy: AuthService.getCurrentUserName()
                })
            })

            axios.post('/timings', timingsaddRes, { headers: authHeader() })
                .then(response => {
                    UserProfile.setHighlights(response.data, 'success')
                    history.push('/clinic/' + UserProfile.getClinicId() + '/3')
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }
    dayOfWeekChecked() {
        var newArr = []
        if (this.state.sunday === true) newArr.push("1");
        if (this.state.monday === true) newArr.push("2");
        if (this.state.tuesday === true) newArr.push("3");
        if (this.state.wednesday === true) newArr.push("4");
        if (this.state.thursday === true) newArr.push("5");
        if (this.state.friday === true) newArr.push("6");
        if (this.state.saturday === true) newArr.push("7");
        if (this.state.all === true) newArr.push("8");
        return newArr;
    }
    cancelTimingsAdd = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/3');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        const type = target.type
        const checked = target.checked

        type === "checkbox" ?
            this.setState({
                [field]: checked,
            })
            :
            this.setState({
                [field]: value
            });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Timings</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Day(s)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-row col-auto my-1 d-flex justify-content-start">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="sunday"
                                                value={this.state.sunday}
                                                onChange={this.handleChange}
                                                ref={this.setFocus}
                                                id="sunday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 1) ? true : false} />
                                            <label className="form-check-label" htmlFor="sunday">Sun</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="monday"
                                                value={this.state.monday}
                                                onChange={this.handleChange}
                                                id="monday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 2) ? true : false} />
                                            <label className="form-check-label" htmlFor="monday">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="tuesday"
                                                value={this.state.tuesday}
                                                onChange={this.handleChange}
                                                id="tuesday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 3) ? true : false} />
                                            <label className="form-check-label" htmlFor="tuesday">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="wednesday"
                                                value={this.state.wednesday}
                                                onChange={this.handleChange}
                                                id="wednesday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 4) ? true : false} />
                                            <label className="form-check-label" htmlFor="wednesday">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="thursday"
                                                value={this.state.thursday}
                                                onChange={this.handleChange}
                                                id="thursday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 5) ? true : false} />
                                            <label className="form-check-label" htmlFor="thursday">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="friday"
                                                value={this.state.friday}
                                                onChange={this.handleChange}
                                                id="friday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 6) ? true : false} />
                                            <label className="form-check-label" htmlFor="friday">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="saturday"
                                                value={this.state.saturday}
                                                onChange={this.handleChange}
                                                id="saturday" disabled={this.state.TimingsList.find(x => x.dayOfWeek === 7) ? true : false} />
                                            <label className="form-check-label" htmlFor="saturday">Sat</label>
                                        </div>

                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="all"
                                                value={this.state.all}
                                                onChange={this.handleChange}
                                                id="all" disabled={this.state.TimingsList.length > 0 ? true : false} />
                                            <label className="form-check-label" htmlFor="all">All</label>
                                        </div>
                                    </div>
                                    {this.validator.message('Days', this.state.sunday, 'required|anyOneCheckboxRequired:' + (this.state.sunday || this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.all))}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Clinic Open time<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <InputMask name="clinicOpenTime" value={this.state.clinicOpenTime}
                                        onChange={this.handleChange} className="col-md-2 form-control"
                                        placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                    {this.validator.message('clinicOpenTime', this.state.clinicOpenTime, 'requiredTime|isValidTime')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Clinic Close time<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <InputMask name="clinicCloseTime" value={this.state.clinicCloseTime}
                                        onChange={this.handleChange} className="col-md-2 form-control"
                                        placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                    {this.validator.message('clinicCloseTime', this.state.clinicCloseTime, 'requiredTime|isValidTime|fromTimeToTimeIsValid:' + this.state.clinicOpenTime.replace(':', '`') + "," + this.state.clinicCloseTime.replace(':', '`'), { messages: { fromTimeToTimeIsValid: 'This value shall be after open time.' } })}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Treatment room (Start)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <InputMask name="treatmentRoomStartTime" value={this.state.treatmentRoomStartTime}
                                        onChange={this.handleChange} className="col-md-2 form-control"
                                        placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                    {this.validator.message('treatmentRoomStartTime', this.state.treatmentRoomStartTime, 'requiredTime|isValidTime')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Treatment room (End)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <InputMask name="treatmentRoomEndTime" value={this.state.treatmentRoomEndTime}
                                        onChange={this.handleChange} className="col-md-2 form-control"
                                        placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                    {this.validator.message('treatmentRoomEndTime', this.state.treatmentRoomEndTime, 'requiredTime|isValidTime|fromTimeToTimeIsValid:' + this.state.treatmentRoomStartTime.replace(':', '`') + "," + this.state.treatmentRoomEndTime.replace(':', '`'), { messages: { fromTimeToTimeIsValid: 'This value shall be after start time.' } })}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Lab Time (Start)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <InputMask name="labStartTime" value={this.state.labStartTime}
                                        onChange={this.handleChange} className="col-md-2 form-control"
                                        placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                    {this.validator.message('labStartTime', this.state.labStartTime, 'requiredTime|isValidTime')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Lab Time (End)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <InputMask name="labEndTime" value={this.state.labEndTime}
                                        onChange={this.handleChange} className="col-md-2 form-control"
                                        placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                    {this.validator.message('labEndTime', this.state.labEndTime, 'requiredTime|isValidTime|fromTimeToTimeIsValid:' + this.state.labStartTime.replace(':', '`') + "," + this.state.labEndTime.replace(':', '`'), { messages: { fromTimeToTimeIsValid: 'This value shall be after start time.' } })}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addTimingsAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelTimingsAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default TimingsAdd
