import React, { Component } from 'react'
import history from '../../../history';
import Table from 'react-bootstrap/Table'
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from 'react-tooltip';
import Button from 'react-bootstrap/Button'
class MaxTreatStart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codesetItemId: 0,
            maxTreatStarts: [],
            showDeleteConfirmation: false,
        }
    }
    componentDidMount() {
        UserProfile.setClinicId(this.props.clinicId)
        axios.get('/maxtreatstartforclinic/' + UserProfile.getClinicId(),{ headers: authHeader() })
            .then(response => {
                let list = []
                let st = ''
                let d = {}
                for (let index=0; index<response.data.length; index++) {
                    const e = response.data[index];
                    if (st != e.startTime){
                        if (d.hasOwnProperty('startTime')) {
                            list.push(d)
                            d = {}
                        }
                        st = e.startTime
                        d['startTime'] = e.startTime
                    }
                    switch (e.dayOfWeek){
                        case 1:
                            d['sunId'] = e.id
                            d['sunCount'] = e.treatmentsStartDesired
                            d['sunPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 2:
                            d['monId'] = e.id
                            d['monCount'] = e.treatmentsStartDesired
                            d['monPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 3:
                            d['tueId'] = e.id
                            d['tueCount'] = e.treatmentsStartDesired
                            d['tuePenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 4:
                            d['wedId'] = e.id
                            d['wedCount'] = e.treatmentsStartDesired
                            d['wedPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 5:
                            d['thuId'] = e.id
                            d['thuCount'] = e.treatmentsStartDesired
                            d['thuPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 6:
                            d['friId'] = e.id
                            d['friCount'] = e.treatmentsStartDesired
                            d['friPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 7:
                            d['satId'] = e.id
                            d['satCount'] = e.treatmentsStartDesired
                            d['satPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        default:
                            console.log('ERROR:::')
                    }
                }
                if (d.hasOwnProperty('startTime')) list.push(d)
                this.setState({
                    codesetItemId: UserProfile.getClinicId(),
                    maxTreatStarts: list
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleShowAdd(){
        history.push('/MaxTreatStartAdd/' + this.state.codesetItemId)
    }
    handleShowEdit(){
        history.push('/MaxTreatStartEdit/' + this.state.codesetItemId)
    }
    handleShowDeleteConfirmation(){
        this.setState({showDeleteConfirmation: true})
    }
    handleDeleteConfirmationClose(){
        this.setState({showDeleteConfirmation: false})
    }
    Delete(){
        axios.delete('/maxtreatstart/' + UserProfile.getClinicId(),{ headers: authHeader() })
        .then(response => {
            this.setState({
                maxTreatStarts: [],
                showDeleteConfirmation: false
            });
        })
        .catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        })
    }
    render() {
        return (
            <div>
                <div className="d-flex justify-content-end">
                    { this.state.maxTreatStarts.length == 0 ?
                    <div>
                    <button type="button" className="btn btn-primary mb-2 mr-2" align="right" data-tip="Add Max Treatment Start" data-toggle="tooltip" data-placement="top" onClick={this.handleShowAdd.bind(this)}>Add</button>
                    <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Delete Max Treatment Start" data-toggle="tooltip" data-placement="top" disabled>Delete</button>
                    </div>
                    :
                    <div>
                    <button type="button" className="btn btn-primary mb-2 mr-2" align="right" data-tip="Edit Max Treatment Start" data-toggle="tooltip" data-placement="top" onClick={this.handleShowEdit.bind(this)}>Edit</button>
                    <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Delete Max Treatment Start" data-toggle="tooltip" data-placement="top" onClick={this.handleShowDeleteConfirmation.bind(this)}>Delete</button>
                    </div>
                    }
                </div>
                <Table className="table table-striped table-bordered table-hover table-sm">
                    <thead className="thead-dark">
                        <tr>
                            <th rowSpan="2" className="text-right">#</th>
                            <th rowSpan="2" className="text-center" style={{width: '120px'}}>Time of Day</th>
                            <th colSpan="2" className="text-center">Monday</th>
                            <th colSpan="2" className="text-center">Tuesday</th>
                            <th colSpan="2" className="text-center">Wednesday</th>
                            <th colSpan="2" className="text-center">Thursday</th>
                            <th colSpan="2" className="text-center">Friday&nbsp;</th>
                            <th colSpan="2" className="text-center">Saturday</th>
                            <th colSpan="2" className="text-center">Sunday</th>
                        </tr>
                        <tr>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                        </tr>
                    </thead>
                    <tbody className="table-hover">
                        {
                            this.state.maxTreatStarts.length == 0 ? <tr><td colSpan="16" style={{ textAlign: "center" }}><b>No Record(s)</b></td></tr> 
                            :
                            this.state.maxTreatStarts.length > 0 && this.state.maxTreatStarts.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="text-right">{key+1}</td>
                                        <td className="text-center">{item.startTime}</td>
                                        <td className="text-right">{item.monCount}</td>
                                        <td className="text-right">{item.monPenalty}</td>
                                        <td className="text-right">{item.tueCount}</td>
                                        <td className="text-right">{item.tuePenalty}</td>
                                        <td className="text-right">{item.wedCount}</td>
                                        <td className="text-right">{item.wedPenalty}</td>
                                        <td className="text-right">{item.thuCount}</td>
                                        <td className="text-right">{item.thuPenalty}</td>
                                        <td className="text-right">{item.friCount}</td>
                                        <td className="text-right">{item.friPenalty}</td>
                                        <td className="text-right">{item.satCount}</td>
                                        <td className="text-right">{item.satPenalty}</td>
                                        <td className="text-right">{item.sunCount}</td>
                                        <td className="text-right">{item.sunPenalty}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                <Modal show={this.state.showDeleteConfirmation} onHide={this.handleDeleteConfirmationClose.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this Max Treatment Start?</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" data-tip="Yes" onClick={this.Delete.bind(this)}>
                        Yes
                    </Button>
                    <Button variant="secondary" data-tip="No" onClick={this.handleDeleteConfirmationClose.bind(this)}>
                        No
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default MaxTreatStart