import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import { RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class ClinicServicesAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesList: [],
            serviceId: '',
            serviceName: '',
            servicesTypeList: [],
            serviceTypeName: '',
            servicetypeId: '',
            clinicId: '',
            description: '',
            activeStatus: 'Y',
            clinicServiceTypeList: [],
            duration: ''
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/services', { headers: authHeader() })
            .then(response1 => {
                axios.get('/getservicetypelist/' + UserProfile.getClinicId(), { headers: authHeader() })
                    .then(response2 => {
                        this.setState({
                            clinicServiceTypeList: response2.data,
                            servicesList: response1.data,
                            clinicId: UserProfile.getClinicId()
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addClinicServicesAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        axios.post('/clinicservices', {
            clinicId: this.state.clinicId, serviceId: this.state.serviceId, servicetypeId: this.state.servicetypeId, description: this.state.description,
            activeStatus: this.state.activeStatus, deletedYN: 'N', createdBy: AuthService.getCurrentUserName(),
            lastUpdatedBy: AuthService.getCurrentUserName()
        }, { headers: authHeader() })
            .then(response => {
                console.log(response)
                UserProfile.setHighlights([response.data.servicesId], 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/6');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    cancelClinicServicesAdd = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/6');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            [field]: value
        });
    }
    handleChangeForResource = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        const d = this.state.servicesList.filter(servicesType => servicesType.resourceTypeId == value).map((x, i) => { return x })

        this.setState({
            [field]: value,
            serviceId: d[0].codesetItemId,
            serviceName: d[0].name,
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Service</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Service Type<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.servicetypeId} name="servicetypeId" id="servicetypeId" onChange={this.handleChangeForResource}
                                        ref={this.setFocus}>
                                        <option value="0">-- Service Type  --</option>
                                        {this.state.clinicServiceTypeList.length > 0
                                            && this.state.clinicServiceTypeList.map((services, i) => {
                                                return (
                                                    <option key={i} value={services[0]}>{services[1]}</option>
                                                )
                                            })}
                                    </select>
                                    {this.validator.message('servicetypeId', this.state.servicetypeId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Service</td>
                                <td className="col-auto"><b> {this.state.serviceName}</b></td>
                            </tr>
                            <tr>
                                <td className="col-auto">Description<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    id="description" className="form-control" placeholder="Description" />
                                    {this.validator.message('Description', this.state.description, 'required|maxLength:200')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addClinicServicesAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClinicServicesAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ClinicServicesAdd
