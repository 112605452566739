import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../../utils/Util'
import ocsHighlighRow from '../../utils/OcsHighlighRow'

class ClinicServiceType extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicServiceTypeList: [],
            codesetItemId: '0',
            serviceTypeList: [],
            serviceTypeName: '',
            serviceTypeId: '',
            skillsId: '',
            sequence: '',
            showList: false,
            showModal: false,
            clinicservicesList: [],
            sortConfig: {
                'serviceTypeName': { dataField: 'serviceTypeName', order: 'asc' },
            },
        }
        this.AddOpen = this.AddOpen.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.getClinicServiceTypeList = this.getClinicServiceTypeList.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getClinicServiceTypeList()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.clinicServiceTypeList, 'ids', UserProfile.getHightlightIds())
        this.setState({ clinicServiceTypeList: this.state.clinicServiceTypeList });
        this.node.paginationContext.currPage = p;
    }
    getClinicServiceTypeList() {
        axios.get('/clinicserviceTypesforClinic/' + UserProfile.getClinicId(), { headers: authHeader() })
            .then(response2 => {
                axios.get('/servicetypeslist', { headers: authHeader() })
                    .then(response3 => {
                        axios.get('/clinicservicesforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                            .then(response4 => {
                                let dataset = response2.data.map((t, i) => ({ id: i, ids: t[0], serviceTypeId: t[2], skills: t[3], sequence: t[4], active: t[5] }))
                                dataset = dataset.map((t) => ({ ...t, serviceTypeName: response3.data.find((r) => r.codesetItemId === t.serviceTypeId).name }))
                                dataset.sort((a, b) => { return a.serviceTypeName < b.serviceTypeName ? -1 : a.serviceTypeName > b.serviceTypeName ? 1 : 0 })
                                this.setState({
                                    clinicservicesList: response4.data,
                                    serviceTypeList: response3.data,
                                    clinicServiceTypeList: dataset,
                                    codesetItemId: UserProfile.getClinicId(),
                                    showList: true
                                });
                                this.gotoPage.click();
                            })
                            .catch(function (error) {
                                console.log('response2 : ', error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log('response3 : ', error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log('response2 : ', error);
                history.push('/Errorpage')
            })
    }
    AddOpen() {
        history.push('/ClinicServiceTypeadd')
    }
    EditOpen(id) {
        history.push('/ClinicServiceTypedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/clinicserviceTypesdel/' + this.state.serviceTypeId, '', { headers: authHeader() })
            .then(response => {
                this.getClinicServiceTypeList()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `'${this.state.serviceTypeName}' Service Type of '${UserProfile.getClinicName()}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name) {
        this.setState({
            serviceTypeId: id,
            serviceTypeName: name,
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'sno',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '50px' }
            },
            {
                dataField: 'serviceTypeName',
                text: 'Service Type',
                sort: true,
                headerStyle: { width: '150px' },
            },
            {
                dataField: 'skills',
                text: 'Skills',
                sort: true,
                headerStyle: { width: '250px' }
            },
            {
                dataField: 'sequence',
                text: 'Sequence',
                sort: true,
                headerStyle: { width: '100px', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'active',
                text: 'Active',
                sort: true,
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cell, row) => (
                    row.active == 'Y' ? 'Yes' : 'No'
                ),
                align: 'center'
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.ids)} data-tip="Edit">Edit</button>&nbsp;
                        {this.state.clinicservicesList.find(x => x.servicetypeId === row.serviceTypeId) ?
                            <button type="button" className="btn btn-secondary" disabled onClick={this.handleShow.bind(this, row.ids, this.state.serviceTypeList.filter(serviceType => serviceType.codesetItemId === row.serviceTypeId).map((x, i) => { return x.name }))} data-tip="Delete">Delete</button>
                            :
                            <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.ids, this.state.serviceTypeList.filter(serviceType => serviceType.codesetItemId === row.serviceTypeId).map((x, i) => { return x.name }))} data-tip="Delete">Delete</button>
                        }
                    </div>
                ),
                align: 'center'
            }
        ];
        return (
            <div>
                <div className="form-row align-items-center my-1">
                    <div className="col">
                        <div className="float-right">
                            <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                                <div className="d-flex justify-content-end">
                                    <ReactTooltip />
                                    {this.state.clinicServiceTypeList.length > 0 && (this.state.clinicservicesList.length === this.state.clinicServiceTypeList.length) ?
                                        <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" disabled onClick={this.AddOpen} >Add Service Type</button>
                                        :
                                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen} >Add Service Type</button>
                                    }
                                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <div>
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete the service type <b>{this.state.serviceTypeName}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            keyField='ids'
                            data={this.state.clinicServiceTypeList}
                            columns={columns}
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory()}
                            noDataIndication="No Record(s)"
                            striped hover condensed
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default ClinicServiceType
