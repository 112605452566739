import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
class StaffAvailabilityEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffAvailableId: '',
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            dayOfWeek: '',
            startTime: '',
            endTime: '',
            availability: '',
            perTimeSlot: '',
            clinicId: '',
            staffId: '',
            createdBy: '',
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        var codesetitemid = this.props.match.params
        var id = codesetitemid.staffAvailableId.replace(":", "")
        axios.get('/staffavailability/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    staffAvailableId: response.data.staffAvailableId,
                    dayOfWeek: response.data.dayOfWeek,
                    perTimeSlot: response.data.perTimeSlot,
                    startTime: response.data.startTime,
                    endTime: response.data.endTime,
                    availability: response.data.availability,
                    sunday: response.data.dayOfWeek == "1" ? true : false,
                    monday: response.data.dayOfWeek == "2" ? true : false,
                    tuesday: response.data.dayOfWeek == "3" ? true : false,
                    wednesday: response.data.dayOfWeek == "4" ? true : false,
                    thursday: response.data.dayOfWeek == "5" ? true : false,
                    friday: response.data.dayOfWeek == "6" ? true : false,
                    saturday: response.data.dayOfWeek == "7" ? true : false,
                    createdBy: response.data.createdBy,
                    clinicId: UserProfile.getClinicId(),
                    staffId: UserProfile.getStaffId()
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    addStaffAvailabilityEdit = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        this.dayOfWeekChecked().map(d => {
            const a = d
            axios.put('/staffavailability/' + this.state.staffAvailableId, {
                staffAvailableId: this.state.staffAvailableId, clinicId: this.state.clinicId, staffId: this.state.staffId, dayOfWeek: a, startTime: this.state.startTime, endTime: this.state.endTime, perTimeSlot: this.state.perTimeSlot,
                availability: this.state.availability, deletedYN: 'N', createdBy: this.state.createdBy,
                lastUpdatedBy: AuthService.getCurrentUserName(),
            }, { headers: authHeader() })
                .then(response => {
                    UserProfile.setHighlights(response.data, 'success')
                    history.push('/clinic/' + UserProfile.getClinicId() + '/2');
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        })
    }
    dayOfWeekChecked() {
        var newArr = []
        if (this.state.sunday == true) newArr.push("1");
        if (this.state.monday == true) newArr.push("2");
        if (this.state.tuesday == true) newArr.push("3");
        if (this.state.wednesday == true) newArr.push("4");
        if (this.state.thursday == true) newArr.push("5");
        if (this.state.friday == true) newArr.push("6");
        if (this.state.saturday == true) newArr.push("7");
        if (this.state.all == true) newArr.push("8");
        return newArr;
    }
    cancelStaffAvailabilityEdit = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([this.state.staffAvailableId], 'info')
        history.push('/clinic/' + UserProfile.getClinicId() + '/2');
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Edit Staff Availability</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <ReactTooltip />
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td className="col-auto">Clinic Name</td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b></td>
                            </tr>
                            <tr>
                                <td>Staff Name</td>
                                <td><b>{UserProfile.getStaffName()}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Day(s)</td>
                                <td>
                                    <div className="form-row col-auto my-1 d-flex justify-content-start">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="sunday"
                                                value={this.state.sunday}
                                                onChange={this.handleChange}
                                                id="sunday" checked={this.state.sunday} disabled />
                                            <label className="form-check-label">Sun</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="monday"
                                                value={this.state.monday}
                                                onChange={this.handleChange}
                                                id="monday" checked={this.state.monday} disabled />
                                            <label className="form-check-label">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="tuesday"
                                                value={this.state.tuesday}
                                                onChange={this.handleChange}
                                                id="tuesday" checked={this.state.tuesday} disabled />
                                            <label className="form-check-label">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="wednesday"
                                                value={this.state.wednesday}
                                                onChange={this.handleChange}
                                                id="wednesday" checked={this.state.wednesday} disabled />
                                            <label className="form-check-label">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="thursday"
                                                value={this.state.thursday}
                                                onChange={this.handleChange}
                                                id="thursday" checked={this.state.thursday} disabled />
                                            <label className="form-check-label">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="friday"
                                                value={this.state.friday}
                                                onChange={this.handleChange}
                                                id="friday" checked={this.state.friday} disabled />
                                            <label className="form-check-label">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="saturday"
                                                value={this.state.saturday}
                                                onChange={this.handleChange}
                                                id="saturday" checked={this.state.saturday} disabled />
                                            <label className="form-check-label">Sat</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Timings<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="input-group">
                                        <InputMask name="startTime" value={this.state.startTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" autoFocus/>
                                        <span className='input-group-text'>and</span>
                                        <InputMask name="endTime" value={this.state.endTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {
                                            this.validator.message('startTime', this.state.startTime,
                                                'fromTimeToTimeRequired:' + this.state.startTime.replace(':', '`') + "," + this.state.endTime.replace(':', '`')
                                                + '|fromTimeToTimeIsValid:' + this.state.startTime.replace(':', '`') + "," + this.state.endTime.replace(':', '`'))
                                        }
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td># of Patients per time slot<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <input type="number" name="perTimeSlot"
                                        value={this.state.perTimeSlot}
                                        onChange={this.handleChange}
                                        id="perTimeSlot" className="col-md-2 form-control" min="1" max="999" autoComplete="off" />
                                    {this.validator.message('# of Patients per time slot', this.state.perTimeSlot, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td>Availability<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <select className="col-md-5 form-control" value={this.state.availability} name="availability" id="availability" onChange={this.handleChange}>
                                        <option value="0">--  Select Availability  --</option>
                                        <option value="Available">Available</option>
                                        <option value="Unavailable">Unavailable</option>
                                        <option value="New Patients">New Patients</option>
                                    </select>
                                    {this.validator.message('Availability', this.state.availability, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button type="button" onClick={this.addStaffAvailabilityEdit} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelStaffAvailabilityEdit} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default StaffAvailabilityEdit
