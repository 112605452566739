import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
class StaffAvailabilityAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            all: false,
            availability: 'Available',
            perTimeSlot: '1',
            clinicId: '',
            staffId: '',
            durationTiming: [{ block: 0, startTime: '', endTime: '' }],
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        this.setState({
            clinicId: UserProfile.getClinicId(),
            staffId: UserProfile.getStaffId()
        })
        this.setFocus.current.focus();
    }
    addStaffAvailabilityAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        let arr = []
        if (this.state.all === true) {
            arr = ['1', '2', '3', '4', '5', '6', '7']
        } else {
            if (this.state.sunday === true) arr.push("1");
            if (this.state.monday === true) arr.push("2");
            if (this.state.tuesday === true) arr.push("3");
            if (this.state.wednesday === true) arr.push("4");
            if (this.state.thursday === true) arr.push("5");
            if (this.state.friday === true) arr.push("6");
            if (this.state.saturday === true) arr.push("7");
        }
        let staffavailabilities = []
        arr.forEach((ar) => {
            this.state.durationTiming.map((item, key) => {
                staffavailabilities.push({
                    clinicId: this.state.clinicId,
                    staffId: this.state.staffId,
                    dayOfWeek: ar,
                    startTime: item.startTime,
                    endTime: item.endTime,
                    perTimeSlot: this.state.perTimeSlot,
                    availability: this.state.availability,
                    deletedYN: 'N',
                    createdBy: AuthService.getCurrentUserName(),
                    lastUpdatedBy: AuthService.getCurrentUserName(),
                })
            })
        })
        axios.post('/staffavailabilities', staffavailabilities, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights(response.data, 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/2');
            })
            .catch(function (error) {
                console.log(error)
                history.push('/Errorpage')
            });
    }
    dayOfWeekChecked() {
        var newArr = []
        if (this.state.sunday == true) newArr.push("1");
        if (this.state.monday == true) newArr.push("2");
        if (this.state.tuesday == true) newArr.push("3");
        if (this.state.wednesday == true) newArr.push("4");
        if (this.state.thursday == true) newArr.push("5");
        if (this.state.friday == true) newArr.push("6");
        if (this.state.saturday == true) newArr.push("7");
        if (this.state.all == true) newArr.push("8");
        return newArr;
    }
    cancelStaffAvailabilityAdd = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/2');
    }
    handleChange = (event) => {
        if (event.target.type === 'checkbox') {
            this.setState({
                [event.target.name]: event.target.checked
            });
        } else {
            if (event.target.name.includes("perTimeSlot")) {
                this.setState({
                    [event.target.name]: event.target.value
                });
            } else if (event.target.name.includes("Time")) {
                let e = event.target;
                this.setState((state) => {
                    let ev = e;
                    const list = state.durationTiming.map((item) => {
                        if (ev.dataset.block == item.block) {
                            if (ev.dataset.name === 'startTime')
                                item.startTime = ev.value;
                            else
                                item.endTime = ev.value;
                        }
                    });
                    return {
                        list,
                    };
                });
            } else {
                this.setState({
                    [event.target.name]: event.target.value
                });
            }
        }
    }
    addTiming = (event) => {
        let list = this.state.durationTiming
        let i = list.length
        let maxBlock = -1;
        while (i--)
            if (list[i].block > maxBlock) maxBlock = list[i].block;
        maxBlock++
        list.push({ block: maxBlock, startTime: '', endTime: '' })
        this.setState({ durationTiming: list });
    }
    removeTiming = (item) => {
        let list = this.state.durationTiming
        let i = list.length
        while (i--)
            if (list[i].block === item.block) list.splice(i, 1)
        this.setState({ durationTiming: list });
    }
    render() {
        this.validator.purgeFields();
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Staff Availability</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <ReactTooltip />
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ width: 30 + '%' }}>Clinic Name</td>
                                <td style={{ width: 70 + '%' }}><b>{UserProfile.getClinicName()}</b></td>
                            </tr>
                            <tr>
                                <td>Staff Name</td>
                                <td><b>{UserProfile.getStaffName()}</b></td>
                            </tr>
                            <tr>
                                <td>Day(s)<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="sunday"
                                                value={this.state.sunday}
                                                onChange={this.handleChange}
                                                id="sunday" 
                                                ref={this.setFocus}/>
                                            <label className="form-check-label" htmlFor="sunday">Sun</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="monday"
                                                value={this.state.monday}
                                                onChange={this.handleChange}
                                                id="monday" />
                                            <label className="form-check-label" htmlFor="monday">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="tuesday"
                                                value={this.state.tuesday}
                                                onChange={this.handleChange}
                                                id="tuesday" />
                                            <label className="form-check-label" htmlFor="tuesday">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="wednesday"
                                                value={this.state.wednesday}
                                                onChange={this.handleChange}
                                                id="wednesday" />
                                            <label className="form-check-label" htmlFor="wednesday">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="thursday"
                                                value={this.state.thursday}
                                                onChange={this.handleChange}
                                                id="thursday" />
                                            <label className="form-check-label" htmlFor="thursday">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="friday"
                                                value={this.state.friday}
                                                onChange={this.handleChange}
                                                id="friday" />
                                            <label className="form-check-label" htmlFor="friday">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="saturday"
                                                value={this.state.saturday}
                                                onChange={this.handleChange}
                                                id="saturday" />
                                            <label className="form-check-label" htmlFor="saturday">Sat</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="all"
                                                value={this.state.all}
                                                onChange={this.handleChange}
                                                id="all" />
                                            <label className="form-check-label" htmlFor="all">All</label>
                                        </div>
                                    </div>
                                    {this.validator.message('Days', this.state.sunday, 'required|anyOneCheckboxRequired:' + (this.state.sunday || this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.all))}
                                </td>
                            </tr>
                            <tr>
                                <td>Timings<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    {
                                        this.state.durationTiming.length > 0 && this.state.durationTiming.map((item, key) => {
                                            return (
                                                <div className="input-group mb-3" key={key}>
                                                    <ReactTooltip />
                                                    <InputMask name={"startTime" + key} value={this.state.durationTiming[key].startTime}
                                                        onChange={this.handleChange} data-name="startTime" data-block={item.block}
                                                        id={"startTime" + key} className="col-md-2 form-control" placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">and</span>
                                                    </div>
                                                    <InputMask name={"endTime" + key} value={this.state.durationTiming[key].endTime}
                                                        onChange={this.handleChange} data-name="endTime" data-block={item.block}
                                                        id={"endTime" + key} className="col-md-2 form-control" placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                                    {key === 0 ?
                                                        <button type="button" className="btn btn-outline-success ml-2" data-tip="Add More Timing" onClick={this.addTiming}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                            </svg>
                                                        </button>
                                                        :
                                                        <button type="button" className="btn btn-outline-danger ml-2" data-tip="Remove This Timing" onClick={this.removeTiming.bind(this, item)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                        </button>
                                                    }
                                                    {
                                                        this.validator.message('Timings' + [key], this.state.durationTiming[key].startTime,
                                                            'fromTimeToTimeRequired:' + this.state.durationTiming[key].startTime.replace(':', '`') + "," + this.state.durationTiming[key].endTime.replace(':', '`')
                                                            + '|fromTimeToTimeIsValid:' + this.state.durationTiming[key].startTime.replace(':', '`') + "," + this.state.durationTiming[key].endTime.replace(':', '`'))
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td># of Patients per time slot<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <div className="input-group has-validation">
                                        <input type="number"
                                            name="perTimeSlot"
                                            value={this.state.perTimeSlot}
                                            onChange={this.handleChange}
                                            id="perTimeSlot" className="col-md-2 form-control" min="1" max="999" autoComplete="off" />
                                        {this.validator.message('# of Patients per time slot', this.state.perTimeSlot, 'required')}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Availability<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <select className="col-md-5 form-control" value={this.state.availability} name="availability" id="availability" onChange={this.handleChange}>
                                        <option value="0">--  Select Availability  --</option>
                                        <option value="Available">Available</option>
                                        <option value="Unavailable">Unavailable</option>
                                        <option value="New Patients">New Patients</option>
                                    </select>
                                    {this.validator.message('Availability', this.state.availability, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button type="button" onClick={this.addStaffAvailabilityAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelStaffAvailabilityAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}
export default StaffAvailabilityAdd
