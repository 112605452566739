import React, { Component } from 'react'
import UserProfile from '../UserProfile'
import history from './../../history';
import axios from 'axios'
import moment from 'moment'
import authHeader from '../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import multisort from 'multisort';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import ocsHighlighRow from '../utils/OcsHighlighRow'
import { findPageIndex } from '../utils/Util'
import Spinner from 'react-bootstrap/Spinner'
class Scheduler extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codesetItemId: '0',
            clinicList: [],
            oncosmartSystemId: '',
            scheduleList: [],
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            all: '',
            clinicId: 0,
            showLoading: false,
            sortConfig: {
                'dayScheduleId': { dataField: 'dayScheduleId', order: 'asc' },
            },
        }
        this.handleChange = this.handleChange.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        axios.get('/aclinicslist/' + UserProfile.getGlobalClientParams().id, { headers: authHeader() })
            .then(response => {
                let cid = 0
                let id = ['']
                let m = false
                let t = false
                let w = false
                let th = false
                let f = false
                let s = false
                let su = false
                if (UserProfile.getClinicId() > 0) {
                    cid = UserProfile.getClinicId()
                    id = response.data.filter(cli => cli.codesetItemId === parseInt(UserProfile.getClinicId())).map((x, i) => { return x.oncosmartSystemId })
                    m = UserProfile.getNavParams().selectedWeekdays.monday
                    t = UserProfile.getNavParams().selectedWeekdays.tuesday
                    w = UserProfile.getNavParams().selectedWeekdays.wednesday
                    th = UserProfile.getNavParams().selectedWeekdays.thursday
                    f = UserProfile.getNavParams().selectedWeekdays.friday
                    s = UserProfile.getNavParams().selectedWeekdays.saturday
                    su = UserProfile.getNavParams().selectedWeekdays.sunday
                }
                this.setState({
                    clinicList: response.data,
                    oncosmartSystemId: id[0],
                    monday: m,
                    tuesday: t,
                    wednesday: w,
                    thursday: th,
                    friday: f,
                    saturday: s,
                    sunday: su,
                    all: !(m || t || w || th || f || s || su),
                    scheduleList: [],
                    codesetItemId: cid
                }, () => {
                    if (UserProfile.getClinicId() > 0) this.getSchedule(true)
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.scheduleList, 'dayScheduleId', UserProfile.getHightlightIds())
        this.setState({ scheduleList: this.state.scheduleList });
        this.node.paginationContext.currPage = p;
    }
    VBOpen(id, date) {
        UserProfile.setSelectedName(this.state.codesetItemId + "," + (parseInt(moment(date).format('E')) + 1) + "," + this.state.oncosmartSystemId)
        UserProfile.setSelectedId(id)
        UserProfile.setClinicId(this.state.codesetItemId + '')
        UserProfile.setScheduleDate(date)
        UserProfile.setNavParams({
            selectedId: id,
            selectedWeekdays: { monday: this.state.monday, tuesday: this.state.tuesday, wednesday: this.state.wednesday, thursday: this.state.thursday, friday: this.state.friday, saturday: this.state.saturday, sunday: this.state.sunday }
        })
        UserProfile.setNavigatedFrom('/scheduler')
        history.push('/viewbreakdown')
    }
    VSOpen(solutionId, date, id) {
        UserProfile.setSelectedName(this.state.codesetItemId + "," + (parseInt(moment(date).format('E')) + 1) + "," + this.state.oncosmartSystemId)
        UserProfile.setSelectedId(solutionId)
        UserProfile.setClinicId(this.state.codesetItemId + '')
        UserProfile.setScheduleDate(date)
        UserProfile.setNavParams({
            selectedId: id,
            selectedWeekdays: { monday: this.state.monday, tuesday: this.state.tuesday, wednesday: this.state.wednesday, thursday: this.state.thursday, friday: this.state.friday, saturday: this.state.saturday, sunday: this.state.sunday }
        })
        UserProfile.setNavigatedFrom('/scheduler')
        history.push('/solutionlist')
    }
    handleChange(event) {
        const target = event.target;
        if (target.type === "checkbox") {
            if (target.name === 'all') {
                this.setState({
                    [target.name]: target.checked,
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                }, () => {
                    if (this.state.oncosmartSystemId > 0 && this.state.all)
                        this.getSchedule();
                    else
                        this.setState({ scheduleList: [] });
                })
            } else {
                if (target.checked) {
                    this.setState({
                        [target.name]: target.checked,
                        all: false
                    }, () => {
                        if (this.state.oncosmartSystemId > 0 && (this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.sunday))
                            this.getSchedule();
                        else
                            this.setState({ scheduleList: [] });
                    })
                } else {
                    this.setState({
                        [target.name]: target.checked,
                    }, () => {
                        if (!(this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.sunday)) {
                            this.setState({
                                all: true
                            }, () => {
                                if (this.state.oncosmartSystemId > 0 && (this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.sunday || this.state.all))
                                    this.getSchedule();
                                else
                                    this.setState({ scheduleList: [] });
                            })
                        } else {
                            if (this.state.oncosmartSystemId > 0 && (this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.sunday))
                                this.getSchedule();
                            else
                                this.setState({ scheduleList: [] });
                        }
                    })
                }
            }
        } else {
            const tt = this.state.clinicList.filter(cli => cli.oncosmartSystemId === parseInt(target.value)).map((x, i) => { return x.codesetItemId })
            this.setState({
                [target.name]: target.value,
                codesetItemId: tt[0],
                scheduleList: [],
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                all: true
            }, () => {
                this.getSchedule();
            });
        }
    }
    getSchedule(c = false) {
        let arr = []
        if (this.state.all === true) {
            arr = [1, 2, 3, 4, 5, 6, 7];
        } else {
            if (this.state.sunday === true) arr.push(1);
            if (this.state.monday === true) arr.push(2);
            if (this.state.tuesday === true) arr.push(3);
            if (this.state.wednesday === true) arr.push(4);
            if (this.state.thursday === true) arr.push(5);
            if (this.state.friday === true) arr.push(6);
            if (this.state.saturday === true) arr.push(7);
        }
        this.setState({ showLoading: true })
        axios.get('/getschudule/' + this.state.oncosmartSystemId + "/" + arr, { headers: authHeader() })
            .then(response => {
                let dataset = [...response.data]
                dataset.sort((a, b) => { return a.dayScheduleId < b.dayScheduleId ? -1 : a.dayScheduleId > b.dayScheduleId ? 1 : 0 })
                this.setState({
                    showLoading: false,
                    scheduleList: dataset
                })
                this.setState({ showLoading: false })
                if (c) this.gotoPage.click();
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    render() {
        const columns = [
            {
                dataField: 'dayScheduleId',
                text: 'ID',
                headerStyle: { width: '8%' },
                sort: true,
            },
            {
                dataField: 'scheduleDate',
                text: 'Day',
                headerStyle: { width: '30%' },
                formatter: (cell, row) => (
                    moment(row.scheduleDate).format('dddd') + ' - ' + moment(row.scheduleDate).format("DD/MM/YYYY")
                ),
                sort: true,
            },
            {
                dataField: 'requiredLabTech',
                text: 'Lab',
                headerStyle: { width: '10%' },
                sort: true,
                align: 'right',
                headerStyle: { textAlign: 'right' }
            },
            {
                dataField: 'requiredMa',
                text: 'MA',
                headerStyle: { width: '10%' },
                sort: true,
                align: 'right',
                headerStyle: { textAlign: 'right' }
            },
            {
                dataField: 'requiredMd',
                text: 'MD',
                headerStyle: { width: '10%' },
                sort: true,
                align: 'right',
                headerStyle: { textAlign: 'right' }
            },
            {
                dataField: 'requiredTtcnp',
                text: 'TTCNP',
                headerStyle: { width: '10%' },
                sort: true,
                align: 'right',
                headerStyle: { textAlign: 'right' }
            },
            {
                dataField: 'requiredInj',
                text: 'Inj',
                headerStyle: { width: '10%' },
                sort: true,
                align: 'right',
                headerStyle: { textAlign: 'right' }
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '12%' },
                formatter: (cellContent, row, index) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary mr-2" onClick={this.VBOpen.bind(this, row.dayScheduleId, row.scheduleDate)} data-tip="View Breakdown">VB</button>
                        {row.solutionGeneratedYN === 'N' ? null :
                            <button type="button" className="btn btn-primary" onClick={this.VSOpen.bind(this, row.solutionId, row.scheduleDate, row.dayScheduleId)} data-tip="List Solution">LS</button>}
                        <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                    </div>
                ),
            }
        ]
        return (
            <div className="container-fluid">
                <div className="section-title" >
                    <h4>List of Schedules </h4>
                </div>
                <div className="form-row align-items-center">
                    <div className="col-auto my-1">
                        <select className="form-control mr-sm-1" value={this.state.oncosmartSystemId}
                            name="oncosmartSystemId" id="oncosmartSystemId" onChange={this.handleChange}>
                            &nbsp;&nbsp;<option value="0">--  Select Clinic  --</option>
                            {this.state.clinicList.length > 0
                                && this.state.clinicList.map((clinic, i) => {
                                    return (
                                        <option key={i} value={clinic.oncosmartSystemId}>{clinic.name}</option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="sunday"
                            value={this.state.sunday}
                            onChange={this.handleChange} checked={this.state.sunday}
                            id="sunday" />
                        <label className="form-check-label" htmlFor="sunday">Sun</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="monday"
                            value={this.state.monday}
                            onChange={this.handleChange} checked={this.state.monday}
                            id="monday" />
                        <label className="form-check-label" htmlFor="monday">Mon</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="tuesday"
                            value={this.state.tuesday}
                            onChange={this.handleChange} checked={this.state.tuesday}
                            id="tuesday" />
                        <label className="form-check-label" htmlFor="tuesday">Tue</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="wednesday"
                            value={this.state.wednesday}
                            onChange={this.handleChange} checked={this.state.wednesday}
                            id="wednesday" />
                        <label className="form-check-label" htmlFor="wednesday">Wed</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="thursday"
                            value={this.state.thursday}
                            onChange={this.handleChange} checked={this.state.thursday}
                            id="thursday" />
                        <label className="form-check-label" htmlFor="thursday">Thu</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="friday"
                            value={this.state.friday}
                            onChange={this.handleChange} checked={this.state.friday}
                            id="friday" />
                        <label className="form-check-label" htmlFor="friday">Fri</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="saturday"
                            value={this.state.saturday}
                            onChange={this.handleChange} checked={this.state.saturday}
                            id="saturday" />
                        <label className="form-check-label" htmlFor="saturday">Sat</label>
                    </div>
                    <div className="col-auto my-1 form-check form-check-inline" style={{ "marginRight": 0 }}>
                        <input className="form-check-input" type="checkbox"
                            name="all"
                            value={this.state.all}
                            onChange={this.handleChange} checked={this.state.all}
                            id="all" />
                        <label className="form-check-label" htmlFor="all">All</label>
                    </div>
                    {
                        this.state.showLoading ? <div className="d-block"><Spinner animation="border" /></div> : ''
                    }
                </div>
                <div>
                    <BootstrapTable
                        remote={{
                            filter: false,
                            pagination: false,
                            sort: true,
                            cellEdit: false
                        }}
                        keyField='dayScheduleId'
                        data={this.state.scheduleList}
                        columns={columns}
                        ref={(n) => (this.node = n)}
                        pagination={paginationFactory()}
                        noDataIndication="No Record(s)"
                        striped hover condensed
                        rowClasses='table table-sm'
                        headerClasses="thread"
                        selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                        onTableChange={this.handleTableChange}
                        sort={{ sortCaret: this.headerSortingClasses }}
                    />
                </div>
            </div>
        )
    }
}

export default Scheduler
