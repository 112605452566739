import React, { Component } from 'react';
import history from '../history';
import AuthService from './authentication/AuthService'
class SessionTimeout extends Component {
    componentDidMount(){
        AuthService.logout()
        document.getElementById('navbar').style.display="none"
        document.getElementById('optimizer-alert').style.display="none"
    }
    Login(){
        history.push('/')
    }
    render() {
        return (
            <div>
                 <nav>
                    <ul>
                        <li>OncoSmart Schedule Optimizer</li>
                    </ul>
                </nav>
                <div className="jumbotron">
                    <div className="container">
                        <h1 className="display-4">Session Timed Out</h1>
                        <hr className="my-4"></hr>
                        <p className="lead">Your session has expired. Please login again.</p>
                        <p className="lead">
                            <a className="btn btn-primary btn-lg" href="/" role="button">Go to Login</a>
                        </p>                            
                    </div>
                </div>
            </div>
        );
    }
}

export default SessionTimeout;