import React, { Component } from 'react'
import history from '../../../history';
import { Form, Label } from 'reactstrap';
import axios from 'axios'
import moment from 'moment'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import Select from "react-select";
import { elementsToValidate, validate } from '../../utils/validations'
class HighLevelFilterStartk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesList: [],
            serviceId: '',
            servicesTypeList: [],
            serviceTypeName: '',
            servicetypeId: '',
            clinicId: '',
            description: '',
            activeStatus: 'Y',
            errors: [],
            notassigningList: [],
            costMonday: [],
            costTuesday: [],
            highlevelFilter: [],
            selectDuration:1,
            duration:'',
            updateMsg:'',
            optiontDuration:[],
            multiValue: [],
            selectedTimeOfDay: [],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeDuration=this.handleChangeDuration.bind(this)
        this.handleMultiChange = this.handleMultiChange.bind(this);
        this.handleTimeOfDayMultiChange = this.handleTimeOfDayMultiChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            clinicId: UserProfile.getClinicId(),
            serviceId: UserProfile.getServiceId(),
        })

        axios.get('/highlevelfilterforservice/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(), { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        highlevelFilter: response.data
                    })
                    this.getNotassigningpdk(this.state.selectDuration)
                }
                else {
                    this.insertHighlevelfilter()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.getOptiontDuration()

    }
    async getOptiontDuration(){
        await axios.get('/getservicedurationstartingaServicepdstartk/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(),{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        optiontDuration:response.data
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    getNotassigningpdk(val) {
        axios.get('/startingaServicepdstartk/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId() + '/'+val,{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        notassigningList: response.data
                    })

                    for (let index = 0; index < response.data.length; index++) {

                        if (response.data[index].costMonday >= 0) {
                            var costMon = 'costMonday' + index
                            document.getElementById(costMon).value = response.data[index].costMonday
                        }
                        if (response.data[index].costTuesday >= 0) {
                            var costTue = 'costTuesday' + index
                            document.getElementById(costTue).value = response.data[index].costTuesday
                        }
                        if (response.data[index].costWednesday >= 0) {
                            var costWed = 'costWednesday' + index
                            document.getElementById(costWed).value = response.data[index].costWednesday
                        }
                        if (response.data[index].costThursday >= 0) {
                            var costThu = 'costThursday' + index
                            document.getElementById(costThu).value = response.data[index].costThursday
                        }
                        if (response.data[index].costFriday >= 0) {
                            var costFri = 'costFriday' + index
                            document.getElementById(costFri).value = response.data[index].costFriday
                        }
                        if (response.data[index].costSaturday >= 0) {
                            var costSat = 'costSaturday' + index
                            document.getElementById(costSat).value = response.data[index].costSaturday
                        }
                        if (response.data[index].costSunday >= 0) {
                            var costSun = 'costSunday' + index
                            document.getElementById(costSun).value = response.data[index].costSunday
                        }

                    }
                    this.getClinicstimingrowsDuration()
                }
                else {
                    this.getClinicstimingrows()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getClinicstimingrowsDuration() {
        await axios.get('/clinicstimingrows/' + UserProfile.getClinicId(), { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    let timeint = response.data[0][2]
                    this.setState({
                        duration:timeint
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    insertHighlevelfilter() {
        var today = new Date();
        axios.post('/highlevelfilter', {
            clinicId: this.state.clinicId, serviceId: this.state.serviceId,
            costNotassigningPDk: '0', costStartingaservicePDStartk: '0', costNotassigningCD: '0',
            createdBy:AuthService.getCurrentUserName(), createdDate: moment(today).format('YYYY-MM-DD'),
            lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD')
        },{ headers: authHeader() })
            .then(response => {
                this.getClinicstimingrows()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    getClinicstimingrows() {
        let maxid=1
        axios.get('/getmaxstartingaServicepdstartk/',{ headers: authHeader() })
        .then(response => {
                if(response.data.length>0)
                {
                    maxid=response.data[0]
                }
                 axios.get('/clinicstimingrows/' + UserProfile.getClinicId(), { headers: authHeader() })
                .then(response => {
                    if (response.data.length > 0) {
                        let starttime = response.data[0][0]
                        let timeint = response.data[0][2]
                        let mainIndex=0
                        let addNotassigningpdkList=[]
                        this.setState({
                            duration:timeint
                        })
                        for (mainIndex = 0; mainIndex < response.data[0][4]; mainIndex++) {
                            starttime=response.data[0][0]
                            for (let index = 0; index < response.data[0][4]; index++) {
                                let d={id:maxid,clinicId: this.state.clinicId, serviceId: this.state.serviceId,
                                    timeOfDay: starttime, costMonday: -1, costTuesday: -1, costWednesday: -1, costThursday: -1,
                                    costFriday: -1, costSaturday: -1, costSunday: -1,
                                    createdBy: AuthService.getCurrentUserName(), createdDate: moment(today).format('YYYY-MM-DD'),
                                    lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD'),serviceDuration:mainIndex+1}
                                addNotassigningpdkList.push(d)
                                starttime = moment(starttime, 'HH:mm')
                                    .add(timeint, 'minutes')
                                    .format('HH:mm');
                                    maxid=maxid+1
                            }
                        }
                        var today = new Date();
                         axios.post('/startingaServicepdstartkbulk', addNotassigningpdkList,{ headers: authHeader() })
                            .then(response => {
                                this.updateClinicDay()
                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })
                      
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    updateClinicNullDays() {
        axios.put('/startingaServicepdstartkupdate/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(), '',{ headers: authHeader() })
            .then(response => {
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    updateClinicDay() {
        let startingaServicepdstartkupdatedayRes=[]
        axios.get('/clinicstiming/' + this.state.clinicId,{ headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    for (let index = 0; index < response.data.length; index++) {
                        const element = response.data[index];
                        startingaServicepdstartkupdatedayRes.push(axios.put('/startingaServicepdstartkupdateday/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId() + '/' + response.data[index][1], { startTime: moment(response.data[index][2], 'HH:mm:ss').format('HH:mm:ss'), endTime: moment(response.data[index][3], 'HH:mm:ss').format('HH:mm:ss') },{ headers: authHeader() })
                            .then(response => {

                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })
                        )
                    }
                    
                    Promise.all(startingaServicepdstartkupdatedayRes).then(() => this.getNotassigningpdk(this.state.selectDuration));
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    addNotassigningpdk(timeOfDay) {
        var today = new Date();
        axios.post('/startingaServicepdstartk', {
            clinicId: this.state.clinicId, serviceId: this.state.serviceId,
            timeOfDay: timeOfDay, costMonday: -1, costTuesday: -1, costWednesday: -1, costThursday: -1,
            costFriday: -1, costSaturday: -1, costSunday: -1,
            createdBy: AuthService.getCurrentUserName(), createdDate: moment(today).format('YYYY-MM-DD'),
            lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD')
        },{ headers: authHeader() })
            .then(response => {

            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    Apply() {
        let selVal = document.getElementById('selectValue').value
        let sel1 = document.getElementById('selectDay')
        let selDayVal = sel1.options[sel1.selectedIndex].value

        let selectedDays = []
        switch (selDayVal){
            case '1':
                selectedDays.push('costSunday');
                break;
            case '2':
                selectedDays.push('costMonday');
                break;
            case '3':
                selectedDays.push('costTuesday');
                break;
            case '4':
                selectedDays.push('costWednesday');
                break;
            case '5':
                selectedDays.push('costThursday');
                break;
            case '6':
                selectedDays.push('costFriday');
                break;
            case '7':
                selectedDays.push('costSaturday');
                break;
            case 'all':
                selectedDays.push('costSunday');
                selectedDays.push('costMonday');
                selectedDays.push('costTuesday');
                selectedDays.push('costWednesday');
                selectedDays.push('costThursday');
                selectedDays.push('costFriday');
                selectedDays.push('costSaturday');
                break;
            default:
                break;  
        }

        let notassigningList = this.state.notassigningList;
        this.state.selectedTimeOfDay.forEach(function(i, idx){
            if (i.id === -1) {
                notassigningList.forEach(function(o,i){
                    selectedDays.forEach(function(item, index){
                        if (document.getElementById(item + i) !== null) {
                            document.getElementById(item + i).value = selVal
                        }
                    })
                })
            } else {
                selectedDays.forEach(function(item, index){
                    if (document.getElementById(item + (i.value-1)) !== null) {
                        document.getElementById(item + (i.value-1)).value = selVal
                    }
                })
            }
        })
    }
    Update() {
        const formElements = elementsToValidate("ocsform");
        formElements.forEach(element => {
            const errors = validate(element, this.state.errors);
            this.setState({
                errors: errors
            });
        })
        if (Object.values(this.state.errors).length > 0) {
            alert('Please correct invalid data')
            return;
        }
        let updateNotassigningList = []
        for (let index = 0; index < this.state.notassigningList.length; index++) {

            var costMon = 'costMonday' + index
            var selValMon = this.state.notassigningList[index].costMonday
            if (document.getElementById(costMon) !== null) {
                selValMon = document.getElementById(costMon).value
            }
            var costTue = 'costTuesday' + index
            var selValTue = this.state.notassigningList[index].costTuesday
            if (document.getElementById(costTue) !== null) {
                selValTue = document.getElementById(costTue).value
            }
            var costWed = 'costWednesday' + index
            var selValWed = this.state.notassigningList[index].costWednesday
            if (document.getElementById(costWed) !== null) {
                selValWed = document.getElementById(costWed).value
            }
            var costThu = 'costThursday' + index
            var selValThu =  this.state.notassigningList[index].costThursday
            if (document.getElementById(costThu) !== null) {
                selValThu = document.getElementById(costThu).value
            }
            var costFri = 'costFriday' + index
            var selValFri = this.state.notassigningList[index].costFriday
            if (document.getElementById(costFri) !== null) {
                selValFri = document.getElementById(costFri).value
            }
            var costSat = 'costSaturday' + index
            var selValSat = this.state.notassigningList[index].costSaturday
            if (document.getElementById(costSat) !== null) {
                selValSat = document.getElementById(costSat).value
            }
            var costSun = 'costSunday' + index
            var selValSun = this.state.notassigningList[index].costSunday
            if (document.getElementById(costSun) !== null) {
                selValSun = document.getElementById(costSun).value
            }
              let d = {
                id: this.state.notassigningList[index].id, clinicId: this.state.notassigningList[index].clinicId, serviceId: this.state.notassigningList[index].serviceId,
                timeOfDay: this.state.notassigningList[index].timeOfDay, costMonday: selValMon,
                costTuesday: selValTue,
                costWednesday: selValWed,
                costThursday: selValThu,
                costFriday: selValFri,
                costSaturday: selValSat,
                costSunday: selValSun,
                serviceDuration:this.state.notassigningList[index].serviceDuration
            }
            updateNotassigningList.push(d)
        }

        axios.post('/updatestartingaServicepdstartk', updateNotassigningList, { headers: authHeader() })
            .then(response => {
                if (this.state.multiValue.length > 0) {
                    let postvalues = [];
                    if (this.state.multiValue[0].value === 'All') {
                        let i = 0;
                        this.setState({
                            updateMsg:'Please wait...'
                        })
                        this.state.notassigningList.forEach((value) => {
                            i++
                            axios.get('/startingaServicepdstartk/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId() + '/'+i,{ headers: authHeader() })
                                .then(response => {
                                    updateNotassigningList.forEach((item) => {
                                        let add = response.data.find((i) => i.timeOfDay === item.timeOfDay);
                                        if (add){
                                            add.costMonday = item.costMonday
                                            add.costTuesday = item.costTuesday
                                            add.costWednesday = item.costWednesday
                                            add.costThursday = item.costThursday
                                            add.costFriday = item.costFriday
                                            add.costSaturday = item.costSaturday
                                            add.costSunday = item.costSunday
                                            postvalues.push(add)
                                        }
                                    })
                                    axios.post('/updatestartingaServicepdstartk', postvalues,{ headers: authHeader() })
                                    .then(response => {
                                        
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        history.push('/Errorpage')
                                    })
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    history.push('/Errorpage')
                                })
                        })
                        this.updateHighlevelFilter()
                    } else {
                        this.setState({
                            updateMsg:'Please wait...'
                        })
                        this.state.multiValue.forEach((value) => {
                            axios.get('/startingaServicepdstartk/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId() + '/'+value.value,{ headers: authHeader() })
                                .then(response => {
                                    updateNotassigningList.forEach((item) => {
                                        let add = response.data.find((i) => i.timeOfDay === item.timeOfDay);
                                        if (add){
                                            add.costMonday = item.costMonday
                                            add.costTuesday = item.costTuesday
                                            add.costWednesday = item.costWednesday
                                            add.costThursday = item.costThursday
                                            add.costFriday = item.costFriday
                                            add.costSaturday = item.costSaturday
                                            add.costSunday = item.costSunday
                                            postvalues.push(add)
                                        }
                                    })
                                    axios.post('/updatestartingaServicepdstartk', postvalues,{ headers: authHeader() })
                                    .then(response => {
                                        
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        history.push('/Errorpage')
                                    })
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    history.push('/Errorpage')
                                })
                            })
                            this.updateHighlevelFilter()
                    }
                } else {
                    this.updateHighlevelFilter()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    updateHighlevelFilter() {
        axios.get('/highlevelfilterforservice/' + UserProfile.getClinicId() + "/" + UserProfile.getServiceId(), { headers: authHeader() })
            .then(response => {
                axios.put('/highlevelfilter/' + response.data[0].filterId, {
                    filterId: response.data[0].filterId,
                    clinicId: response.data[0].clinicId, serviceId: response.data[0].serviceId, costStartingaservicePDStartk: 1,
                    costNotassigningPDk: response.data[0].costNotassigningPDk, costNotassigningCD: response.data[0].costNotassigningCD
                },{ headers: authHeader() })
                    .then(response => {
                       this.getOptiontDuration()
                       this.setState({
                        updateMsg:'Record(s) Updated....'
                       })
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    Cancel() {
        history.push('/clinic/'+UserProfile.getClinicId()+'/7')
    }
    handleChange(event) {
        const { name, value } = event.target
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            updateMsg:''
        }))
    }
    handleChangeDuration(event) {
        const { name, value } = event.target
        this.setState(prevState => ({
            ...prevState,
            [name]: value,
            updateMsg:''
        }))
        this.getNotassigningpdk(value)
    }
    handleMultiChange(option) {
        this.setState(state => {
            return {
                multiValue: option
            };
        });
    }
    handleTimeOfDayMultiChange(option) {
        this.setState(state => {
            return {
                selectedTimeOfDay: option
            };
        });
    }
    render() {
        let options = this.state.notassigningList.length > 0 && this.state.notassigningList.map((option, i) => {
            i++;
            return ({
                value:i,
                label:i + ' X ' + this.state.duration + ' = ' + (i * this.state.duration) + ' Minutes',
                id:option.id,
                serviceDuration: option.serviceDuration,
                isdisabled: (this.state.multiValue != null) && this.state.multiValue.some((opt, i) => { 
                    return opt.id===-1
                }),
            })
        });
        if (options == false) options = [];
        options.unshift({value:'All', label:'All', id:-1, isdisabled: (this.state.multiValue != null) && this.state.multiValue.length > 0})

        let timeForApplyoptions = this.state.notassigningList.length > 0 && this.state.notassigningList.map((option, i) => {
            i++;
            return ({
                value:i,
                label:option.timeOfDay.slice(0,5),
                id:option.id,
                serviceDuration: option.serviceDuration,
                isdisabled: (this.state.selectedTimeOfDay != null) && this.state.selectedTimeOfDay.some((opt, i) => { 
                    return opt.id===-1
                }),
            })
        });
        if (timeForApplyoptions == false) timeForApplyoptions = [];
        timeForApplyoptions.unshift({value:'All', label:'All', id:-1, isdisabled: (this.state.selectedTimeOfDay != null) && this.state.selectedTimeOfDay.length > 0})
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Score of starting a service in a given valid timeslot k - P(D_Start_k)</h4>
                </div>
                <Form id="ocsform" method="post">
                    <div className="section-title containerAdd">
                    <Label for="error" style={{color:'blue'}}>{this.state.updateMsg}</Label>
                        <div className="d-flex justify-content-end form-row form-inline mb-2">
                        <ReactTooltip />
                            <button type="button" className="btn btn-secondary mr-2" onClick={this.Cancel.bind(this)} data-tip="Close">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.Update.bind(this)} data-tip="Update">Update</button>
                        </div>
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="col-auto"><b>Clinic Name</b></td>
                                    <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Services</td>
                                    <td className="col-auto">{UserProfile.getServiceName()}</td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Action</td>
                                    <td className="col-auto">Score of starting a service in a given valid timeslot k - P(D_Start_k)</td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Duration</td>
                                    <td className="form-inline mb-2">
                                    <select className="form-control mr-2" id="selectDuration" value={this.state.selectDuration}
                                        name="selectDuration"  onChange={this.handleChangeDuration} validations={['requireddrop']} autoFocus>
                                        <option value="-1">--  Select Duration --</option>
                                          {this.state.notassigningList.length > 0
                                    && this.state.notassigningList.map((notassigning, i) => {
                                        let iFound=false
                                        for (let index = 0; index < this.state.optiontDuration.length; index++) {
                                            const element = this.state.optiontDuration[index];
                                            if(element==i+1)
                                            {
                                                iFound=true     
                                            }
                                        }
                                        if(iFound){
                                        return (
                                            <option key={i} value={i+1} style={{color:'blue',fontWeight:'bold'}}>{i+1}</option>
                                        )
                                        }else{
                                            return (
                                                <option key={i} value={i+1}>{i+1}</option>
                                            )
                                        }
                                    })}
                            </select> X {this.state.duration} = {this.state.selectDuration * this.state.duration}&nbsp;Minutes
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Apply to Duration(s)</td>
                                    <td>
                                        <Select
                                            name="applySelectedDuration"
                                            placeholder="Select Duration"
                                            closeMenuOnSelect={false}
                                            value={this.state.multiValue}
                                            options={options}
                                            onChange={this.handleMultiChange}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            id="applySelectedDuration" validations={['required']}
                                            isOptionDisabled={(option) => option.isdisabled}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <div className="d-flex justify-content-end form-row form-inline mb-3">
                                            <label className="form-check-label mr-2">Value:</label>
                                            <input type="text" className="form-control col-md-1 form-group text-right mr-2" placeholder="125" id="selectValue" />&nbsp;
                                            <Select
                                                name="selectTime"
                                                placeholder="--  Select Time (Row) --"
                                                closeMenuOnSelect={false}
                                                value={this.state.selectedTimeOfDay}
                                                options={timeForApplyoptions}
                                                onChange={this.handleTimeOfDayMultiChange}
                                                isMulti
                                                className="basic-multi-select col mr-2"
                                                classNamePrefix="select"
                                                id="selectTime" validations={['required']}
                                                isOptionDisabled={(option) => option.isdisabled}
                                            />
                                            <select className="form-control mr-2" id="selectDay">
                                                <option selected>-- Select Day (Column) ---</option>
                                                <option value='all'>All</option>
                                                <option value='2'>Monday</option>
                                                <option value='3'>Tuesday</option>
                                                <option value='4'>Wednesday</option>
                                                <option value='5'>Thursday</option>
                                                <option value='6'>Friday</option>
                                                <option value='7'>Saturday</option>
                                                <option value='1'>Sunday</option>
                                            </select>
                                                <button type="button" className="btn btn-secondary" onClick={this.Apply.bind(this)} align="right" data-tip="Apply">Apply</button>
                                                <ReactTooltip />
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <table className="table table-striped table-bordered table-hover table-sm data-sortable='true'" >
                                <thead className="thead-dark">
                                    <tr>
                                    <th>#</th>
                                        <th style={{width: '120px'}}>Time of Day</th>
                                        <th>Monday</th>
                                        <th>Tuesday</th>
                                        <th>Wednesday</th>
                                        <th>Thursday</th>
                                        <th>Friday&nbsp;</th>
                                        <th>Saturday</th>
                                        <th>Sunday</th>
                                    </tr>
                                </thead>
                                <tbody className="table-hover">
                                    {


                                        this.state.notassigningList.length > 0 && this.state.notassigningList.map((item, key) => {
                                            return (
                                                <tr key={item.id}>
                                                    <td>{key + 1}</td>
                                                    <td >{moment(item.timeOfDay, 'HH:mm').format('HH:mm')}</td>
                                                    <td>{item.costMonday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={'costMonday' + key}
                                                        onChange={this.handleChange}
                                                        id={'costMonday' + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                    <td>{item.costTuesday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={'costTuesday' + key}
                                                        onChange={this.handleChange}
                                                        id={'costTuesday' + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                    <td>{item.costWednesday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={"costWednesday" + key}
                                                        onChange={this.handleChange}
                                                        id={"costWednesday" + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                    <td>{item.costThursday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={"costThursday" + key}
                                                        onChange={this.handleChange}
                                                        id={"costThursday" + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                    <td>{item.costFriday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={"costFriday" + key}
                                                        onChange={this.handleChange}
                                                        id={"costFriday" + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                    <td>{item.costSaturday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={"costSaturday" + key}
                                                        onChange={this.handleChange}
                                                        id={"costSaturday" + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                    <td>{item.costSunday >= 0 ? <input type="text"
                                                        validations={['required']}
                                                        name={"costSunday" + key}
                                                        onChange={this.handleChange}
                                                        id={"costSunday" + key} className="form-control text-right" placeholder="" maxLength="8"/> : ''} </td>
                                                </tr>

                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <div className="d-flex justify-content-end form-row form-inline">
                            <ReactTooltip />
                                <button type="button" className="btn btn-secondary mr-2" onClick={this.Cancel.bind(this)} data-tip="Close">Close</button>
                                <button type="button" className="btn btn-primary" onClick={this.Update.bind(this)} data-tip="Update">Update</button>
                            </div>

                        </div>
                    </div>
                </Form>
            </div >
        )
    }
}

export default HighLevelFilterStartk
