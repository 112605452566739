import React, { useState } from "react";
import './ProgressBar.css';
const ProgressBar = (props) => {
    const { bgcolor, completed, title, chairsUsage,staffUsage } = props;
    const [tooltipLeft, setTooltipLeft] = useState(0);
    const [tooltipTop, setTooltipTop] = useState(0);
    let su = staffUsage
    if (staffUsage === null){
      su="0"
    }
    const chFunc = Function('return ' + chairsUsage)
    const staffUsage1 = su.substring(0, su.lastIndexOf('('))
    const stFunc = Function('return ' + staffUsage1.replaceAll('x', '*'))
    const isChairsOverUsed = isNaN(chFunc()) ? 0 : isFinite(chFunc()) ? chFunc() : 2
    const isStaffOverUsed = isNaN(stFunc()) ? 0 : isFinite(stFunc()) ?stFunc() : 2
    let overused = (isChairsOverUsed > 1 || isStaffOverUsed > 1) ? 'block' : 'none' 
    let limitused = 'none'
    if (overused==='none') {limitused = 'block'}
    let showCompleted = completed
    if (parseFloat(chairsUsage.substr(0, chairsUsage.indexOf("/"))) > parseFloat(chairsUsage.substr(chairsUsage.indexOf("/") + 1))){
      showCompleted=110
    }
    const stFunc1 = Function('return ' + staffUsage1.substr(0, staffUsage1.indexOf("/")).replaceAll('x', '*'))
    if (parseFloat(stFunc1()) > parseFloat(staffUsage1.substr(staffUsage1.indexOf("/") + 1))){
      showCompleted = 110
    }

    let per=''
    if(chairsUsage==='0/0'){
      per=title + ' - ' + staffUsage 
    }else{
      per=title + ' ('+ chairsUsage +') - '+ staffUsage 
    }

    let onMouseMove = (e) => {
      setTooltipLeft(e.clientX-100)
      setTooltipTop(e.clientY-80)
    }
  
    return (
      <div className="progress-indicator-container" onMouseEnter={(e) => onMouseMove(e)}>
        <div style={{ width: `${showCompleted}%`, backgroundColor: bgcolor, display: limitused}} className="progress-indicator"></div>
        <div style={{
          width: `${showCompleted}%`,
          maxWidth:`115%`,
          backgroundColor: bgcolor,
          backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'6\' height=\'6\' viewBox=\'0 0 6 6\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cg fill=\'%23ffffff\' fill-opacity=\'1\' fill-rule=\'evenodd\'%3E%3Cpath d=\'M5 0h1L0 6V5zM6 5v1H5z\'/%3E%3C/g%3E%3C/svg%3E")',
          display: overused
          }} className="progress-indicator"></div>
        <div className="tooltip" style={{top: tooltipTop, left: tooltipLeft}}>{per}</div>
      </div>
    );
  };
  
  export default ProgressBar;