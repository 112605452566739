import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../../utils/Util'
import ocsHighlighRow from '../../utils/OcsHighlighRow'
class ClinicServices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clinicservicesList: [],
            codesetItemId: '0',
            servicesList: [],
            serviceName: '',
            serviceId: '',
            serviceTypeName: '',
            serviceTypeId: '',
            showList: false,
            showModal: false,
            clinicservicetypeList: [],
            highlevelfilterservices: [],
            sortConfig: {
                'serviceName': { dataField: 'serviceName', order: 'asc' },
            },
        }
        this.AddOpen = this.AddOpen.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.getClinicServiceList = this.getClinicServiceList.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getClinicServiceList()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.clinicservicesList, 'servicesId', UserProfile.getHightlightIds())
        this.setState({ clinicservicesList: this.state.clinicservicesList });
        this.node.paginationContext.currPage = p;
    }
    getClinicServiceList() {
        if (UserProfile.getClinicId() > 0) {
            axios.get('/clinicservicesforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                .then(response1 => {
                    axios.get('/services', { headers: authHeader() })
                        .then(response2 => {
                            axios.get('/servicetypeslist', { headers: authHeader() })
                                .then(response3 => {
                                    axios.get('/getservicetypelist/' + UserProfile.getClinicId(), { headers: authHeader() })
                                        .then(response4 => {
                                            axios.get('/gethighLevelfilterclinicservices/' + UserProfile.getClinicId(), { headers: authHeader() })
                                                .then(response5 => {
                                                    let dataset = [...response1.data]
                                                    dataset = dataset.map((t) => ({ ...t, serviceName: response2.data.find((r) => r.codesetItemId === t.serviceId).name }))
                                                    dataset = dataset.map((t) => ({ ...t, serviceTypeName: response3.data.find((r) => r.codesetItemId === t.servicetypeId).name }))
                                                    dataset.sort((a, b) => { return a.serviceName < b.serviceName ? -1 : a.serviceName > b.serviceName ? 1 : 0 })
                                                    this.setState({
                                                        highlevelfilterservices: response5.data,
                                                        clinicservicetypeList: response4.data,
                                                        clinicservicesList: dataset,
                                                        codesetItemId: UserProfile.getClinicId(),
                                                        showList: true,
                                                        servicesList: response2.data,
                                                    });
                                                    this.gotoPage.click();
                                                })
                                                .catch(function (error) {
                                                    console.log('response2 : ', error);
                                                    history.push('/Errorpage')
                                                })
                                        })
                                        .catch(function (error) {
                                            console.log('response4 : ', error);
                                            history.push('/Errorpage')
                                        })
                                })
                                .catch(function (error) {
                                    console.log(error);
                                    history.push('/Errorpage')
                                })
                        })
                        .catch(function (error) {
                            console.log(error);
                            history.push('/Errorpage')
                        })
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }
    }
    AddOpen() {
        history.push('/ClinicServicesadd')
    }
    EditOpen(id) {
        history.push('/ClinicServicesedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/clinicservicesdel/' + this.state.serviceId, '', { headers: authHeader() })
            .then(response => {
                this.getClinicServiceList()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `'${this.state.serviceName}' Service for '${UserProfile.getClinicName()}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name) {
        this.setState({
            serviceId: id,
            serviceName: name,
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'sno',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '50px' }
            },
            {
                dataField: 'serviceName',
                text: 'Service',
                sort: true,
                headerStyle: { width: '150px' },
            },
            {
                dataField: 'serviceTypeName',
                text: 'Service Type',
                sort: true,
                headerStyle: { width: '150px' },
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                headerStyle: { width: '250px' }
            },
            {
                dataField: 'activeStatus',
                text: 'Active',
                sort: true,
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cell, row) => (
                    row.activeStatus == 'Y' ? 'Yes' : 'No'
                ),
                align: 'center'
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.servicesId)} data-tip="Edit">Edit</button>&nbsp;
                        {this.state.highlevelfilterservices.find(x => x.serviceId === row.servicesId) ?
                            <button type="button" className="btn btn-secondary" disabled onClick={this.handleShow.bind(this, row.servicesId, this.state.servicesList.filter(services => services.codesetItemId === row.serviceId).map((x, i) => { return x.name }))} data-tip="Delete">Delete</button>
                            :
                            <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.servicesId, this.state.servicesList.filter(services => services.codesetItemId === row.serviceId).map((x, i) => { return x.name }))} data-tip="Delete">Delete</button>
                        }
                    </div>
                ),
                align: 'center'
            }
        ];
        return (
            <div>
                <div className="form-row align-items-center">
                    <div className="col">
                        <div className="float-right">
                            <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                                <div className="d-flex justify-content-end">
                                    <ReactTooltip />
                                    {this.state.clinicservicetypeList.length === 0 ?
                                        <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" disabled onClick={this.AddOpen} >Add Service</button>
                                        :
                                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen} >Add Service</button>
                                    }
                                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <div>
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete the service <b>{this.state.serviceName}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            keyField='servicesId'
                            data={this.state.clinicservicesList}
                            columns={columns}
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory()}
                            noDataIndication="No Record(s)"
                            striped hover condensed
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default ClinicServices
