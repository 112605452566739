import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import authHeader from '../../authentication/authHeader'
import moment from 'moment'
import ReactTooltip from 'react-tooltip';
import UserProfile from '../../UserProfile'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
class DonotStartAdd extends Component {
    constructor(props) {
        super(props)
        this.errorList = React.createRef()
        this.state = {
            clinicId: UserProfile.getClinicId(),
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            all: false,
            servicetypeId: '',
            serviceTypeList: [],
            clinicServiceTypeList: [],
            durationTiming: [{ block: 0, startTime: '', endTime: '' }],
            clinicScheduleBlock: 0,
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/servicetypeslist', { headers: authHeader() })
            .then(response => {
                this.setState({
                    serviceTypeList: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        axios.get('/clinicserviceTypesforClinic/' + this.state.clinicId, { headers: authHeader() })
            .then(response2 => {
                this.setState({
                    clinicServiceTypeList: response2.data
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        axios.get('/clinics/' + this.state.clinicId, { headers: authHeader() })
            .then(response2 => {
                axios.get('/timingsforclinic/' + this.state.clinicId, { headers: authHeader() })
                    .then(response => {
                        let minopen = response.data.reduce(function (min, cur) {
                            return moment(cur.clinicOpenTime, 'HH:mm') < moment(min.clinicOpenTime, 'HH:mm') ? cur : min;
                        });
                        let maxclose = response.data.reduce(function (max, cur) {
                            return moment(cur.clinicCloseTime, 'HH:mm') < moment(max.clinicCloseTime, 'HH:mm') ? max : cur;
                        });
                        let st = minopen.clinicOpenTime;
                        let durationTiming = [];
                        let i = 0
                        while (moment(st, "HH:mm") < moment(maxclose.clinicCloseTime, "HH:mm")) {
                            durationTiming.push({ block: i++, startTime: '', endTime: '' });
                            st = moment(st, "HH:mm").add(response2.data.scheduleTimeBlock, 'minutes').format('HH:mm');
                        }
                        this.setState({
                            durationTiming: durationTiming,
                            clinicScheduleBlock: response2.data.scheduleTimeBlock
                        });
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    handleChange = (event) => {
        if (event.target.type === 'checkbox') {
            this.setState({
                [event.target.name]: event.target.checked
            });
        } else if (event.target.name.includes("Time")) {
            let e = event.target;
            this.setState((state) => {
                let ev = e;
                const list = state.durationTiming.map((item) => {
                    if (ev.dataset.block == item.block) {
                        if (ev.dataset.name === 'startTime')
                            item.startTime = ev.value;
                        else
                            item.endTime = ev.value;
                    }
                });
                return {
                    list,
                };
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }
    handleSave = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            alert('There seems to be invalid inputs, please review')
            return;
        }

        let timimg = [];
        this.state.durationTiming.map((item, key) => {
            if ((document.getElementById('startTime' + key).value.length + document.getElementById('endTime' + key).value.length > 0)
                && (document.getElementById('startTime' + key).value.length === 0 || document.getElementById('endTime' + key).value.length === 0)) {
                document.getElementById('startTime' + key).style.borderColor = "red";
                document.getElementById('endTime' + key).style.borderColor = "red";
            }
            if (document.getElementById('startTime' + key).value.length > 0 && document.getElementById('endTime' + key).value.length > 0) {
                timimg.push(1)
            }
        })
        if (timimg.length === 0) {
            this.setState({ errormessage: 'Enter from and to hours at least for one duration' })
            alert('There seems to be invalid inputs, please review')
            window.scrollTo(0, this.errorList.current.offsetTop)
            return
        }

        let arr = []
        if (this.state.all === true) {
            arr = ['1', '2', '3', '4', '5', '6', '7']
        } else {
            if (this.state.sunday === true) arr.push("1");
            if (this.state.monday === true) arr.push("2");
            if (this.state.tuesday === true) arr.push("3");
            if (this.state.wednesday === true) arr.push("4");
            if (this.state.thursday === true) arr.push("5");
            if (this.state.friday === true) arr.push("6");
            if (this.state.saturday === true) arr.push("7");
        }

        let donotstarts = []
        arr.forEach((ar) => {
            this.state.durationTiming.map((item, key) => {
                if (document.getElementById('startTime' + key).value.length > 0 && document.getElementById('endTime' + key).value.length > 0) {
                    donotstarts.push({
                        clinicId: this.state.clinicId,
                        dayOfWeek: ar,
                        servicetypeId: this.state.servicetypeId,
                        scheduleBlockDuration: key + 1,
                        startTime: document.getElementById('startTime' + key).value,
                        endTime: document.getElementById('endTime' + key).value,
                        createdBy: 'administrator',
                        lastUpdatedBy: 'administrator',
                    })
                }
            })
        })

        axios.post('/donotstart', donotstarts, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights(response.data, 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/9');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleCancel = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/9');
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title" >
                    <h4 ref={this.errorList}>Add Do not Start</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <label style={{ color: 'red' }}>{this.state.errormessage}</label>
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b></td>
                            </tr>
                            <tr>
                                <td>Service Type<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.servicetypeId} name="servicetypeId" id="servicetypeId" onChange={this.handleChange}
                                        ref={this.setFocus}>
                                        <option value="0">-- Select Service Type --</option>
                                        {this.state.clinicServiceTypeList.length > 0
                                            && this.state.clinicServiceTypeList.map((services, i) => {
                                                return (
                                                    <option key={i} value={services[2]}>{this.state.serviceTypeList.filter(serviceType => serviceType.codesetItemId === services[2]).map((x, i) => { return x.name })}</option>
                                                )
                                            })}
                                    </select>
                                    {this.validator.message('servicetypeId', this.state.servicetypeId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Day(s)<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-row col-auto my-1 d-flex justify-content-start" id="checkbox-group">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="sunday"
                                                value={this.state.sunday}
                                                onChange={this.handleChange}
                                                id="sunday" />
                                            <label className="form-check-label" htmlFor="sunday">Sun</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="monday"
                                                value={this.state.monday}
                                                onChange={this.handleChange}
                                                id="monday" />
                                            <label className="form-check-label" htmlFor="monday">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="tuesday"
                                                value={this.state.tuesday}
                                                onChange={this.handleChange}
                                                id="tuesday" />
                                            <label className="form-check-label" htmlFor="tuesday">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="wednesday"
                                                value={this.state.wednesday}
                                                onChange={this.handleChange}
                                                id="wednesday" />
                                            <label className="form-check-label" htmlFor="wednesday">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="thursday"
                                                value={this.state.thursday}
                                                onChange={this.handleChange}
                                                id="thursday" />
                                            <label className="form-check-label" htmlFor="thursday">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="friday"
                                                value={this.state.friday}
                                                onChange={this.handleChange}
                                                id="friday" />
                                            <label className="form-check-label" htmlFor="friday">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="saturday"
                                                value={this.state.saturday}
                                                onChange={this.handleChange}
                                                id="saturday" />
                                            <label className="form-check-label" htmlFor="saturday">Sat</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="all"
                                                value={this.state.all}
                                                onChange={this.handleChange}
                                                id="all" />
                                            <label className="form-check-label" htmlFor="all">All</label>
                                        </div>
                                    </div>
                                    {this.validator.message('Days', this.state.sunday, 'required|anyOneCheckboxRequired:' + (this.state.sunday || this.state.monday || this.state.tuesday || this.state.wednesday || this.state.thursday || this.state.friday || this.state.saturday || this.state.all))}
                                </td>
                            </tr>
                            {
                                this.state.durationTiming.length > 0 && this.state.durationTiming.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{key + 1} X {this.state.clinicScheduleBlock} = {(key + 1) * this.state.clinicScheduleBlock} Minutes</td>
                                            <td>
                                                <div className="input-group">
                                                    <InputMask value={this.state.durationTiming[key].startTime}
                                                        name={'startTime' + key} onChange={this.handleChange} data-name="startTime" data-block={item.block}
                                                        id={'startTime' + key} className="col-lg-2 form-control" placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">and</span>
                                                    </div>
                                                    <InputMask value={this.state.durationTiming[key].endTime}
                                                        name={'endTime' + key} onChange={this.handleChange} data-name="enndTime" data-block={item.block}
                                                        id={'endTime' + key} className="col-lg-2 form-control" placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                                </div>
                                                {
                                                    this.validator.message('Timings' + key, this.state.durationTiming[key].startTime,
                                                        'fromTimeToTimeIsValid:' + this.state.durationTiming[key].startTime.replace(':', '`') + "," + this.state.durationTiming[key].endTime.replace(':', '`'))
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.handleSave} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.handleCancel} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default DonotStartAdd