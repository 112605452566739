import React, { Component } from 'react'
import history from '../../history';
import axios from 'axios'
import UserProfile from '../UserProfile'
import authHeader from '../authentication/authHeader'
import AuthService from '../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../utils/ocsvalidators'
import { RequiredTitle } from '../utils/Util'
class ClinicEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            codesetItemId: '',
            codesetId: '',
            oncosmartSystemId: '',
            name: '',
            description: '',
            scheduleTimeBlock: '',
            nurseNeededforOngoing: '',
            displayOrder: '',
            activeStatus: 'Y',
            errors: [],
            oncosmartSystemIdOld: '',
            showError: false,
            errorMsg: '',
            createdBy: '',
            organizations: [],
            clients: [],
            selectedOrganizationId: '',
            selectedClientId: '',
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        let codesetitemid = this.props.match.params
        let id = codesetitemid.codeitemid.replace(":", "")

        axios.get('/organizations', { headers: authHeader() })
            .then(resp => {
                let dataset = [...resp.data]
                dataset.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                axios.get('/clients/' + UserProfile.getOrganizationId(), { headers: authHeader() })
                    .then(res => {
                        let dataset1 = [...res.data]
                        dataset1.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                        axios.get('/clinics/' + id, { headers: authHeader() })
                            .then(response => {
                                this.setState({
                                    codesetItemId: response.data.codesetItemId, codesetId: response.data.codesetId, oncosmartSystemId: response.data.oncosmartSystemId, oncosmartSystemIdOld: response.data.oncosmartSystemId, name: response.data.name, description: response.data.description,
                                    scheduleTimeBlock: response.data.scheduleTimeBlock, nurseNeededforOngoing: response.data.nurseNeededforOngoing,
                                    displayOrder: response.data.displayOrder, activeStatus: response.data.activeStatus, createdBy: response.data.createdBy,
                                    organizations: dataset,
                                    clients: dataset1,
                                    selectedOrganizationId: UserProfile.getOrganizationId(),
                                    selectedClientId: UserProfile.getClientId(),
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addClinic = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        if (this.state.oncosmartSystemIdOld == this.state.oncosmartSystemId) {
            axios.put('/clinics/' + this.state.codesetItemId, {
                codesetItemId: this.state.codesetItemId,
                codesetId: this.state.codesetId,
                oncosmartSystemId: this.state.oncosmartSystemId,
                name: this.state.name,
                description: this.state.description,
                scheduleTimeBlock: this.state.scheduleTimeBlock,
                nurseNeededforOngoing: this.state.nurseNeededforOngoing,
                displayOrder: this.state.displayOrder,
                activeStatus: this.state.activeStatus,
                deletedYN: 'N',
                createdBy: this.state.createdBy,
                lastUpdatedBy: AuthService.getCurrentUserName(),
                parentId: this.state.selectedClientId
            }, { headers: authHeader() })
                .then(response => {
                    axios.get('/aclinicslist/' + UserProfile.getGlobalClientParams().id, { headers: authHeader() })
                        .then(resp => {
                            axios.get('/clinics', { headers: authHeader() })
                                .then(res => {
                                    let clinics = []
                                    resp.data.forEach(element => {
                                        clinics.push({ id: element.codesetItemId, name: element.name, displayOrder: element.displayOrder })
                                    });
                                    clinics.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                                    let t = UserProfile.getGlobalClientParams()
                                    t.clinics = clinics
                                    t.allClinics = res.data
                                    localStorage.setItem("client", JSON.stringify(t));
                                    UserProfile.setOrganizationId(this.state.selectedOrganizationId)
                                    UserProfile.setClientId(this.state.selectedClientId)
                                    UserProfile.setPage("Clinics");
                                    UserProfile.setHighlights(response.data, 'success')
                                    history.push('/standardcode');
                                })
                                .catch(function (error) {
                                    console.log(error);
                                })
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        } else {
            axios.get('/clinicsforoncosmart/' + this.state.oncosmartSystemId, { headers: authHeader() })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            showError: true,
                            errorMsg: 'OncoSmart System ID already exists'
                        });
                    } else {
                        axios.put('/clinics/' + this.state.codesetItemId, {
                            codesetItemId: this.state.codesetItemId,
                            codesetId: this.state.codesetId,
                            oncosmartSystemId: this.state.oncosmartSystemId,
                            name: this.state.name,
                            description: this.state.description,
                            scheduleTimeBlock: this.state.scheduleTimeBlock,
                            nurseNeededforOngoing: this.state.nurseNeededforOngoing / 100,
                            displayOrder: this.state.displayOrder,
                            activeStatus: this.state.activeStatus,
                            deletedYN: 'N',
                            createdBy: this.state.createdBy,
                            lastUpdatedBy: AuthService.getCurrentUserName(),
                            parentId: this.state.selectedClientId
                        }, { headers: authHeader() })
                            .then(response => {
                                axios.get('/aclinicslist/' + UserProfile.getGlobalClientParams().id, { headers: authHeader() })
                                    .then(resp => {
                                        axios.get('/clinics', { headers: authHeader() })
                                            .then(res => {
                                                let clinics = []
                                                resp.data.forEach(element => {
                                                    clinics.push({ id: element.codesetItemId, name: element.name, displayOrder: element.displayOrder })
                                                });
                                                clinics.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                                                let t = UserProfile.getGlobalClientParams()
                                                t.clinics = clinics
                                                t.allClinics = res.data
                                                localStorage.setItem("client", JSON.stringify(t));
                                                UserProfile.setOrganizationId(this.state.selectedOrganizationId)
                                                UserProfile.setClientId(this.state.selectedClientId)
                                                UserProfile.setPage("Clinics");
                                                UserProfile.setHighlights(response.data, 'success')
                                                history.push('/standardcode');
                                            })
                                            .catch(function (error) {
                                                console.log(error);
                                            })
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    })
                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }
    }
    cancelClinic = (event) => {
        event.preventDefault();
        UserProfile.setPage("Clinics");
        UserProfile.setHighlights([this.state.codesetItemId], 'info')
        history.push('/standardcode');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        this.setState({
            [field]: value
        });
    }
    organizationChangeHandler = (event) => {
        const value = event.target.value
        axios.get('/clients/' + value, { headers: authHeader() })
            .then(response => {
                let dataset = [...response.data]
                dataset.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                this.setState({
                    selectedOrganizationId: value,
                    selectedClientId: '0',
                    clients: dataset
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Edit Clinic</h4>
                </div>
                <RequiredTitle />
                <div style={{ display: (this.state.showError ? 'block' : 'none') }}>
                    <label htmlFor="error" style={{ color: 'red' }}>{this.state.errorMsg}</label>
                </div>
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered table-sm">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td>Organization:<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <select className="form-control" value={this.state.selectedOrganizationId}
                                        name="selectedOrganizationId" onChange={this.organizationChangeHandler}
                                        ref={this.setFocus}>
                                        <option value="0">--  Select Organization  --</option>
                                        {this.state.organizations.length > 0
                                            && this.state.organizations.map((o, i) => {
                                                return (
                                                    <option key={i} value={o.codesetItemId}>{o.name}</option>
                                                )
                                            })}
                                    </select>
                                    {this.validator.message('selectedOrganizationId', this.state.selectedOrganizationId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Client:<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <select className="form-control" value={this.state.selectedClientId}
                                        name="selectedClientId" onChange={this.handleChange}>
                                        <option value="0">--  Select Client  --</option>
                                        {this.state.clients.length > 0
                                            && this.state.clients.map((o, i) => {
                                                return (
                                                    <option key={i} value={o.codesetItemId}>{o.name}</option>
                                                )
                                            })}
                                    </select>
                                    {this.validator.message('selectedClientId', this.state.selectedClientId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">OncoSmart System ID<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="number"
                                    name="oncosmartSystemId"
                                    value={this.state.oncosmartSystemId}
                                    onChange={this.handleChange}
                                    id="oncosmartSystemId" className="form-control col-lg-4" min="0" max="999999" />
                                    {this.validator.message('OncoSmart System ID', this.state.oncosmartSystemId, 'required|numeric|min:0,num|numeric|max:999999,num')}
                                </td>
                            </tr>

                            <tr>
                                <td className="col-auto">Name<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    id="name" className="form-control" placeholder="Clinic name" />
                                    {this.validator.message('Name', this.state.name, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td>Description<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><textarea className="form-control"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    id="description" rows="3" placeholder="Clinic description"></textarea>Maximum 200 characters
                                    {this.validator.message('Description', this.state.description, 'required|maxLength:200')}
                                </td>
                            </tr>
                            <tr>
                                <td>Scheduling Time Blocks<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.scheduleTimeBlock} validations={['required']} name="scheduleTimeBlock" id="scheduleTimeBlock" onChange={this.handleChange}>
                                        <option value="05">05</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="25">25</option>
                                        <option value="30">30</option>
                                        <option value="35">35</option>
                                        <option value="40">40</option>
                                        <option value="45">45</option>
                                        <option value="50">50</option>
                                        <option value="55">55</option>
                                        <option value="60">60</option>
                                    </select> &nbsp;Minutes</td>
                                {this.validator.message('scheduleTimeBlock', this.state.scheduleTimeBlock, 'required|not_in:0')}
                            </tr>
                            <tr>
                                <td>Value of a nurse needed for ongoing treatment<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline"><input type="number"
                                    name="nurseNeededforOngoing"
                                    value={this.state.nurseNeededforOngoing}
                                    onChange={this.handleChange}
                                    id="nurseNeededforOngoing" className="col-lg-4 form-control" min="0" max="100" />&nbsp;%
                                    {this.validator.message('Value of a nurse needed for ongoing treatment', this.state.nurseNeededforOngoing, 'required|numeric|min:0,num|numeric|max:100,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Display Order<span className='text-danger ml-1'>*</span></td>
                                <td><input type="number"
                                    name="displayOrder"
                                    value={this.state.displayOrder}
                                    onChange={this.handleChange}
                                    id="displayOrder" className="col-lg-4 form-control" min="1" max="99999" />
                                    {this.validator.message('Display Order', this.state.displayOrder, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" validations={['required']} name="activeStatus" id="activeStatusY" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="activeStatusY">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" validations={['required']} name="activeStatus" id="activeStatusN" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="activeStatusN">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addClinic} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClinic} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ClinicEdit
