import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import history from '../../history';
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import ocsHighlighRow from '../utils/OcsHighlighRow'
import UserProfile from '../UserProfile'
import multisort from 'multisort';
import { findPageIndex } from '../utils/Util'
import { Store } from 'react-notifications-component'
class Clinics extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            clinicList: [],
            showAdd: false,
            clinicname: '',
            showModal: false,
            clinicid: '',
            clinicdeleteenable: [],
            showClinics: false,
            selectedOrganizationId: '',
            selectedClientId: '',
            organizations: [],
            clients: [],
            sortConfig: {
                'oncosmartSystemId': { dataField: 'oncosmartSystemId', order: 'asc' },
            },
        }
        this.AddOpen = this.AddOpen.bind(this);
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        let org = ''
        let cli = ''
        if (parseInt(UserProfile.getOrganizationId()) === 0) {
            UserProfile.setOrganizationId(UserProfile.getGlobalClientParams().organizationId)
            UserProfile.setClientId(UserProfile.getGlobalClientParams().id)
        }
        if (parseInt(UserProfile.getOrganizationId()) > 0) org = UserProfile.getOrganizationId()
        if (parseInt(UserProfile.getClientId()) > 0) cli = UserProfile.getClientId()
        if (parseInt(org) > 0 || parseInt(cli) > 0) {
            this.setState({
                selectedOrganizationId: org,
                selectedClientId: cli
            }, () => {
                this.getOrganizations()
                if (parseInt(org) > 0) {
                    this.getClients()
                    if (parseInt(cli) > 0) {
                        this.getClinics()
                    }
                }
            })
        } else {
            this.getOrganizations()
        }
    }
    getOrganizations() {
        axios.get('/organizations', { headers: authHeader() })
            .then(response => {
                let dataset = [...response.data]
                dataset.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                this.setState({
                    organizations: dataset
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    getClients() {
        axios.get('/clients/' + this.state.selectedOrganizationId, { headers: authHeader() })
            .then(response => {
                let dataset = [...response.data]
                dataset.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                this.setState({
                    clients: dataset
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    getClinics() {
        axios.get('/clinicslist/' + this.state.selectedClientId, { headers: authHeader() })
            .then(response1 => {
                axios.get('/checkdeleteenable/1', { headers: authHeader() })
                    .then(response2 => {
                        let dataset = [...response1.data]
                        dataset.sort((a, b) => { return a.oncosmartSystemId < b.oncosmartSystemId ? -1 : a.oncosmartSystemId > b.oncosmartSystemId ? 1 : 0 })
                        this.setState({
                            clinicdeleteenable: response2.data,
                            clinicList: dataset,
                            showClinics: true,
                        });
                        this.gotoPage.click();
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.clinicList, 'codesetItemId', UserProfile.getHightlightIds())
        this.setState({ clinicList: this.state.clinicList });
        this.node.paginationContext.currPage = p;
    }
    AddOpen() {
        history.push('/Clinicadd')
    }
    EditOpen(id) {
        history.push('/Clinicedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/clinicsdel/' + id, '', { headers: authHeader() })
            .then(response1 => {
                this.handleClose()
                axios.get('/clinicslist/' + this.state.selectedClientId, { headers: authHeader() })
                    .then(response2 => {
                        let dataset = [...response2.data]
                        dataset.sort((a, b) => { return a.oncosmartSystemId < b.oncosmartSystemId ? -1 : a.oncosmartSystemId > b.oncosmartSystemId ? 1 : 0 })
                        this.setState({ clinicList: dataset });
                        UserProfile.setPage("Clinics");
                        UserProfile.setHighlights([], 'success')
                        this.gotoPage.click();
                        Store.addNotification({
                            title: "Deleted",
                            message: `Clinic '${this.state.clinicname}' is deleted`,
                            type: "info",
                            insert: "top",
                            container: "top-right",
                            dismiss: {
                                duration: window['runConfig'].deleteNotification.displayInterval,
                                onScreen: true
                            }
                        });
                    })
                    .catch(function (error) {
                        console.log('response2', error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log('response1', error);
                history.push('/Errorpage')
            })
    }
    CopySettingOpen(id) {
        history.push('/Cliniccopysetting:' + id)
    }
    organizationChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            showClinics: false,
        }, () => {
            this.getClients()
            UserProfile.setOrganizationId(this.state.selectedOrganizationId)
        })
    }
    clientChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            showClinics: false,
        }, () => {
            this.getClinics()
            UserProfile.setClientId(this.state.selectedClientId)
        })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(name, id) {
        this.setState({
            clinicname: name,
            showModal: true,
            clinicid: id
        })
    }
    render() {
        const columns = [
            {
                dataField: 'codesetItemId',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '5%' },
                align: 'right'
            },
            {
                dataField: 'oncosmartSystemId',
                text: 'OncoSmart System ID',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                headerStyle: { width: '19%' }
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                headerStyle: { width: '19%' }
            },
            {
                dataField: 'scheduleTimeBlock',
                text: 'Scheduling Time Blocks',
                sort: true,
                headerStyle: { width: '12%', textAlign: 'right' },
                formatter: (cell, row) => (
                    row.scheduleTimeBlock + ' Minutes'
                ),
                align: 'right'
            },
            {
                dataField: 'nurseNeededforOngoing',
                text: 'A Nurse for Ongoing Treatment',
                sort: true,
                headerStyle: { width: '12%', textAlign: 'right' },
                formatter: (cell, row) => (
                    row.nurseNeededforOngoing + ' %'
                ),
                align: 'right'
            },
            {
                dataField: 'displayOrder',
                text: 'Display Order',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'activeStatus',
                text: 'Active',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'center' },
                formatter: (cell, row) => (
                    row.activeStatus === 'Y' ? 'Yes' : 'No'
                ),
                align: 'center'
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '14%', textAlign: 'center' },
                formatter: (cellContent, row, index) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.codesetItemId)} data-tip="Edit">Edit</button>&nbsp;
                        {this.state.clinicdeleteenable.find(x => x === row.codesetItemId) ?
                            <button type="button" className="btn btn-secondary" disabled onClick={this.handleShow.bind(this, row.name, row.codesetItemId)} data-tip="Delete">Delete</button>
                            :
                            <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.name, row.codesetItemId)} data-tip="Delete">Delete</button>
                        }
                        <button type="button" className="btn btn-primary" style={{ 'marginTop': '5px' }} onClick={this.CopySettingOpen.bind(this, row.codesetItemId)} data-tip="Copy Setting">Copy Setting</button>
                    </div>
                ),
                align: 'center'
            }
        ];
        return (
            <div>
                <div className="form-row mt-1">
                    <div className="col-auto">
                        <select className="form-control" value={this.state.selectedOrganizationId}
                            name="selectedOrganizationId" onChange={this.organizationChangeHandler}>
                            <option value="0">--  Select Organization  --</option>
                            {this.state.organizations.length > 0
                                && this.state.organizations.map((o, i) => {
                                    return (
                                        <option key={i} value={o.codesetItemId}>{o.name}</option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className="col-auto">
                        <select className="form-control" value={this.state.selectedClientId}
                            name="selectedClientId" onChange={this.clientChangeHandler}>
                            <option value="0">--  Select Client  --</option>
                            {this.state.clients.length > 0
                                && this.state.clients.map((o, i) => {
                                    return (
                                        <option key={i} value={o.codesetItemId}>{o.name}</option>
                                    )
                                })}
                        </select>
                    </div>
                </div>
                <div style={{ display: (this.state.showClinics ? 'block' : 'none') }}>
                    <div className="d-flex justify-content-end">
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen}>Add Clinic</button>
                        <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                    </div>
                    <div>
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete the clinic <b>{this.state.clinicname}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this, this.state.clinicid)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <BootstrapTable
                        remote={{
                            filter: false,
                            pagination: false,
                            sort: true,
                            cellEdit: false
                        }}
                        keyField='codesetItemId'
                        data={this.state.clinicList}
                        columns={columns}
                        ref={(n) => (this.node = n)}
                        pagination={paginationFactory()}
                        noDataIndication="No Record(s)"
                        striped hover condensed
                        rowClasses='table table-sm'
                        headerClasses="thread"
                        selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                        onTableChange={this.handleTableChange}
                        sort={{ sortCaret: this.headerSortingClasses }}
                    />
                </div>
            </div>
        )
    }
}

export default Clinics;
