import React, { Component } from 'react';
import axios from 'axios'
import history from '../history';
import AuthService from './authentication/AuthService'
import authHeader from './authentication/authHeader'
class ErrorPage extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            show: false,
        }
    }
    componentDidMount(){
        axios.get('/organizations',{ headers: authHeader() })
        .then(response => {
            this.setState({show: true})
        })
        .catch(function (error) {
            if (error.response) {
                if (error.response.status === 401){
                    history.push('/SessionTimeout')
                } else {
                    this.setState({show: true})
                }
            } else {
                this.setState({show: true})
            }
        })
    }
    componentWillUnmount(){
    }
    Login(){
        AuthService.logout()
        history.push('/')
    }
    render() {
        return (
            <div style={{ display: (this.state.show ? 'block' : 'none') }}>
                <div className="jumbotron">
                    <div className="container">
                        <h1 className="display-4">Something went wrong...</h1>
                        <hr className="my-4"></hr>
                        <p className="lead">There was an error in handling your request. Please try again.</p>
                        <p className="lead">
                            <a className="btn btn-primary btn-lg" href="/Home" role="button">Go to Home</a>
                        </p>                            
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;