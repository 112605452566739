import React, { Component } from 'react'
import { Form } from 'reactstrap';
import UserProfile from '../UserProfile'
import history from '../../history';
import axios from 'axios'
import authHeader from '../authentication/authHeader'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../utils/ocsvalidators'
import Spinner from 'react-bootstrap/Spinner'
class ClinicCopySetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            oncosmartSystemId: 0,
            name: '',
            clinicList: [],
            copyFrom: '',
            copyTiming: false,
            copyResource: false,
            copyService: false,
            copyHighLevelFilter: false,
            copyMaxTreatmentStart: false,
            copyStaff: false,
            copyStaffAvailability: false,
            newClinicTimingCount: 0,
            newClinicTiming: [],
            newClinicResourceCount: 0,
            newClinicServiceTypeCount: 0,
            newClinicServiceCount: 0,
            newClinicService: [],
            newClinicHighLevelFilterCount: 0,
            newClinicMaxTreatmentStartCount: 0,
            newStaffCount: 0,
            newStaffAvailabilityCount: 0,
            newClinicSchedulingTimeBlock: 0,
            oldClinicSchedulingTimeBlock: 0,
            showOverwriteModel: false,
            errors: [],
            showHLFOverview: false,
            serviceOverview: [],
            timingOverview: [],
            oldClinicName: '',
            showSpinner: false,
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        let codesetitemid = this.props.match.params
        let id = codesetitemid.codeitemid.replace(":", "")
        axios.get('/clinics/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    id: id, oncosmartSystemId: response.data.oncosmartSystemId, name: response.data.name,
                    newClinicSchedulingTimeBlock: response.data.scheduleTimeBlock
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/aclinicslist/' + UserProfile.getGlobalClientParams().id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    clinicList: response.data,
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/timingsforclinic/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newClinicTimingCount: response.data.length,
                    newClinicTiming: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/clinicresourcesforclinic/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newClinicResourceCount: response.data.length
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/clinicserviceTypesforClinic/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newClinicServiceTypeCount: response.data.length
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/clinicservicesforclinic/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newClinicServiceCount: response.data.length,
                    newClinicService: response.data
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/gethighLevelfilterclinicservices/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newClinicHighLevelFilterCount: response.data.length
                })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/maxtreatstartforclinic/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newClinicMaxTreatmentStartCount: response.data.length
                })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/staffclinics/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newStaffCount: response.data.length
                })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/staffavailabilityforClinic/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    newStaffAvailabilityCount: response.data.length
                })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
    }
    onCancel = (event) => {
        event.preventDefault();
        UserProfile.setPage("Clinics");
        UserProfile.setHighlights([parseInt(this.state.id)], 'info')
        history.push('/standardcode');
    }
    onPreSave = (event) => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        if ((this.state.copyTiming && this.state.newClinicTimingCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyResource && this.state.newClinicResourceCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyService && this.state.newClinicServiceTypeCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyService && this.state.newClinicServiceCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyHighLevelFilter && this.state.newClinicHighLevelFilterCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyMaxTreatmentStart && this.state.newClinicMaxTreatmentStartCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyStaff && this.state.newStaffCount > 0 && this.state.copyFrom > 0) ||
            (this.state.copyStaffAvailability && this.state.newStaffAvailabilityCount > 0 && this.state.copyFrom > 0)) {
            this.setState({
                showOverwriteModel: true
            })
        } else {
            this.onPreSave2(event)
        }
    }
    onPreSave2 = (event) => {
        if (this.state.copyHighLevelFilter) {
            if (this.state.timingOverview.length === 0 && (!this.state.copyTiming)) {
                alert('Please input/copy clinic timings. Without clinic timing cannot build high level filter/max treatment start')
                this.setState({ showOverwriteModel: false });
            } else if (this.state.copyHighLevelFilter) {
                this.setState({ showOverwriteModel: false, showHLFOverview: true });
            } else {
                this.onSave(event)
            }
        } else {
            this.onSave(event)
        }
    }
    onSave = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        this.setState({
            showSpinner: true
        })
        let post = { clinicId: this.state.id }
        if (this.state.copyTiming) post['copyTimingFrom'] = this.state.copyFrom
        if (this.state.copyResource) post['copyResourceFrom'] = this.state.copyFrom
        if (this.state.copyService) post['copyServiceTypeFrom'] = this.state.copyFrom
        if (this.state.copyService) post['copyServiceFrom'] = this.state.copyFrom
        if (this.state.copyHighLevelFilter) post['copyHighLevelFilterFrom'] = this.state.copyFrom
        if (this.state.copyMaxTreatmentStart) post['copyMaxTreatmentStartFrom'] = this.state.copyFrom
        if (this.state.copyStaff) post['copyStaffFrom'] = this.state.copyFrom
        if (this.state.copyStaffAvailability) post['copyStaffAvailabilityFrom'] = this.state.copyFrom
        axios.post('/copysetting', post, { headers: authHeader() })
            .then(response => {
                UserProfile.setPage("Clinics");
                UserProfile.setHighlights([parseInt(this.state.id)], 'success')
                history.push('/standardcode');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleChange = (event) => {
        if (event.target.type == "checkbox") {
            if (event.target.name === 'copyStaffAvailability' && event.target.checked) {
                this.setState({
                    copyStaffAvailability: event.target.checked,
                    copyStaff: event.target.checked,
                })
            } else if (event.target.name === 'copyStaff' && (!event.target.checked)) {
                this.setState({
                    copyStaffAvailability: event.target.checked,
                    copyStaff: event.target.checked,
                })
            } else {
                this.setState({
                    [event.target.name]: event.target.checked,
                })
            }
        }
    }
    copyFromChanged = (event) => {
        if (event.target.value > 0) {
            axios.get('/clinics/' + event.target.value, { headers: authHeader() })
                .then(response => {
                    this.setState({
                        oldClinicSchedulingTimeBlock: response.data.scheduleTimeBlock,
                        oldClinicName: response.data.name
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                });
            axios.get('/timingsforclinic/' + event.target.value, { headers: authHeader() })
                .then(response => {
                    let tt = []
                    let days = { 1: 'Sun', 2: 'Mon', 3: 'Tue', 4: 'Wed', 5: 'Thu', 6: 'Fri', 7: 'Sat' }
                    for (let i = 0; i < this.state.newClinicTiming.length; i++) {
                        let found = false;
                        let t = { sourceStartClass: 'text-warning', sourceEndClass: 'text-warning', targetStartClass: 'text-warning', targetEndClass: 'text-warning' }
                        for (let j = 0; j < response.data.length; j++) {
                            if (response.data[j].dayOfWeek === this.state.newClinicTiming[i].dayOfWeek) {
                                found = true
                                let clinicOpenSame = false
                                let clinicCloseSame = false
                                if (response.data[j].clinicOpenTime === this.state.newClinicTiming[i].clinicOpenTime)
                                    clinicOpenSame = true
                                if (response.data[j].clinicCloseTime === this.state.newClinicTiming[i].clinicCloseTime)
                                    clinicCloseSame = true

                                if (clinicOpenSame) {
                                    t.sourceStartClass = 'text-success'
                                    t.targetStartClass = 'text-success'
                                }
                                if (clinicCloseSame) {
                                    t.sourceEndClass = 'text-success'
                                    t.targetEndClass = 'text-success'
                                }
                                t.targetStart = this.state.newClinicTiming[i].clinicOpenTime
                                t.targetEnd = this.state.newClinicTiming[i].clinicCloseTime
                                t.sourceStart = response.data[j].clinicOpenTime
                                t.sourceEnd = response.data[j].clinicCloseTime
                            }
                        }
                        if (!found) {
                            t.sourceStart = ''
                            t.sourceEnd = ''
                            t.targetStart = this.state.newClinicTiming[i].clinicOpenTime
                            t.targetEnd = this.state.newClinicTiming[i].clinicCloseTime
                        }
                        t.day = days[this.state.newClinicTiming[i].dayOfWeek]
                        tt.push(t)
                    }
                    this.setState({
                        timingOverview: tt,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                });
            axios.get('/clinicservicesforclinic/' + event.target.value, { headers: authHeader() })
                .then(response => {
                    let tt = []
                    for (let i = 0; i < this.state.newClinicService.length; i++) {
                        let found = false;
                        for (let j = 0; j < response.data.length; j++) {
                            if (response.data[j].serviceId === this.state.newClinicService[i].serviceId) {
                                found = true;
                                tt.push({
                                    source: this.state.newClinicService[j].description,
                                    sourceClass: 'text-success',
                                    target: response.data[i].description,
                                    targetClass: 'text-success'
                                })
                            }
                        }
                        if (!found) {
                            tt.push({
                                source: this.state.newClinicService[i].description,
                                sourceClass: 'text-warning',
                                target: '',
                                targetClass: 'text-warning'
                            })
                        }
                    }
                    this.setState({
                        serviceOverview: tt,
                    });
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                });
        } else {
            this.setState({
                oldClinicSchedulingTimeBlock: 0,
            });
        }
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    onOverwriteClose() {
        this.setState({
            showOverwriteModel: false
        })
    }
    onHLFOverviewClose() {
        this.setState({
            showHLFOverview: false
        })
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Copy Clinic Settings</h4>
                </div>
                <Form id="clinicadd" method="post">
                    <div className="section-title containerAdd">
                        <table className="table table-striped table-bordered table-sm">
                            <tbody>
                                <tr>
                                    <td style={{ width: '70%' }} colSpan="2">OncoSmart System ID</td>
                                    <td style={{ width: '30%' }}><input type="text"
                                        name="oncosmartSystemId"
                                        value={this.state.oncosmartSystemId}
                                        id="oncosmartSystemId" className="form-control-plaintext col-lg-4" readOnly />
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2">Name</td>
                                    <td><input type="text"
                                        name="name"
                                        defaultValue={this.state.name}
                                        id="name" className="form-control-plaintext" placeholder="Clinic name" readOnly /></td>
                                </tr>
                                <tr>
                                    <td><div className="text-muted"><span style={{ color: 'red' }}>#</span> - denotes data already exists.</div></td>
                                    <td>{this.validator.message('Component', this.state.copyHighLevelFilter, 'required|anyOneCheckboxRequired:' + (this.state.copyTiming || this.state.copyResource || this.state.copyService || this.state.copyHighLevelFilter || this.state.copyStaff || this.state.copyStaffAvailability || this.state.copyMaxTreatmentStart))}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyTiming">Copy Timings From</label><span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newClinicTimingCount > 0 ? '#' : ''}</span>
                                    </td>
                                    <td align="center">
                                        <div className="form-check">
                                            <input className="form-check-input mt-2" type="checkbox" id="copyTiming" name="copyTiming" checked={this.state.copyTiming} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} rowSpan="8">
                                        <select className="form-control" id="copyFrom" name="copyFrom" value={this.state.copyFrom} onChange={this.copyFromChanged}>
                                            <option value="0">--  Select Clinic  --</option>
                                            {this.state.clinicList.length > 0
                                                && this.state.clinicList.map((clinic, i) => {
                                                    return (
                                                        <option key={i} value={clinic.codesetItemId}>{clinic.name}</option>
                                                    )
                                                })}
                                        </select>
                                        {this.validator.message('CopyFrom', this.state.copyFrom, 'required|not_in:0')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyResource">Copy Resources From<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newClinicResourceCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                    <td align="center">
                                        <div className="form-check">
                                            <input className="form-check-input mt-2" type="checkbox" id="copyResource" name="copyResource" checked={this.state.copyResource} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyService">Copy Service Types From<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newClinicServiceTypeCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                    <td align="center" rowSpan="2">
                                        <div className="form-check">
                                            <input className="form-check-input mt-4" type="checkbox" id="copyService" name="copyService" checked={this.state.copyService} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyService">Copy Services From<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newClinicServiceCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyHighLevelFilter">Copy High Level Filter From<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newClinicHighLevelFilterCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                    <td align="center">
                                        <div className="form-check">
                                            <input className="form-check-input mt-2" type="checkbox" id="copyHighLevelFilter" name="copyHighLevelFilter" checked={this.state.copyHighLevelFilter} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyMaxTreatmentStart">Copy Max Treatment Start From<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newClinicMaxTreatmentStartCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                    <td align="center">
                                        <div className="form-check">
                                            <input className="form-check-input mt-2" type="checkbox" id="copyMaxTreatmentStart" name="copyMaxTreatmentStart" checked={this.state.copyMaxTreatmentStart} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyStaff">Copy Staff<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newStaffCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                    <td align="center">
                                        <div className="form-check">
                                            <input className="form-check-input mt-2" type="checkbox" id="copyStaff" name="copyStaff" checked={this.state.copyStaff} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <label className="form-label" htmlFor="copyStaffAvailability">Copy Staff Availability<span style={{ color: 'red', 'marginLeft': '5px' }}>{this.state.newStaffAvailabilityCount > 0 ? '#' : ''}</span></label>
                                    </td>
                                    <td align="center">
                                        <div className="form-check">
                                            <input className="form-check-input mt-2" type="checkbox" id="copyStaffAvailability" name="copyStaffAvailability" checked={this.state.copyStaffAvailability} onChange={this.handleChange} />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="3">
                                        <ReactTooltip />
                                        <button type="button" onClick={this.onPreSave} className="btn btn-primary mr-2" data-tip="Save">Save</button>
                                        <button type="button" onClick={this.onCancel} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Form>
                <Modal show={this.state.showOverwriteModel} onHide={this.onOverwriteClose.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                        <Modal.Title>Overwrite Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to overwrite data marked with <span style={{ color: 'red', 'marginLeft': '5px' }}>#</span>?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" data-tip="Yes" onClick={this.onPreSave2.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="primary" data-tip="No" onClick={this.onOverwriteClose.bind(this)}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showHLFOverview} onHide={this.onHLFOverviewClose.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                        <Modal.Title>High Level Filter - Input Overview</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-4"></div>
                                <div className="col-md-4"><h6>{this.state.oldClinicName}</h6></div>
                                <div className="col-md-4"><h6>{this.state.name}</h6></div>
                                <div className="col-md-12 mt-1"><h5>Clinic Timing:</h5></div>
                            </div>
                            {
                                this.state.timingOverview.map((e, i) => {
                                    return <div className="row">
                                        <div className="col-md-4"><h6>{e.day} - Start</h6></div>
                                        <div className="col-md-4"><span>{e.sourceStart}</span></div>
                                        <div className="col-md-4"><span className={e.targetStartClass}>{e.targetStart}</span></div>
                                        <div className="col-md-4"><h6>{e.day} - End</h6></div>
                                        <div className="col-md-4"><span>{e.sourceEnd}</span></div>
                                        <div className="col-md-4"><span className={e.targetEndClass}>{e.targetEnd}</span></div>
                                    </div>
                                })
                            }
                            <div className="row">
                                <div className="col-md-12 mt-1"><h5>Scheduling Time Blocks:</h5></div>
                                <div className="col-md-4"></div>
                                <div className="col-md-4"><span>{this.state.oldClinicSchedulingTimeBlock} Minutes</span></div>
                                <div className="col-md-4"><span className={this.state.newClinicSchedulingTimeBlock === this.state.oldClinicSchedulingTimeBlock ? 'text-success' : 'text-warning'}>{this.state.newClinicSchedulingTimeBlock} Minutes</span></div>

                                <div className="col-md-12 mt-1"><h5>Services:</h5></div>
                            </div>
                            {
                                this.state.serviceOverview.map((e, i) => {
                                    return <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-4"><span>{e.target}</span></div>
                                        <div className="col-md-4"><span className={e.sourceClass}>{e.source}</span></div>
                                    </div>
                                })
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="secondary" data-tip="Yes" onClick={this.onSave.bind(this)}>
                            {this.state.showSpinner ? <div className="d-inline"><Spinner animation="grow" size="sm" /></div> : null} Yes, Continue
                        </Button>
                        <Button variant="primary" data-tip="No" onClick={this.onHLFOverviewClose.bind(this)}>
                            No, Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ClinicCopySetting