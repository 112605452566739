import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
import { RequiredTitle } from '../../utils/Util'

class ResourcesAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oncosmartSystemId: '',
            name: '',
            resourceTypeId: '0',
            displayOrder: '1',
            activeStatus: 'Y',
            resourcetypesList: '',
            showError: false,
            errorMsg: '',
            resourceList: []
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/resourcetypeslist', { headers: authHeader() })
            .then(response1 => {
                axios.get('/resourceslist', { headers: authHeader() })
                    .then(response2 => {
                        if (response2.data.length > 0) {
                            this.setState({
                                displayOrder: Math.max.apply(Math, response2.data.map(function (o) { return o.displayOrder + 1; })),
                                resourceList: response2.data,
                                resourcetypesList: response1.data
                            });
                        } else {
                            this.setState({
                                displayOrder: 1,
                                resourcetypesList: response1.data
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }

    addResourcesAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        axios.get('/resourcesforoncosmart/' + this.state.oncosmartSystemId, { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        showError: true,
                        errorMsg: 'OncoSmart System ID already exists'
                    });
                }
                else {
                    axios.post('/resources', {
                        codesetId: 4, oncosmartSystemId: this.state.oncosmartSystemId, name: this.state.name, resourceTypeId: this.state.resourceTypeId,
                        displayOrder: this.state.displayOrder, activeStatus: this.state.activeStatus, deletedYN: 'N', createdBy: AuthService.getCurrentUserName(),
                        lastUpdatedBy: AuthService.getCurrentUserName()
                    }, { headers: authHeader() })
                        .then(response => {
                            UserProfile.setPage("Resources");
                            UserProfile.setHighlights([response.data], 'success')
                            history.push('/standardcode');
                        })
                        .catch(function (error) {
                            console.log(error);
                            history.push('/Errorpage')
                        })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    cancelResourcesAdd = (event) => {
        event.preventDefault();
        UserProfile.setPage("Resources");
        UserProfile.setHighlights([], 'success')
        history.push('/standardcode');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            [field]: value
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Resources</h4>
                </div>
                <RequiredTitle />
                <div style={{ display: (this.state.showError ? 'block' : 'none') }}>
                    <label style={{ color: 'red' }}>{this.state.errorMsg}</label>
                </div>
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto">OncoSmart System ID<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="oncosmartSystemId"
                                    value={this.state.oncosmartSystemId}
                                    onChange={this.handleChange}
                                    ref={this.setFocus}
                                    id="oncosmartSystemId" className="form-control col-lg-2" maxLength="6" />
                                    {this.validator.message('OncoSmart System ID', this.state.oncosmartSystemId, 'required|numeric|min:0,num|numeric|max:999999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Name<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    id="name" className="form-control" placeholder="Resource Name" />
                                    {this.validator.message('Name', this.state.name, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td>Resource Type<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.resourceTypeId} name="resourceTypeId" id="resourceTypeId" onChange={this.handleChange}>
                                        <option value="0">-- Select Resource Type --</option>
                                        {this.state.resourcetypesList.length > 0
                                            && this.state.resourcetypesList.map((resourcetype, i) => {
                                                if (!this.state.resourceList.find(o2 => o2.resourceTypeId === resourcetype.codesetItemId)) {
                                                    return (
                                                        <option key={i} value={resourcetype.codesetItemId}>{resourcetype.name}</option>
                                                    )
                                                }
                                            })}
                                    </select>
                                    {this.validator.message('resourceTypeId', this.state.resourceTypeId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Display Order<span className='text-danger ml-1'>*</span></td>
                                <td><input type="text"
                                    name="displayOrder"
                                    value={this.state.displayOrder}
                                    onChange={this.handleChange}
                                    id="displayOrder" className="col-lg-2 form-control" pattern="[0-9]*" maxLength="3" />
                                    {this.validator.message('Display Order', this.state.displayOrder, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" validations={['required']} name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" validations={['required']} name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addResourcesAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelResourcesAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ResourcesAdd
