import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
import { RequiredTitle } from '../../utils/Util'

class StaffEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            staffId: '',
            firstName: '',
            lastName: '',
            description: '',
            skillId: '0',
            displayOrder: '1',
            activeStatus: 'Y',
            skillsList: '',
            clinicId: '',
            createdBy: '',
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        var codesetitemid = this.props.match.params
        var id = codesetitemid.staffid.replace(":", "")

        axios.get('/staff/' + id, { headers: authHeader() })
            .then(response1 => {
                axios.get('/skillslist', { headers: authHeader() })
                    .then(response2 => {
                        this.setState({
                            staffId: response1.data.staffId, clinicId: response1.data.clinicId, firstName: response1.data.firstName,
                            lastName: response1.data.lastName, description: response1.data.description, skillId: response1.data.skillId,
                            displayOrder: response1.data.displayOrder, activeStatus: response1.data.activeStatus, createdBy: response1.data.createdBy,
                            skillsList: response2.data,
                            clinicId: UserProfile.getClinicId()
                        });
                    })
                    .catch(function (error) {
                        console.log('response2 : ', error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log('response1 : ', error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addStaffEdit = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        axios.put('/staff/' + this.state.staffId, {
            staffId: this.state.staffId,
            clinicId: this.state.clinicId, firstName: this.state.firstName, lastName: this.state.lastName, description: this.state.description, skillId: this.state.skillId,
            displayOrder: this.state.displayOrder, activeStatus: this.state.activeStatus, deletedYN: 'N', createdBy: this.state.createdBy,
            lastUpdatedBy: AuthService.getCurrentUserName(),
        }, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights([this.state.staffId], 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/1');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    cancelStaffEdit = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([this.state.staffId], 'info')
        history.push('/clinic/' + UserProfile.getClinicId() + '/1');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            [field]: value
        });
    }
    render() {
        let skillsListnew = this.state.skillsList.length > 0
            && this.state.skillsList.map((skills, i) => {
                return (
                    <option key={i} value={skills.codesetItemId}>{skills.name}</option>
                )
            }, this);

        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Edit Staff</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">First Name<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                    ref={this.setFocus}
                                    id="firstName" className="form-control" placeholder="First Name" />
                                    {this.validator.message('Name', this.state.firstName, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Last Name<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                    id="lastName" className="form-control" placeholder="Last Name" />
                                    {this.validator.message('Name', this.state.lastName, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Description<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    id="description" className="form-control" placeholder="Description" />
                                    {this.validator.message('Description', this.state.description, 'required|maxLength:200')}
                                </td>
                            </tr>
                            <tr>
                                <td>Skills<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.skillId} validations={['requireddrop']} name="skillId" id="skillId" onChange={this.handleChange}>
                                        <option value="0">-- Select Skills --</option>
                                        {skillsListnew}
                                    </select>
                                    {this.validator.message('skillId', this.state.skillId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Display Order<span className='text-danger ml-1'>*</span></td>
                                <td><input type="text"
                                    validations={['required', 'isNumberOnly']}
                                    name="displayOrder"
                                    value={this.state.displayOrder}
                                    onChange={this.handleChange}
                                    id="displayOrder" className="col-lg-2 form-control" pattern="[0-9]*" />
                                    {this.validator.message('Display Order', this.state.displayOrder, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <label className="form-check-label" ><input className="form-check-input" type="radio" name="activeStatus" id="activeStatus" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <label className="form-check-label"><input className="form-check-input" type="radio" name="activeStatus" id="activeStatus" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addStaffEdit} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelStaffEdit} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default StaffEdit
