import React, { Component } from 'react'
import UserProfile from './UserProfile'
import axios from 'axios'
import authHeader from './authentication/authHeader'

class SwitchClient extends Component {
    componentDidMount() {
        const p = this.props.match.params
        const i = p.id.replace(":", "")

        let organizationId = 0
        let clientId = ''
        axios.get('/organizations', { headers: authHeader() })
            .then(orgresp => {
                let orgs = []
                orgresp.data.forEach(e => {
                    orgs.push({ id: e.codesetItemId, name: e.name, displayOrder: e.displayOrder })
                })
                orgs.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                axios.get('/clients', { headers: authHeader() })
                    .then(resp => {
                        let clients = []
                        let clientShortName = ''
                        resp.data.forEach(e => {
                            clients.push({ id: e.codesetItemId, name: e.name, displayOrder: e.displayOrder, parentId: e.parentId })
                            if (e.codesetItemId === parseInt(i)) {
                                organizationId = e.parentId
                                clientId = e.codesetItemId
                                clientShortName = e.shortName
                            }
                        })
                        clients.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                        axios.get('/aclinicslist/' + clientId, { headers: authHeader() })
                            .then(response => {
                                axios.get('/clinics', { headers: authHeader() })
                                    .then(res => {
                                        let clinics = []
                                        response.data.forEach(element => {
                                            clinics.push({ id: element.codesetItemId, name: element.name, displayOrder: element.displayOrder })
                                        });
                                        clinics.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                                        const client = {
                                            id: i,
                                            shortName: clientShortName,
                                            organizationId: organizationId,
                                            organizations: orgs,
                                            clients: clients,
                                            clinics: clinics,
                                            allClinics: res.data,
                                        }
                                        UserProfile.setGlobalClientParams(client)
                                        this.props.history.push("/Home");
                                        window.location.reload();
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    })
                            })
                            .catch(function (error) {
                                console.log(error);
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    render() { return (<></>) }
}

export default SwitchClient
