import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Home from "./components/home/Home";

import Logout from './components/Logout'
import Login from './components/Login'
import Standardcode from './components/standardcode/Standardcode'

import Scheduler from './components/scheduler/Scheduler'
import ClinicAdd from './components/standardcode/ClinicAdd'
import ClinicEdit from './components/standardcode/ClinicEdit'
import ClinicCopySetting from './components/standardcode/ClinicCopySetting'

import Skills from './components/standardcode/skills/Skills'
import SkillsAdd from './components/standardcode/skills/SkillsAdd'
import SkillsEdit from './components/standardcode/skills/SkillsEdit'

import ResourceType from './components/standardcode/resourcetype/ResourceType'
import ResourceTypeAdd from './components/standardcode/resourcetype/ResourceTypeAdd'
import ResourceTypeEdit from './components/standardcode/resourcetype/ResourceTypeEdit'

import Resources from './components/standardcode/resources/Resources'
import ResourcesAdd from './components/standardcode/resources/ResourcesAdd'
import ResourcesEdit from './components/standardcode/resources/ResourcesEdit'

import ServicesType from './components/standardcode/servicestype/ServicesType'
import ServicesTypeAdd from './components/standardcode/servicestype/ServicesTypeAdd'
import ServicesTypeEdit from './components/standardcode/servicestype/ServicesTypeEdit'

import Services from './components/standardcode/services/Services'
import ServicesAdd from './components/standardcode/services/ServicesAdd'
import ServicesEdit from './components/standardcode/services/ServicesEdit'

import Staff from './components/clinics/staff/Staff'
import StaffAdd from './components/clinics/staff/StaffAdd'
import StaffEdit from './components/clinics/staff/StaffEdit'

import StaffAvailability from './components/clinics/staffavailability/StaffAvailability'
import StaffAvailabilityAdd from './components/clinics/staffavailability/StaffAvailabilityAdd'
import StaffAvailabilityEdit from './components/clinics/staffavailability/StaffAvailabilityEdit'

import ClinicResources from './components/clinics/clinicresources/ClinicResources'
import ClinicResourcesAdd from './components/clinics/clinicresources/ClinicResourcesAdd'
import ClinicResourcesEdit from './components/clinics/clinicresources/ClinicResourcesEdit'

import ClinicServiceType from './components/clinics/clinicservicetype/ClinicServiceType'
import ClinicServiceTypeAdd from './components/clinics/clinicservicetype/ClinicServiceTypeAdd'
import ClinicServiceTypeEdit from './components/clinics/clinicservicetype/ClinicServiceTypeEdit'

import ClinicServices from './components/clinics/clinicservices/ClinicServices'
import ClinicServicesAdd from './components/clinics/clinicservices/ClinicServicesAdd'
import ClinicServicesEdit from './components/clinics/clinicservices/ClinicServicesEdit'

import DonotbookPatients from './components/clinics/donotbookpatients/DonotbookPatients'
import DonotbookPatientsAdd from './components/clinics/donotbookpatients/DonotbookPatientsAdd'
import DonotbookPatientsEdit from './components/clinics/donotbookpatients/DonotbookPatientsEdit'

import DonotStart from './components/clinics/donotstart/DonotStart'
import DonotStartAdd from './components/clinics/donotstart/DonotStartAdd'
import DonotStartEdit from './components/clinics/donotstart/DonotStartEdit'

import ClinicWrapper from './components/clinics/clinicwrapper'

import Timings from './components/clinics/timings/Timings'
import TimingsAdd from './components/clinics/timings/TimingsAdd'
import TimingsEdit from './components/clinics/timings/TimingsEdit'

import HighLevelFilter from './components/clinics/highlevelfilter/HighLevelFilter'
import HighLevelFilterAdd from './components/clinics/highlevelfilter/HighLevelFilterAdd'
import HighLevelFilterStartk from './components/clinics/highlevelfilter/HighLevelFilterStartk'
import HighLevelFilterCD from './components/clinics/highlevelfilter/HighLevelFilterCD'

import MaxTreatStartAdd from './components/clinics/maxtreatstart/MaxTreatStartAdd'
import MaxTreatStartEdit from './components/clinics/maxtreatstart/MaxTreatStartEdit'

import ViewBreakdown from './components/scheduler/ViewBreakdown'
import SolutionList from './components/scheduler/SolutionList'
import ViewSolutionList from './components/scheduler/ViewSolutionList'

import ErrorPage from './components/ErrorPage'
import SessionTimeout from './components/SessionTimeout'

import OrganizationAdd from './components/standardcode/ogranization/OrganizationAdd'
import OrganizationEdit from './components/standardcode/ogranization/OrganizationEdit'
import ClientAdd from './components/standardcode/client/ClientAdd'
import ClientEdit from './components/standardcode/client/ClientEdit'
import SwitchClient from './components/SwitchClient'
export default class Routes extends Component {
    render() {
        return (

            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/home" component={Home} />
                <Route path="/scheduler" component={Scheduler} />
                <Route path="/viewbreakdown" component={ViewBreakdown} />
                <Route path="/solutionlist" component={SolutionList} />
                <Route path="/viewsolutionlist" component={ViewSolutionList} />
                {/*<Route path="/clinic" component={Clinic} />*/}
                <Route path="/standardcode" component={Standardcode} />
                <Route path="/Logout" component={Logout} />
                <Route path="/Clinicadd" component={ClinicAdd} />
                <Route path="/Clinicedit:codeitemid" component={ClinicEdit} />
                <Route path="/Cliniccopysetting:codeitemid" component={ClinicCopySetting} />
                <Route path="/Skills" component={Skills} />
                <Route path="/Skillsadd" component={SkillsAdd} />
                <Route path="/Skillsedit:codeitemid" component={SkillsEdit} />
                <Route path="/Resourcetype" component={ResourceType} />
                <Route path="/Resourcetypeadd" component={ResourceTypeAdd} />
                <Route path="/Resourcetypeedit:codeitemid" component={ResourceTypeEdit} />
                <Route path="/Resources" component={Resources} />
                <Route path="/Resourcesadd" component={ResourcesAdd} />
                <Route path="/Resourcesedit:codeitemid" component={ResourcesEdit} />
                <Route path="/Servicestype" component={ServicesType} />
                <Route path="/Servicestypeadd" component={ServicesTypeAdd} />
                <Route path="/Servicestypeedit:codeitemid" component={ServicesTypeEdit} />
                <Route path="/Services" component={Services} />
                <Route path="/Servicesadd" component={ServicesAdd} />
                <Route path="/Servicesedit:codeitemid" component={ServicesEdit} />
                <Route path="/Staff" component={Staff} />
                <Route path="/Staffadd" component={StaffAdd} />
                <Route path="/Staffedit:staffid" component={StaffEdit} />
                <Route path="/Staffavailability" component={StaffAvailability} />
                <Route path="/Staffavailabilityadd" component={StaffAvailabilityAdd} />
                <Route path="/Staffavailabilityedit:staffAvailableId" component={StaffAvailabilityEdit} />
                <Route path="/ClinicResources" component={ClinicResources} />
                <Route path="/ClinicResourcesadd" component={ClinicResourcesAdd} />
                <Route path="/ClinicResourcesedit:resourcesId" component={ClinicResourcesEdit} />
                <Route path="/ClinicServiceType" component={ClinicServiceType} />
                <Route path="/ClinicServiceTypeadd" component={ClinicServiceTypeAdd} />
                <Route path="/ClinicServiceTypedit:servicetypesId" component={ClinicServiceTypeEdit} />
                <Route path="/ClinicServices" component={ClinicServices} />
                <Route path="/ClinicServicesadd" component={ClinicServicesAdd} />
                <Route path="/ClinicServicesedit:servicesId" component={ClinicServicesEdit} />
                <Route path="/DonotbookPatients" component={DonotbookPatients} />
                <Route path="/DonotbookPatientsadd" component={DonotbookPatientsAdd} />
                <Route path="/DonotbookPatientsedit:donotbookId" component={DonotbookPatientsEdit} />
                <Route path="/DonotStart" component={DonotStart} />
                <Route path="/DonotStartAdd" component={DonotStartAdd} />
                <Route path="/DonotStartEdit/:id" component={DonotStartEdit} />
                <Route path="/Timings" component={Timings} />
                <Route path="/Timingsadd" component={TimingsAdd} />
                <Route path="/Timingsedit:timingsId" component={TimingsEdit} />
                <Route path="/HighLevelFilter" component={HighLevelFilter} />
                <Route path="/HighLevelFilteradd" component={HighLevelFilterAdd} />
                <Route path="/HighLevelFilterstartk" component={HighLevelFilterStartk} />
                <Route path="/HighLevelFiltercd" component={HighLevelFilterCD} />
                <Route path="/Errorpage" component={ErrorPage} />
                <Route path="/SessionTimeout" component={SessionTimeout} />
                <Route path="/clinic/:clinicid/:type" component={ClinicWrapper} />
                <Route path="/clinic/:clinicid" component={ClinicWrapper} />
                <Route path="/MaxTreatStartEdit/:clinicid" component={MaxTreatStartEdit} />
                <Route path="/MaxTreatStartAdd/:clinicid" component={MaxTreatStartAdd} />
                <Route path="/organizationadd" component={OrganizationAdd} />
                <Route path="/organizationedit/:id" component={OrganizationEdit} />
                <Route path="/clientAdd" component={ClientAdd} />
                <Route path="/clientedit/:id" component={ClientEdit} />
                <Route path="/switchclient/:id" component={SwitchClient} />
            </Switch>

        )
    }
}
