import React, { Component } from 'react'
import history from '../../../history';
import Select from "react-select";
import Table from 'react-bootstrap/Table'
import axios from 'axios'
import moment from 'moment'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, {messages, errorMessageClassName} from '../../utils/ocsvalidators'

class MaxTreatStartEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codesetItemId: 0,
            maxTreatStarts: [],
            selectedTimeOfDay: [],
            workingDays: [],
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleTimeOfDayMultiChange = this.handleTimeOfDayMultiChange.bind(this);
    }
    componentDidMount() {
        UserProfile.setClinicId(this.props.match.params.clinicid)
        axios.get('/maxtreatstartforclinic/' + UserProfile.getClinicId(),{ headers: authHeader() })
            .then(response => {
                let list = []
                let st = ''
                let d = {}
                for (let index=0; index<response.data.length; index++) {
                    const e = response.data[index];
                    if (st != e.startTime){
                        if (d.hasOwnProperty('startTime')) {
                            list.push(d)
                            d = {}
                        }
                        st = e.startTime
                        d['startTime'] = e.startTime
                    }
                    switch (e.dayOfWeek){
                        case 1:
                            d['sunId'] = e.id
                            d['sunCount'] = e.treatmentsStartDesired
                            d['sunPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 2:
                            d['monId'] = e.id
                            d['monCount'] = e.treatmentsStartDesired
                            d['monPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 3:
                            d['tueId'] = e.id
                            d['tueCount'] = e.treatmentsStartDesired
                            d['tuePenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 4:
                            d['wedId'] = e.id
                            d['wedCount'] = e.treatmentsStartDesired
                            d['wedPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 5:
                            d['thuId'] = e.id
                            d['thuCount'] = e.treatmentsStartDesired
                            d['thuPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 6:
                            d['friId'] = e.id
                            d['friCount'] = e.treatmentsStartDesired
                            d['friPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        case 7:
                            d['satId'] = e.id
                            d['satCount'] = e.treatmentsStartDesired
                            d['satPenalty'] = e.treatmentsStartExceededPenalty
                            break;
                        default:
                            console.log('ERROR:::')
                    }
                }
                if (d.hasOwnProperty('startTime')) list.push(d)
                let days = []
                axios.get('/clinicstiming/' + UserProfile.getClinicId(),{ headers: authHeader() })
                .then(response1 => {
                    for (let index = 0; index < response1.data.length; index++)
                        days.push(response1.data[index][1])
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
                this.setState({
                    codesetItemId: UserProfile.getClinicId(),
                    maxTreatStarts: list,
                    workingDays: days,
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target
        const y = name.split('_')
        const arrayvalue = [ ...this.state.maxTreatStarts ]
        arrayvalue[y[1]][y[0]] = value;
        this.setState({
            maxTreatStarts: arrayvalue
        })
    }
    handleTimeOfDayMultiChange(option) {
        this.setState(state => {
            return {
                selectedTimeOfDay: option
            };
        });
    }
    Apply() {
        const arrayvalue = [ ...this.state.maxTreatStarts ]
        let selectedDay = document.getElementById('selectDay').options[document.getElementById('selectDay').selectedIndex].value
        for (let i=0; i<this.state.selectedTimeOfDay.length; i++){
            if (this.state.selectedTimeOfDay[i].id === -1) {
                switch (selectedDay ){
                    case 'all':
                        for (let a=0; a<arrayvalue.length; a++){
                            if (this.state.workingDays.includes(1)){
                                arrayvalue[a].sunCount=document.getElementById('fillCount').value
                                arrayvalue[a].sunPenalty=document.getElementById('fillPenalty').value
                            }
                            if (this.state.workingDays.includes(2)){
                                arrayvalue[a].monCount=document.getElementById('fillCount').value
                                arrayvalue[a].monPenalty=document.getElementById('fillPenalty').value
                            }
                            if (this.state.workingDays.includes(3)){
                                arrayvalue[a].tueCount=document.getElementById('fillCount').value
                                arrayvalue[a].tuePenalty=document.getElementById('fillPenalty').value
                            }
                            if (this.state.workingDays.includes(4)){
                                arrayvalue[a].wedCount=document.getElementById('fillCount').value
                                arrayvalue[a].wedPenalty=document.getElementById('fillPenalty').value
                            }
                            if (this.state.workingDays.includes(5)){
                                arrayvalue[a].thuCount=document.getElementById('fillCount').value
                                arrayvalue[a].thuPenalty=document.getElementById('fillPenalty').value
                            }
                            if (this.state.workingDays.includes(6)){
                                arrayvalue[a].friCount=document.getElementById('fillCount').value
                                arrayvalue[a].friPenalty=document.getElementById('fillPenalty').value
                            }
                            if (this.state.workingDays.includes(7)){
                                arrayvalue[a].satCount=document.getElementById('fillCount').value
                                arrayvalue[a].satPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "1":
                        if (this.state.workingDays.includes(1)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].sunCount=document.getElementById('fillCount').value
                                arrayvalue[a].sunPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "2":
                        if (this.state.workingDays.includes(2)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].monCount=document.getElementById('fillCount').value
                                arrayvalue[a].monPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "3":
                        if (this.state.workingDays.includes(3)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].tueCount=document.getElementById('fillCount').value
                                arrayvalue[a].tuePenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "4":
                        if (this.state.workingDays.includes(4)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].wedCount=document.getElementById('fillCount').value
                                arrayvalue[a].wedPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "5":
                        if (this.state.workingDays.includes(5)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].thuCount=document.getElementById('fillCount').value
                                arrayvalue[a].thuPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "6":
                        if (this.state.workingDays.includes(6)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].friCount=document.getElementById('fillCount').value
                                arrayvalue[a].friPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                    case "7":
                        if (this.state.workingDays.includes(7)){
                            for (let a=0; a<arrayvalue.length; a++){
                                arrayvalue[a].satCount=document.getElementById('fillCount').value
                                arrayvalue[a].satPenalty=document.getElementById('fillPenalty').value
                            }
                        }
                        break;
                }
            } else {
                switch (selectedDay ){
                    case 'all':
                        for (let a=0; a<arrayvalue.length; a++){
                            if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                if (this.state.workingDays.includes(1)){
                                    arrayvalue[a].sunCount=document.getElementById('fillCount').value
                                    arrayvalue[a].sunPenalty=document.getElementById('fillPenalty').value
                                }
                                if (this.state.workingDays.includes(2)){
                                    arrayvalue[a].monCount=document.getElementById('fillCount').value
                                    arrayvalue[a].monPenalty=document.getElementById('fillPenalty').value
                                }
                                if (this.state.workingDays.includes(3)){
                                    arrayvalue[a].tueCount=document.getElementById('fillCount').value
                                    arrayvalue[a].tuePenalty=document.getElementById('fillPenalty').value
                                }
                                if (this.state.workingDays.includes(4)){
                                    arrayvalue[a].wedCount=document.getElementById('fillCount').value
                                    arrayvalue[a].wedPenalty=document.getElementById('fillPenalty').value
                                }
                                if (this.state.workingDays.includes(5)){
                                    arrayvalue[a].thuCount=document.getElementById('fillCount').value
                                    arrayvalue[a].thuPenalty=document.getElementById('fillPenalty').value
                                }
                                if (this.state.workingDays.includes(6)){
                                    arrayvalue[a].friCount=document.getElementById('fillCount').value
                                    arrayvalue[a].friPenalty=document.getElementById('fillPenalty').value
                                }
                                if (this.state.workingDays.includes(7)){
                                    arrayvalue[a].satCount=document.getElementById('fillCount').value
                                    arrayvalue[a].satPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '1':
                        if (this.state.workingDays.includes(1)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].sunCount=document.getElementById('fillCount').value
                                    arrayvalue[a].sunPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '2':
                        if (this.state.workingDays.includes(2)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].monCount=document.getElementById('fillCount').value
                                    arrayvalue[a].monPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '3':
                        if (this.state.workingDays.includes(3)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].tueCount=document.getElementById('fillCount').value
                                    arrayvalue[a].tuePenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '4':
                        if (this.state.workingDays.includes(4)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].wedCount=document.getElementById('fillCount').value
                                    arrayvalue[a].wedPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '5':
                        if (this.state.workingDays.includes(5)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].thuCount=document.getElementById('fillCount').value
                                    arrayvalue[a].thuPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '6':
                        if (this.state.workingDays.includes(6)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].friCount=document.getElementById('fillCount').value
                                    arrayvalue[a].friPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                    case '7':
                        if (this.state.workingDays.includes(7)){
                            for (let a=0; a<arrayvalue.length; a++){
                                if (this.state.selectedTimeOfDay[i].label === arrayvalue[a].startTime){
                                    arrayvalue[a].satCount=document.getElementById('fillCount').value
                                    arrayvalue[a].satPenalty=document.getElementById('fillPenalty').value
                                }
                            }
                        }
                        break;
                }
            }
        }
        this.setState({
            maxTreatStarts: arrayvalue
        })
    }
    handleSave(event){
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        let list = [];
        let today = new Date();
        for (let index=0; index<this.state.maxTreatStarts.length; index++) {
            //perform required validation here...
            if (this.state.maxTreatStarts[index].hasOwnProperty('sunId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].sunId,
                    dayOfWeek: 1,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].sunCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].sunPenalty
                })
            }
            if (this.state.maxTreatStarts[index].hasOwnProperty('monId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].monId,
                    dayOfWeek: 2,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].monCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].monPenalty
                })
            }
            if (this.state.maxTreatStarts[index].hasOwnProperty('tueId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].tueId,
                    dayOfWeek: 3,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].tueCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].tuePenalty
                })
            }
            if (this.state.maxTreatStarts[index].hasOwnProperty('wedId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].wedId,
                    dayOfWeek: 4,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].wedCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].wedPenalty
                })
            }
            if (this.state.maxTreatStarts[index].hasOwnProperty('thuId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].thuId,
                    dayOfWeek: 5,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].thuCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].thuPenalty
                })
            }
            if (this.state.maxTreatStarts[index].hasOwnProperty('friId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].friId,
                    dayOfWeek: 6,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].friCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].friPenalty
                })
            }
            if (this.state.maxTreatStarts[index].hasOwnProperty('satId')){
                list.push({
                    clinicId: this.state.codesetItemId,
                    startTime: this.state.maxTreatStarts[index].startTime,
                    createdBy: AuthService.getCurrentUserName(),
                    createdDate: moment(today).format('YYYY-MM-DD'),
                    lastUpdatedBy: AuthService.getCurrentUserName(), 
                    lastUpdatedDate: moment(today).format('YYYY-MM-DD'),
                    id: this.state.maxTreatStarts[index].satId,
                    dayOfWeek: 7,
                    treatmentsStartDesired: this.state.maxTreatStarts[index].satCount,
                    treatmentsStartExceededPenalty: this.state.maxTreatStarts[index].satPenalty
                })
            }
        }
        axios.put('/maxtreatstart', list,{ headers: authHeader() })
        .then(response => {
            history.push('/clinic/'+this.state.codesetItemId+'/10')
        })
        .catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        })
    }
    handleCancel(){
        history.push('/clinic/'+this.state.codesetItemId+'/10')
    }
    render() {
        let timeForApplyoptions = this.state.maxTreatStarts.length > 0 && this.state.maxTreatStarts.map((option, i) => {
            i++;
            return ({
                value:i,
                label:option.startTime,
                id:i,
                isdisabled: (this.state.selectedTimeOfDay != null) && this.state.selectedTimeOfDay.some((opt, i) => { 
                    return opt.id===-1
                }),
            })
        });
        if (timeForApplyoptions == false) timeForApplyoptions = [];
        timeForApplyoptions.unshift({value:'All', label:'All', id:-1, isdisabled: (this.state.selectedTimeOfDay != null) && this.state.selectedTimeOfDay.length > 0})

        return (
            <div className="container-fluid">
                <ReactTooltip />
                <div className="section-title">
                    <h4>Max Treatment Start <small>- Edit - {UserProfile.getClinicName()}</small></h4>
                </div>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-primary mb-2 mr-2" align="right" data-tip="Save Max Treatment Start" data-toggle="tooltip" data-placement="top" onClick={this.handleSave.bind(this)}>Save</button>
                    <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Cancel" data-toggle="tooltip" data-placement="top" onClick={this.handleCancel.bind(this)}>Cancel</button>
                </div>
                <div className="d-flex justify-content-end form-row form-inline mb-3">
                    <input type="number" className="form-control col-md-1 form-group text-right mr-2" placeholder="Count" id="fillCount" autoFocus/>
                    <input type="number" className="form-control col-md-1 form-group text-right mr-2" placeholder="Penalty" id="fillPenalty"/>
                    <Select
                        name="selectTime"
                        placeholder="--  Select Time (Row) --"
                        closeMenuOnSelect={false}
                        value={this.state.selectedTimeOfDay}
                        options={timeForApplyoptions}
                        onChange={this.handleTimeOfDayMultiChange}
                        isMulti
                        className="basic-multi-select col mr-2"
                        classNamePrefix="select"
                        id="selectTime"
                        isOptionDisabled={(option) => option.isdisabled}
                    />
                    <select className="form-control mr-2" id="selectDay">
                        <option selected>-- Select Day (Column) ---</option>
                        <option value='all'>All</option>
                        <option value='2'>Monday</option>
                        <option value='3'>Tuesday</option>
                        <option value='4'>Wednesday</option>
                        <option value='5'>Thursday</option>
                        <option value='6'>Friday</option>
                        <option value='7'>Saturday</option>
                        <option value='1'>Sunday</option>
                    </select>
                    <button type="button" className="btn btn-secondary" onClick={this.Apply.bind(this)} align="right" data-tip="Apply">Apply</button>
                </div>
                <Table className="table table-striped table-bordered table-hover table-sm">
                    <thead className="thead-dark">
                        <tr>
                            <th rowSpan="2" className="text-right">#</th>
                            <th rowSpan="2" className="text-center" style={{width: '120px'}}>Time of Day</th>
                            <th colSpan="2" className="text-center">Monday</th>
                            <th colSpan="2" className="text-center">Tuesday</th>
                            <th colSpan="2" className="text-center">Wednesday</th>
                            <th colSpan="2" className="text-center">Thursday</th>
                            <th colSpan="2" className="text-center">Friday&nbsp;</th>
                            <th colSpan="2" className="text-center">Saturday</th>
                            <th colSpan="2" className="text-center">Sunday</th>
                        </tr>
                        <tr>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                            <th className="text-right">Count</th>
                            <th className="text-right">Score</th>
                        </tr>
                    </thead>
                    <tbody className="table-hover">
                        {
                            this.state.maxTreatStarts.length == 0 ? <tr><td colSpan="16" style={{ textAlign: "center" }}><b>No Record(s)</b></td></tr> 
                            : 
                            this.state.maxTreatStarts.length > 0 && this.state.maxTreatStarts.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td className="text-right">{key+1}</td>
                                        <td className="text-center">{item.startTime}</td>
                                        <td>
                                            {item.hasOwnProperty('monCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'monCount_' + key} id={'monCount' + key} value={item.monCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].monCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('monPenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'monPenalty_' + key} id={'monPenalty' + key} value={item.monPenalty} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].monPenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('tueCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'tueCount_' + key} id={'tueCount' + key} value={item.tueCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].tueCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('tuePenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'tuePenalty_' + key} id={'tuePenalty' + key} value={item.tuePenalty} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].tuePenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('wedCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'wedCount_' + key} id={'wedCount' + key} value={item.wedCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].wedCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('wedPenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'wedPenalty_' + key} id={'wedPenalty' + key} value={item.wedPenalty} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].wedPenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('thuCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'thuCount_' + key} id={'thuCount' + key} value={item.thuCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].thuCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('thuPenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'thuPenalty_' + key} id={'thuPenalty' + key} value={item.thuPenalty} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].thuPenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('friCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'friCount_' + key} id={'friCount' + key} value={item.friCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].friCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('friPenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'friPenalty_' + key} id={'friPenalty' + key} value={item.friPenalty} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].friPenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('satCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'satCount_' + key} id={'satCount' + key} value={item.satCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].satCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('satPenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'satPenalty_' + key} id={'satPenalty' + key} value={item.satCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].satPenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('sunCount') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'sunCount_' + key} id={'sunCount' + key} value={item.sunCount} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].sunCount, 'required|numeric|min:0,num|numeric|max:999,num')}</div>
                                            : ''}</td>
                                        <td>
                                            {item.hasOwnProperty('sunPenalty') ? 
                                                <div><input type="number" onChange={this.handleChange} name={'sunPenalty_' + key} id={'sunPenalty' + key} value={item.sunPenalty} className="form-control text-right" min="0"/>
                                                {this.validator.message('', this.state.maxTreatStarts[key].sunPenalty, 'required|numeric|min:0,num|numeric|max:999999,num')}</div>
                                            : ''}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                    <button type="button" className="btn btn-primary mb-2 mr-2" align="right" data-tip="Save Max Treatment Start" data-toggle="tooltip" data-placement="top" onClick={this.handleSave.bind(this)}>Save</button>
                    <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Cancel" data-toggle="tooltip" data-placement="top" onClick={this.handleCancel.bind(this)}>Cancel</button>
                </div>
            </div>
        )
    }
}

export default MaxTreatStartEdit