import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import Select from "react-select";
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import { RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class ClinicServiceTypeAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resourceList: [],
            resourceId: '',
            serviceTypeList: [],
            servicetypeId: '',
            skillList: [],
            skillsId: '',
            clinicId: '',
            sequence: '',
            multiValue: [],
            activeStatus: 'Y',
            maxservicetypesId: '',
            clinicServiceTypeList: []
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
        this.handleMultiChange = this.handleMultiChange.bind(this);
    }
    componentDidMount() {
        axios.get('/clinicserviceTypes', { headers: authHeader() })
            .then(response1 => {
                axios.get('/activeservicetypeslist', { headers: authHeader() })
                    .then(response2 => {
                        axios.get('/activeskillslist', { headers: authHeader() })
                            .then(response3 => {
                                axios.get('/clinicserviceTypesforClinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                                    .then(response4 => {
                                        if (response4.data.length > 0) {
                                            this.setState({
                                                clinicServiceTypeList: response4.data,
                                                maxservicetypesId: Math.max.apply(Math, response1.data.map(function (o) { return o.servicetypesId + 1; })),
                                                serviceTypeList: response2.data,
                                                skillList: response3.data,
                                                clinicId: UserProfile.getClinicId()
                                            });
                                        } else {
                                            this.setState({
                                                clinicServiceTypeList: response4.data,
                                                maxservicetypesId: 1,
                                                serviceTypeList: response2.data,
                                                skillList: response3.data,
                                                clinicId: UserProfile.getClinicId()
                                            });
                                        }
                                    })
                                    .catch(function (error) {
                                        console.log('response2 : ', error);
                                        history.push('/Errorpage')
                                    })
                            }
                            )
                            .catch(function (error) {
                                console.log('response3 : ', error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log('response2 : ', error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log('response1 : ', error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addClinicServiceTypeAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        
        let updateClinicserviceTypesList = []
        var index1 = this.state.maxservicetypesId
        for (let index = 0; index < this.state.multiValue.length; index++) {
            const element = this.state.multiValue[index];
            let d = {
                servicetypesId: index1,
                clinicId: this.state.clinicId, servicetypeId: this.state.servicetypeId, skillsId: Object.values(element)[0], sequence: this.state.sequence,
                activeStatus: this.state.activeStatus, deletedYN: 'N', createdBy: AuthService.getCurrentUserName(),
                lastUpdatedBy: AuthService.getCurrentUserName(),
            }

            updateClinicserviceTypesList.push(d)
            index1 = index1 + 1
        }

        axios.post('/updateclinicserviceTypes', updateClinicserviceTypesList, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights([response.data.map((e) => e.servicetypesId).join()], 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/5');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    cancelClinicServiceTypeAdd = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/5');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            [field]: value
        });
    }
    handleChangeForResource = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        const d = this.state.resourceList.filter(resource => resource.codesetItemId == value).map((x, i) => { return x.resourceTypeId })
        this.setState({
            [field]: value,
            resourceTypeId: d[0]
        });
    }
    handleMultiChange(option) {
        this.setState(state => {
            return {
                multiValue: option
            };
        });
    }
    handleChangeForSkill = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        let value1 = Array.from(event.target.selectedOptions, option => option.value);
        this.setState({ values: value1 });
    }
    render() {
        let serviceTypeListnew = this.state.serviceTypeList.length > 0
            && this.state.serviceTypeList.map((serviceType, i) => {
                if (!this.state.clinicServiceTypeList.find(o2 => o2[2] === serviceType.codesetItemId)) {
                    return (
                        <option key={i} value={serviceType.codesetItemId}>{serviceType.name}</option>
                    )
                }
            }, this);
        let skillListnew = this.state.skillList.length > 0
            && this.state.skillList.map((skill, i) => {
                return ({
                    value: skill.codesetItemId,
                    label: skill.name
                }
                )
            }, this);
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Service Type</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Service Type<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.servicetypeId} name="servicetypeId" id="servicetypeId" onChange={this.handleChangeForResource}
                                        ref={this.setFocus}>
                                        <option value="0">-- Select Service Type  --</option>
                                        {serviceTypeListnew}
                                    </select>
                                    {this.validator.message('servicetypeId', this.state.servicetypeId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Skills<span className='text-danger ml-1'>*</span></td>
                                <td>
                                    <small className="form-text">If multiple skills are selected, those will be used in the selected order during GFF</small>
                                    <Select
                                        name="skills"
                                        placeholder="skills"
                                        value={this.state.multiValue}
                                        options={skillListnew}
                                        onChange={this.handleMultiChange}
                                        isMulti
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        id="skills"
                                    />
                                    {this.validator.message('multiValue', this.state.multiValue, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Sequence<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="sequence"
                                    value={this.state.sequence}
                                    onChange={this.handleChange}
                                    id="sequence" className="col-lg-2 form-control" placeholder="1" maxLength="3" />
                                    {this.validator.message('Display Order', this.state.sequence, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addClinicServiceTypeAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClinicServiceTypeAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ClinicServiceTypeAdd
