import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import history from '../../../history';
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import multisort from 'multisort';
import { findPageIndex } from '../../utils/Util'
import ocsHighlighRow from '../../utils/OcsHighlighRow'
import UserProfile from '../../UserProfile'
import { Store } from 'react-notifications-component'
class ResourceType extends Component {
    constructor(prop) {
        super(prop)
        this.state = {
            resourcetypesList: [],
            showAdd: false,
            showList: true,
            showModal: false,
            resourcetypeid: '',
            resourcetypename: '',
            clinicdeleteenable: [],
            sortConfig: {
                'oncosmartSystemId': { dataField: 'oncosmartSystemId', order: 'asc' },
            },
        }
        this.AddOpen = this.AddOpen.bind(this);
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.getResourcetypes = this.getResourcetypes.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }

    componentDidMount() {
        this.getResourcetypes()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.resourcetypesList, 'codesetItemId', UserProfile.getHightlightIds())
        this.setState({ resourcetypesList: this.state.resourcetypesList });
        this.node.paginationContext.currPage = p;
    }
    async getResourcetypes() {
        await axios.get('/resourcetypeslist', { headers: authHeader() })
            .then(response1 => {
                axios.get('/checkdeleteenable/3', { headers: authHeader() })
                    .then(response2 => {
                        let dataset = [...response1.data]
                        dataset.sort((a, b) => { return a.oncosmartSystemId < b.oncosmartSystemId ? -1 : a.oncosmartSystemId > b.oncosmartSystemId ? 1 : 0 })
                        this.setState({
                            clinicdeleteenable: response2.data,
                            resourcetypesList: dataset
                        });
                        this.gotoPage.click();
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    AddOpen() {
        this.setState({
            show: true,
            showList: false
        })
        history.push('/Resourcetypeadd')
    }
    EditOpen(id) {
        history.push('/Resourcetypeedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/resourcetypesdel/' + this.state.resourcetypeid, '', { headers: authHeader() })
            .then(response => {
                this.handleClose()
                this.getResourcetypes()
                Store.addNotification({
                    title: "Deleted",
                    message: `Resource Type '${this.state.resourcetypename}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name) {
        this.setState({
            resourcetypeid: id,
            resourcetypename: name,
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'codesetItemId',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '5%', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'oncosmartSystemId',
                text: 'OncoSmart System ID',
                sort: true,
                headerStyle: { width: '11%', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'name',
                text: 'Name',
                sort: true,
                headerStyle: { width: '25%' }
            },
            {
                dataField: 'description',
                text: 'Description',
                sort: true,
                headerStyle: { width: '25%' }
            },

            {
                dataField: 'displayOrder',
                text: 'Display Order',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'activeStatus',
                text: 'Active',
                sort: true,
                headerStyle: { width: '10%', textAlign: 'center' },
                formatter: (cell, row) => (
                    row.activeStatus === 'Y' ? 'Yes' : 'No'
                ),
                align: 'center'
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '14%', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.codesetItemId)} data-tip="Edit">Edit</button>&nbsp;
                        {this.state.clinicdeleteenable.find(x => x === row.codesetItemId) ?
                            <button type="button" className="btn btn-secondary" disabled onClick={this.handleShow.bind(this, row.name, row.codesetItemId)} data-tip="Delete">Delete</button>
                            :
                            <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.codesetItemId, row.name)} data-tip="Delete">Delete</button>
                        }
                    </div>
                ),
                align: 'center'
            }

        ];
        return (
            <div>
                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <div className="d-flex justify-content-end">
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen}>Add Resource Type</button>
                        <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                    </div>
                </div>
                <div>
                    <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Delete Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete the resource type <b>{this.state.resourcetypename}</b>?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <div>
                    {<BootstrapTable
                        remote={{
                            filter: false,
                            pagination: false,
                            sort: true,
                            cellEdit: false
                        }}
                        keyField='codesetItemId'
                        data={this.state.resourcetypesList}
                        columns={columns}
                        ref={(n) => (this.node = n)}
                        pagination={paginationFactory()}
                        noDataIndication="No Record(s)"
                        striped hover condensed
                        rowClasses='table table-sm'
                        headerClasses="thread"
                        selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                        onTableChange={this.handleTableChange}
                        sort={{ sortCaret: this.headerSortingClasses }}
                    />}
                </div>

            </div>
        )
    }
}
export default ResourceType;