import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import { RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class ClinicResourcesAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resourceList: [],
            resourceId: '',
            resourceTypeList: [],
            resourceTypeName: '',
            resourceTypeId: '',
            clinicId: '',
            quantity: '',
            errors: [],
            clinicresourceList: []
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/resources', { headers: authHeader() })
            .then(response1 => {
                axios.get('/resourcetypes', { headers: authHeader() })
                    .then(response2 => {
                        axios.get('/clinicresourcesforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                            .then(response3 => {
                                this.setState({
                                    clinicresourceList: response3.data,
                                    resourceList: response1.data,
                                    resourceTypeList: response2.data,
                                    clinicId: UserProfile.getClinicId()
                                });
                            })
                            .catch(function (error) {
                                console.log('response2 : ', error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log('response2 : ', error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log('response1 : ', error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addClinicResourcesAdd = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        axios.post('/clinicresources', {
            clinicId: this.state.clinicId, resourceId: this.state.resourceId, resourceTypeId: this.state.resourceTypeId, quantity: this.state.quantity,
            deletedYN: 'N', createdBy: this.state.createdBy,
            lastUpdatedBy: AuthService.getCurrentUserName()
        }, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights([response.data.resourcesId], 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/4');
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    cancelClinicResourcesAdd = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([], 'success')
        history.push('/clinic/' + UserProfile.getClinicId() + '/4');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            [field]: value
        });
    }
    handleChangeForResource = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        const d = this.state.resourceList.filter(resource => resource.codesetItemId == value).map((x, i) => { return x.resourceTypeId })
        this.setState({
            [field]: value,
            resourceTypeId: d[0]
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Resource</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b></td>
                            </tr>
                            <tr>
                                <td>Resource<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.resourceId} name="resourceId" id="resourceId" onChange={this.handleChangeForResource}
                                        ref={this.setFocus}>
                                        <option value="0">-- Select Resource --</option>
                                        {this.state.resourceList.length > 0
                                            && this.state.resourceList.map((resources, i) => {
                                                if (!this.state.clinicresourceList.find(o2 => o2.resourceTypeId === resources.resourceTypeId)) {
                                                    return (
                                                        <option key={i} value={resources.codesetItemId}>{resources.name}</option>
                                                    )
                                                }
                                            })}
                                    </select>
                                    {this.validator.message('resourceId', this.state.resourceId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Resource Type</td>
                                <td className="col-auto"><b>
                                    {this.state.resourceTypeList.filter(resourceType => resourceType.codesetItemId == this.state.resourceTypeId).map((x, i) => { return x.name })}
                                </b>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Quantity<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="quantity"
                                    value={this.state.quantity}
                                    onChange={this.handleChange}
                                    id="quantity" className="col-lg-2 form-control" placeholder="1" />
                                    {this.validator.message('Quantity', this.state.quantity, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addClinicResourcesAdd} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClinicResourcesAdd} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ClinicResourcesAdd
