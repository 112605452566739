import React, { Component } from 'react'
import history from '../../../history';
import axios from 'axios'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import { RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class SkillsAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oncosmartSystemId: '',
            name: '',
            description: '',
            displayOrder: '1',
            activeStatus: 'Y',
            showError: false,
            errorMsg: ''
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/skills', { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({ displayOrder: Math.max.apply(Math, response.data.map(function (o) { return o.displayOrder + 1; })) });
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }

    addClinic = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        axios.get('/skillsforoncosmart/' + this.state.oncosmartSystemId, { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        showError: true,
                        errorMsg: 'OncoSmart System ID already exists'
                    });
                } else {
                    axios.post('/skills', {
                        codesetId: 2, oncosmartSystemId: this.state.oncosmartSystemId, name: this.state.name, description: this.state.description,
                        displayOrder: this.state.displayOrder, activeStatus: this.state.activeStatus, deletedYN: 'N', createdBy: AuthService.getCurrentUserName(),
                        lastUpdatedBy: AuthService.getCurrentUserName()
                    }, { headers: authHeader() })
                        .then(response => {
                            UserProfile.setPage("Skills");
                            UserProfile.setHighlights([response.data], 'success')
                            history.push('/standardcode');
                        })
                        .catch(function (error) {
                            console.log(error);
                            history.push('/Errorpage')
                        })
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    cancelClinic = (event) => {
        event.preventDefault();
        UserProfile.setPage("Skills");
        UserProfile.setHighlights([], 'success')
        history.push('/standardcode');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            errorMsg: '',
            showError: false,
            [field]: value
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Add Skills</h4>
                </div>
                <RequiredTitle />
                <div style={{ display: (this.state.showError ? 'block' : 'none') }}>
                    <label style={{ color: 'red' }}>{this.state.errorMsg}</label>
                </div>
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered table-sm">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto">OncoSmart System ID<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="oncosmartSystemId"
                                    value={this.state.oncosmartSystemId}
                                    onChange={this.handleChange}
                                    ref={this.setFocus}
                                    id="oncosmartSystemId" className="form-control col-lg-2" maxLength="6" />
                                    {this.validator.message('OncoSmart System ID', this.state.oncosmartSystemId, 'required|numeric|min:0,num|numeric|max:999999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Name<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="name"
                                    value={this.state.name}
                                    onChange={this.handleChange}
                                    id="name" className="form-control" placeholder="Skill" />
                                    {this.validator.message('Name', this.state.name, 'required')}
                                </td>
                            </tr>
                            <tr>
                                <td>Description<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><textarea className="form-control"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    id="description" rows="3" placeholder="Skill Description"></textarea>Maximum 200 characters
                                    {this.validator.message('Description', this.state.description, 'required|maxLength:200')}
                                </td>
                            </tr>
                            <tr>
                                <td>Display Order<span className='text-danger ml-1'>*</span></td>
                                <td><input type="text"
                                    name="displayOrder"
                                    value={this.state.displayOrder}
                                    onChange={this.handleChange}
                                    id="displayOrder" className="col-lg-2 form-control" pattern="[0-9]*" maxLength="3" />
                                    {this.validator.message('Display Order', this.state.displayOrder, 'required|numeric|min:0,num|numeric|max:99999,num')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>
                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addClinic} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClinic} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default SkillsAdd
