const UserProfile = (function() {
    var currentpage="";
    var selectedId="";
    var selectedName="";
    let clinicId = 0;
    let staffId = 0;
    let staffName = "";
    let serviceId = 0;
    let serviceName = "";
    let highlightIds = "";
    let highlightType = "success"
    let scheduleDate = "";
    let navigatedFrom = "";
    let navParams = {};
    let organizationId = 0;
    let clientId = 0;
    let globalClientParams = null;

    var getPage = function() {
      return currentpage;
    };
  
    var setPage = function(page) {
      currentpage = page;
    };

    var getSelectedName = function() {
      return selectedName;
    };
  
    var setSelectedName = function(name) {
      selectedName = name;
    };
    var getSelectedId = function() {
      return selectedId; 
    };
  
    var setSelectedId = function(id) {
      selectedId = id;
    };
    var getClinicId = function() {
      return clinicId;
    };
  
    var setClinicId = function(id) {
      clinicId = id;
    };

    var setStaffId = function(id) {
      staffId = id
    }
    var getStaffId = function(){
      return staffId
    }

    var setStaffName = function(name){
      staffName = name
    }
    var getStaffName = function() {
      return staffName
    }

    var setServiceId = function(id) {
      serviceId = id
    }
    var getServiceId = function() {
      return serviceId
    }
    var setServiceName = function(name) {
      serviceName = name
    }
    var getServiceName = function() {
      return serviceName
    }

    var setHighlightIds = function(ids) {
      highlightIds = ids
    }
    var getHightlightIds = function() {
      return highlightIds
    }

    var setScheduleDate = function(date) {
      scheduleDate = date
    }
    var getScheduleDate = function() {
      return scheduleDate
    }

    const setNavigatedFrom = (param) => {
      navigatedFrom = param
    }
    const getNavigatedFrom = () => {
      return navigatedFrom
    }

    const setNavParams = (params) => {
      navParams = params
    }
    const getNavParams = () => {
      return navParams
    }

    const setHighlights = (ids, type) => {
      highlightIds=ids
      highlightType=type
    }
    const setHighlightType = (param) => {
      highlightType = param
    }
    const getHightlightType = () => {
      return highlightType
    }

    const setOrganizationId = (param) => {
      if (organizationId !== param) clientId = 0
      organizationId = param
    }
    const getOrganizationId = () => {
      return organizationId
    }

    const setClientId = (param) => {
      clientId = param
    }
    const getClientId = () => {
      return clientId
    }

    const setGlobalClientParams = (params) => {
      globalClientParams = params
      localStorage.setItem("client", JSON.stringify(globalClientParams));
    }
    const getGlobalClientParams = () => {
      if (globalClientParams === null) globalClientParams = JSON.parse(localStorage.getItem("client"))
      return globalClientParams
    }

    var getClinicName= function(){
      let r = ""
      const clinics = JSON.parse(localStorage.getItem('client')).clinics;
      for(let i = 0; i < clinics.length; i++) {
        if (clinics[i].id === parseInt(clinicId))
          r = clinics[i].name
      }
      return r
    }

    return {
      getPage: getPage,
      setPage:setPage,
      getSelectedId:getSelectedId,
      setSelectedId:setSelectedId,
      getSelectedName:getSelectedName,
      setSelectedName:setSelectedName,
      setClinicId:setClinicId,
      getClinicId:getClinicId,
      getClinicName:getClinicName,
      setStaffId:setStaffId,
      getStaffId:getStaffId,
      setStaffName:setStaffName,
      getStaffName:getStaffName,
      setServiceId:setServiceId,
      getServiceId:getServiceId,
      setServiceName:setServiceName,
      getServiceName:getServiceName,
      setHighlightIds:setHighlightIds,
      getHightlightIds:getHightlightIds,
      setScheduleDate:setScheduleDate,
      getScheduleDate:getScheduleDate,
      setNavigatedFrom: setNavigatedFrom,
      getNavigatedFrom: getNavigatedFrom,
      setNavParams: setNavParams,
      getNavParams: getNavParams,
      setHighlightType: setHighlightType,
      getHightlightType: getHightlightType,
      setHighlights: setHighlights,
      setOrganizationId: setOrganizationId,
      getOrganizationId: getOrganizationId,
      setClientId: setClientId,
      getClientId: getClientId,
      setGlobalClientParams: setGlobalClientParams,
      getGlobalClientParams: getGlobalClientParams,
    }
  })();
  
  export default UserProfile;