import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import ocsHighlighRow from '../../utils/OcsHighlighRow'
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../../utils/Util'
class Timings extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codesetItemId: '0',
            showList: false,
            TimingsList: [],
            showModal: false,
            timingid: '',
            timingname: '',
            sortConfig: {
                'dayOfWeek': { dataField: 'dayOfWeek', order: 'asc' },
            },
        }
        this.clinicHandleChange = this.clinicHandleChange.bind(this)
        this.AddOpen = this.AddOpen.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.getTimingList = this.getTimingList.bind(this)
        UserProfile.setClinicId(this.props.clinicId)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getTimingList()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.TimingsList, 'timingsId', UserProfile.getHightlightIds())
        this.setState({ TimingsList: this.state.TimingsList });
        this.node.paginationContext.currPage = p;
    }
    getTimingList() {
        axios.get('/timingsforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
            .then(response2 => {
                this.setState({
                    TimingsList: response2.data,
                    codesetItemId: UserProfile.getClinicId(),
                    showList: true
                });
                this.gotoPage.click();
            })
            .catch(function (error) {
                console.log('response2 : ', error);
                history.push('/Errorpage')
            })
    }
    clinicHandleChange(event) {
        const { name, value } = event.target
        axios.get('/timingsforclinic/' + event.target.value, { headers: authHeader() })
            .then(response => {
                this.setState({
                    TimingsList: response.data,
                    [name]: value,
                    showList: true,
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    AddOpen() {
        history.push('/Timingsadd')
    }
    EditOpen(id) {
        history.push('/Timingsedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/timingsdel/' + this.state.timingid, '', { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights([], 'success')
                this.getTimingList()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `'${this.state.timingname}' Timing for '${UserProfile.getClinicName()}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name) {
        this.setState({
            timingid: id,
            timingname: name === 1 ? 'Sunday' : name === 2 ? 'Monday' : name === 3 ? 'Tuesday' : name === 4 ? 'Wednesday' : name === 5 ? 'Thursday' : name === 6 ? 'Friday' : 'Saturday',
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'timingsId',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '50px', textAlign: 'right' },
                align: 'right'
            },
            {
                dataField: 'dayOfWeek',
                text: 'Day(s)',
                sort: true,
                headerStyle: { width: '20%' },
                formatter: (cell, row) => (
                    row.dayOfWeek === 1 ? 'Sunday' : row.dayOfWeek === 2 ? 'Monday' : row.dayOfWeek === 3 ? 'Tuesday' : row.dayOfWeek === 4 ? 'Wednesday' : row.dayOfWeek === 5 ? 'Thursday' : row.dayOfWeek === 6 ? 'Friday' : 'Saturday'
                )
            },
            {
                dataField: 'clinicOpenTime',
                text: 'Clinic Open',
                sort: true,
                headerStyle: { width: '100px', textAlign: 'right' },
                formatter: (cell, row) => (
                    moment(row.clinicOpenTime, 'HH:mm').format('HH:mm')
                ),
                align: 'right'
            },
            {
                dataField: 'clinicCloseTime',
                text: 'Clinic Close',
                sort: true,
                headerStyle: { width: '100px', textAlign: 'right' },
                formatter: (cell, row) => (
                    moment(row.clinicCloseTime, 'HH:mm').format('HH:mm')
                ),
                align: 'right'
            },
            {
                dataField: 'treatmentRoomStartTime',
                text: 'Treatment First Patient',
                sort: true,
                headerStyle: { width: '130px', textAlign: 'right' },
                formatter: (cell, row) => (
                    moment(row.treatmentRoomStartTime, 'HH:mm').format('HH:mm')
                ),
                align: 'right'
            },
            {
                dataField: 'treatmentRoomEndTime',
                text: 'Treatment Last Patient',
                sort: true,
                headerStyle: { width: '130px', textAlign: 'right' },
                formatter: (cell, row) => (
                    moment(row.treatmentRoomEndTime, 'HH:mm').format('HH:mm')
                ),
                align: 'right'
            },
            {
                dataField: 'labStartTime',
                text: 'Lab Start',
                sort: true,
                headerStyle: { width: '80px', textAlign: 'right' },
                formatter: (cell, row) => (
                    moment(row.labStartTime, 'HH:mm').format('HH:mm')
                ),
                align: 'right'
            },
            {
                dataField: 'labEndTime',
                text: 'Lab End',
                sort: true,
                headerStyle: { width: '80px', textAlign: 'right' },
                formatter: (cell, row) => (
                    moment(row.labEndTime, 'HH:mm').format('HH:mm')
                ),
                align: 'right'
            },
            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.timingsId)} data-tip="Edit">Edit</button>&nbsp;
                        <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.timingsId, row.dayOfWeek)} data-tip="Delete">Delete</button>
                    </div>
                ),
                align: 'center'
            }

        ];
        return (
            <div>
                <div className="form-row align-items-center my-1">
                    <div className="col">
                        <div className="float-right">
                            <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                                <div className="d-flex justify-content-end">
                                    <ReactTooltip />
                                    {this.state.TimingsList.length === 7 ?
                                        <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" disabled onClick={this.AddOpen} >Add Timings</button>
                                        :
                                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen} >Add Timings</button>
                                    }
                                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <div>
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete <b>Timings for {this.state.timingname}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            keyField='timingsId'
                            data={this.state.TimingsList}
                            columns={columns}
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory()}
                            noDataIndication="No Record(s)"
                            striped hover condensed
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default Timings
