import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../../utils/Util'
import ocsHighlighRow from '../../utils/OcsHighlighRow'

class DonotbookPatients extends Component {
    constructor(props) {
        super(props)
        this.state = {
            codesetItemId: '0',
            servicesTypeList: [],
            showList: false,
            serviceTypeId: '',
            DonotbookPatientsList: [],
            DonotbookPatientsListOrg: [],
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            all: '',
            showModal: false,
            donotbookid: '',
            donotbookday: '',
            sortConfig: {
                'dayOfWeek': { dataField: 'dayOfWeek', order: 'asc' },
            },
        }
        this.AddOpen = this.AddOpen.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.getDonotbookPatientList = this.getDonotbookPatientList.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getDonotbookPatientList()
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.DonotbookPatientsList, 'donotbookId', UserProfile.getHightlightIds())
        this.setState({ DonotbookPatientsList: this.state.DonotbookPatientsList });
        this.node.paginationContext.currPage = p;
    }
    getDonotbookPatientList() {
        if (UserProfile.getClinicId().length > 0) {
            axios.get('/donotbookforclinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                .then(response1 => {
                    axios.get('/servicetypeslist', { headers: authHeader() })
                        .then(response2 => {
                            let dataset = [...response1.data]
                            dataset = dataset.map((t) => ({ ...t, timing: t.startTime + ' - ' + t.endTime }))
                            dataset = dataset.map((t) => ({ ...t, serviceTypeName: response2.data.find((r) => r.codesetItemId === t.servicetypeId).name }))
                            dataset.sort((a, b) => { return a.dayOfWeek < b.dayOfWeek ? -1 : a.dayOfWeek > b.dayOfWeek ? 1 : 0 })
                            this.setState({
                                DonotbookPatientsList: dataset,
                                DonotbookPatientsListOrg: dataset,
                                codesetItemId: UserProfile.getClinicId(),
                                showList: true,
                                servicesTypeList: response2.data
                            });
                            this.gotoPage.click();
                        })
                        .catch(function (error) {
                            console.log(error);
                            history.push('/Errorpage')
                        })
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }
    }
    handleChange(event) {
        if (event.target.type === 'checkbox') {
            if (event.target.name === 'all' && event.target.checked) {
                this.setState({
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    all: true
                }, () => {
                    this.dayOfWeekChecked();
                });
            } else if (document.getElementById('sunday').checked === false && document.getElementById('monday').checked === false &&
                document.getElementById('tuesday').checked === false && document.getElementById('wednesday').checked === false &&
                document.getElementById('thursday').checked === false && document.getElementById('friday').checked === false &&
                document.getElementById('saturday').checked === false) {
                this.setState({
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    all: true
                }, () => {
                    this.dayOfWeekChecked();
                });
            } else {
                this.setState({
                    [event.target.name]: event.target.checked,
                    all: false
                }, () => {
                    this.dayOfWeekChecked();
                });
            }
        }
    }
    dayOfWeekChecked(field, val) {
        let arr = []
        if (this.state.all === true) {
            arr = [1, 2, 3, 4, 5, 6, 7];
        } else {
            if (this.state.sunday === true) arr.push(1);
            if (this.state.monday === true) arr.push(2);
            if (this.state.tuesday === true) arr.push(3);
            if (this.state.wednesday === true) arr.push(4);
            if (this.state.thursday === true) arr.push(5);
            if (this.state.friday === true) arr.push(6);
            if (this.state.saturday === true) arr.push(7);
        }
        this.setState({
            DonotbookPatientsList: this.state.DonotbookPatientsListOrg.filter((t) => arr.includes(t.dayOfWeek))
        })
    }
    AddOpen() {
        history.push('/DonotbookPatientsadd')
    }
    EditOpen(id) {
        history.push('/DonotbookPatientsedit:' + id)
    }
    DeleteOpen(id) {
        axios.put('/donotbookdel/' + this.state.donotbookId, '', { headers: authHeader() })
            .then(response => {
                this.getDonotbookPatientList()
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `'${this.state.donotbookday}' Do not book patients of '${UserProfile.getClinicName()}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name) {
        this.setState({
            donotbookId: id,
            donotbookday: name == '2' ? ' Monday' : name == '3' ? 'Tuesday' : name == '4' ? 'Wednesday' : name == '5' ? 'Thursday' : name == '6' ? 'Friday' : name == '7' ? 'Saturday' : 'Sunday',
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'donotbookId',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '50px' }
            },

            {
                dataField: 'dayOfWeek',
                text: 'Day(s)',
                sort: true,
                headerStyle: { width: '150px' },
                formatter: (cell, row) => (
                    row.dayOfWeek == '2' ? ' Monday' : row.dayOfWeek == '3' ? 'Tuesday' : row.dayOfWeek == '4' ? 'Wednesday' : row.dayOfWeek == '5' ? 'Thursday' : row.dayOfWeek == '6' ? 'Friday' : row.dayOfWeek == '7' ? 'Saturday' : 'Sunday'
                )

            },
            {
                dataField: 'serviceTypeName',
                text: 'Service Type',
                sort: true,
                headerStyle: { width: '150px' },
            },
            {
                dataField: 'timing',
                text: 'Timing',
                sort: true,
                headerStyle: { width: '250px' },
            },
            {
                dataField: 'activeStatus',
                text: 'Active',
                sort: true,
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cell, row) => (
                    row.activeStatus == 'Y' ? 'Yes' : 'No'
                ),
                align: 'center'
            },

            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '100px', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.donotbookId)} data-tip="Edit">Edit</button>&nbsp;
                        <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.donotbookId, row.dayOfWeek)} data-tip="Delete">Delete</button>
                    </div>
                ),
                align: 'center'
            }

        ];
        return (
            <div>
                <div className="form-row align-items-center">
                    <div className="col">
                        <div className="float-right">
                            <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                                <div className="d-flex justify-content-end">
                                    <div className="form-check form-check-inline">&nbsp;&nbsp;
                                        <input className="form-check-input" type="checkbox"
                                            name="sunday"
                                            value={this.state.sunday}
                                            onChange={this.handleChange}
                                            id="sunday" />
                                        <label className="form-check-label" htmlFor="sunday">Sun</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="monday"
                                            value={this.state.monday}
                                            onChange={this.handleChange}
                                            id="monday" />
                                        <label className="form-check-label" htmlFor="monday">Mon</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="tuesday"
                                            value={this.state.tuesday}
                                            onChange={this.handleChange}
                                            id="tuesday" />
                                        <label className="form-check-label" htmlFor="tuesday">Tue</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="wednesday"
                                            value={this.state.wednesday}
                                            onChange={this.handleChange}
                                            id="wednesday" />
                                        <label className="form-check-label" htmlFor="wednesday">Wed</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="thursday"
                                            value={this.state.thursday}
                                            onChange={this.handleChange}
                                            id="thursday" />
                                        <label className="form-check-label" htmlFor="thursday">Thu</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="friday"
                                            value={this.state.friday}
                                            onChange={this.handleChange}
                                            id="friday" />
                                        <label className="form-check-label" htmlFor="friday">Fri</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="saturday"
                                            value={this.state.saturday}
                                            onChange={this.handleChange}
                                            id="saturday" />
                                        <label className="form-check-label" htmlFor="saturday">Sat</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="checkbox"
                                            name="all"
                                            value={this.state.all}
                                            onChange={this.handleChange} checked={this.state.all}
                                            id="all" />
                                        <label className="form-check-label" htmlFor="all">All</label>
                                    </div>
                                    <ReactTooltip />
                                    <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen} >Add New</button>
                                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                                </div>
                                <div>
                                    <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                                        <ReactTooltip />
                                        <Modal.Header closeButton>
                                            <Modal.Title>Delete Confirmation</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>Are you sure you want to delete the <b>{this.state.donotbookday}</b>?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="primary" data-tip="Yes" onClick={this.DeleteOpen.bind(this)}>
                                                Yes
                                            </Button>
                                            <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                                No
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            keyField='donotbookId'
                            data={this.state.DonotbookPatientsList}
                            columns={columns}
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory()}
                            noDataIndication="No Record(s)"
                            striped hover condensed
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default DonotbookPatients
