
import React, { Component } from 'react'

 class Logout extends Component {

    componentDidMount(){
        document.getElementById('navbar').style.display="none"
       
    }
    componentWillUnmount(){
        document.getElementById('navbar').style.display = "flex";
     }
    render() {
        return (
            <div>
                 Logout page
            </div>
        )
    }
}

export default Logout
