import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import ocsHighlighRow from '../../utils/OcsHighlighRow'
import multisort from 'multisort';
import { Store } from 'react-notifications-component'
import { findPageIndex } from '../../utils/Util'

class StaffAvailability extends Component {
    constructor(props) {
        super(props)
        this.daylist = []
        this.state = {
            staffList: [],
            codesetItemId: '0',
            skillsList: [],
            showList: false,
            staffId: '',
            staffAvailabilityList: [],
            staffAvailabilityListOrg: [],
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            all: 'checked',
            showModal: false,
            staffAvailabilityId: '',
            staffAvailabilityName: '',
            enableCopyButton: false,
            copyFromStaffId: '',
            copyFromStaffList: [],
            showOverwriteModel: false,
            sortConfig: {
                'dayOfWeek': { dataField: 'dayOfWeek', order: 'asc' },
                'timing': { dataField: 'timing', order: 'asc' },
            },
        }
        this.staffHandleChange = this.staffHandleChange.bind(this)
        this.AddOpen = this.AddOpen.bind(this)
        this.gotoPageHandler = this.gotoPageHandler.bind(this);
        this.handleChange = this.handleChange.bind(this)
        this.getStaffAvailabilityList = this.getStaffAvailabilityList.bind(this)
        this.copyStaffOnChange = this.copyStaffOnChange.bind(this)
        this.copyTimings = this.copyTimings.bind(this)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    componentDidMount() {
        this.getStaffAvailabilityList()
        if (UserProfile.getStaffId() > 0) this.getData(UserProfile.getStaffId());
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    gotoPageHandler() {
        const p = findPageIndex(this.state.staffAvailabilityList, 'staffAvailableId', UserProfile.getHightlightIds())
        this.setState({ staffAvailabilityList: this.state.staffAvailabilityList });
        this.node.paginationContext.currPage = p;
    }
    getStaffAvailabilityList() {
        axios.get('/activestaffclinics/' + UserProfile.getClinicId(), { headers: authHeader() })
            .then(response => {
                this.setState({
                    staffList: response.data,
                    staffAvailabilityList: []
                });
                if (this.state.staffId > 0)
                    this.getData(this.state.staffId)
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/activestaffhavingtiming/' + UserProfile.getClinicId() + '/-1', { headers: authHeader() }).then(response => {
            this.setState({
                copyFromStaffList: response.data
            });
        }).catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        });
        this.setState({ enableCopyButton: false, copyFromStaffId: '' });
    }
    copyStaffOnChange(event) {
        this.setState({ enableCopyButton: false });
        if (event.target.value !== "0") this.setState({ enableCopyButton: true });
        this.setState({ copyFromStaffId: event.target.value })
    }
    copyTimings() {
        if (this.state.staffAvailabilityList.length > 0) {
            this.setState({ showOverwriteModel: true })
        } else {
            this.overwriteStaffAvailability()
        }
    }
    handleOverwriteModelClose() {
        this.setState({ showOverwriteModel: false })
    }
    overwriteStaffAvailability() {
        axios.get('/copystaffavailability/' + this.state.copyFromStaffId + '/' + this.state.staffId, { headers: authHeader() }).then(response => {
            this.getStaffAvailabilityList()
            this.handleOverwriteModelClose()
        }).catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        });
    }
    staffHandleChange(event) {
        event.preventDefault();
        this.getData(event.target.value)
    }
    getData(staffId) {
        axios.get('/staffavailabilityforClinicstaff/' + UserProfile.getClinicId() + "/" + staffId, { headers: authHeader() })
            .then(response => {
                let dataset = [...response.data]
                dataset = dataset.map((t) => ({ ...t, timing: t.startTime + ' - ' + t.endTime }))
                this.setState({
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    all: true,
                    staffAvailabilityList: dataset,
                    staffAvailabilityListOrg: dataset,
                    staffId: staffId,
                    showList: true
                });
                this.gotoPage.click();
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            });
        axios.get('/activestaffhavingtiming/' + UserProfile.getClinicId() + "/" + staffId, { headers: authHeader() }).then(response => {
            this.setState({
                copyFromStaffList: response.data,
                enableCopyButton: false,
                copyFromStaffId: ''
            });
        }).catch(function (error) {
            console.log(error);
            history.push('/Errorpage')
        });
    }
    handleChange(event) {
        if (event.target.type === 'checkbox') {
            if (event.target.name === 'all' && event.target.checked) {
                this.setState({
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    all: true
                }, () => {
                    this.dayOfWeekChecked();
                });
            } else if (document.getElementById('sunday').checked === false && document.getElementById('monday').checked === false &&
                document.getElementById('tuesday').checked === false && document.getElementById('wednesday').checked === false &&
                document.getElementById('thursday').checked === false && document.getElementById('friday').checked === false &&
                document.getElementById('saturday').checked === false) {
                this.setState({
                    monday: false,
                    tuesday: false,
                    wednesday: false,
                    thursday: false,
                    friday: false,
                    saturday: false,
                    sunday: false,
                    all: true
                }, () => {
                    this.dayOfWeekChecked();
                });
            } else {
                this.setState({
                    [event.target.name]: event.target.checked,
                    all: false
                }, () => {
                    this.dayOfWeekChecked();
                });
            }
        }
    }
    dayOfWeekChecked(field, val) {
        let arr = []
        if (this.state.all === true) {
            arr = [1, 2, 3, 4, 5, 6, 7];
        } else {
            if (this.state.sunday === true) arr.push(1);
            if (this.state.monday === true) arr.push(2);
            if (this.state.tuesday === true) arr.push(3);
            if (this.state.wednesday === true) arr.push(4);
            if (this.state.thursday === true) arr.push(5);
            if (this.state.friday === true) arr.push(6);
            if (this.state.saturday === true) arr.push(7);
        }
        this.setState({
            staffAvailabilityList: this.state.staffAvailabilityListOrg.filter((t) => arr.includes(t.dayOfWeek))
        })
    }
    AddOpen() {
        UserProfile.setStaffId(this.state.staffId)
        var sel2 = document.getElementById('staffId');
        UserProfile.setStaffName(sel2.options[sel2.selectedIndex].text)
        history.push('/StaffAvailabilityAdd')
    }
    EditOpen(id) {
        UserProfile.setStaffId(this.state.staffId)
        var sel2 = document.getElementById('staffId');
        UserProfile.setStaffName(sel2.options[sel2.selectedIndex].text)
        history.push('/StaffAvailabilityEdit:' + id)
    }
    DeletestaffAvailability(id) {
        axios.put('/staffavailabilitdel/' + this.state.staffAvailabilityId, '', { headers: authHeader() })
            .then(response => {
                this.getStaffAvailabilityList()
                this.getData(this.state.staffId)
                this.handleClose()
                Store.addNotification({
                    title: "Deleted",
                    message: `'${this.state.staffAvailabilityName}' Staff Availability of '${UserProfile.getClinicName()}' is deleted`,
                    type: "info",
                    insert: "top",
                    container: "top-right",
                    dismiss: {
                        duration: window['runConfig'].deleteNotification.displayInterval,
                        onScreen: true
                    }
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    handleShow(id, name, startTime, endTime) {
        let text = name === 1 ? 'Sunday' : name === 2 ? 'Monday' : name === 3 ? 'Tuesday' : name === 4 ? 'Wednesday' : name === 5 ? 'Thursday' : name === 6 ? 'Friday' : 'Saturday'
        this.setState({
            staffAvailabilityId: id,
            staffAvailabilityName: text + ' [' + startTime + ' - ' + endTime + ']',
            showModal: true
        })
    }
    render() {
        const columns = [
            {
                dataField: 'staffAvailableId',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '5%' }
            },
            {
                dataField: 'dayOfWeek',
                text: 'Day(s)',
                sort: true,
                headerStyle: { width: '20%' },
                formatter: (cell, row) => (
                    row.dayOfWeek === 1 ? 'Sunday' : row.dayOfWeek === 2 ? 'Monday' : row.dayOfWeek === 3 ? 'Tuesday' : row.dayOfWeek === 4 ? 'Wednesday' : row.dayOfWeek === 5 ? 'Thursday' : row.dayOfWeek === 6 ? 'Friday' : 'Saturday'
                )
            },
            {
                dataField: 'timing',
                text: 'Timing',
                sort: true,
                headerStyle: { width: '20%' },
            },
            {
                dataField: 'perTimeSlot',
                text: '# of Patients per time slot',
                sort: true,
                headerStyle: { width: '20%' },
                align: 'right'
            },
            {
                dataField: 'availability',
                text: 'Availability',
                sort: true,
                headerStyle: { width: '20%' }
            },

            {
                dataField: 'action',
                text: 'Action',
                headerStyle: { width: '15%', textAlign: 'center' },
                formatter: (cellContent, row) => (
                    <div>
                        <ReactTooltip />
                        <button type="button" className="btn btn-primary" onClick={this.EditOpen.bind(this, row.staffAvailableId)} data-tip="Edit">Edit</button>&nbsp;
                        <button type="button" className="btn btn-primary" onClick={this.handleShow.bind(this, row.staffAvailableId, row.dayOfWeek, row.startTime, row.endTime)} data-tip="Delete">Delete</button>
                    </div>
                ),
                align: 'center'
            }

        ];
        return (
            <div>
                <div className="form-row align-items-center">
                    <div className="col-2">
                        <select className="form-control mr-sm-1" value={this.state.staffId}
                            name="staffId" id="staffId" onChange={this.staffHandleChange}>
                            <option value="0">--  Select Staff  --</option>
                            {this.state.staffList.length > 0
                                && this.state.staffList.map((staff, i) => {
                                    return (
                                        <option key={i} value={staff.staffId}>{staff.firstName} {staff.lastName}</option>
                                    )
                                })}
                        </select>
                    </div>
                    <div className="col-10">
                        <div className="float-right">
                            <div className="form-row align-items-center">
                                <div className="col-auto my-1">
                                    <label htmlFor="copyFromStaffId">Copy Timings From</label>
                                </div>
                                <div className="col-auto my-1">
                                    <select className="form-control mr-sm-1" value={this.state.copyFromStaffId}
                                        name="copyFromStaffId" id="copyFromStaffId" onChange={this.copyStaffOnChange}>
                                        <option value="0">--  Select Staff  --</option>
                                        {this.state.copyFromStaffList.length > 0
                                            && this.state.copyFromStaffList.map((staff, i) => {
                                                return (
                                                    <option key={i} value={staff.staffId}>{staff.firstName} {staff.lastName}</option>
                                                )
                                            })}
                                    </select>
                                </div>
                                <div className="col-auto my-1">
                                    <ReactTooltip />
                                    {this.state.enableCopyButton && this.state.staffId > 0 ?
                                        <button type="button" className="btn btn-primary" data-tip="Copy" data-toggle="tooltip" data-placement="top" onClick={this.copyTimings} >Copy</button>
                                        :
                                        <button type="button" className="btn btn-secondary" data-tip="Copy" data-toggle="tooltip" data-placement="top">Copy</button>
                                    }
                                    <button type="button" onClick={this.gotoPageHandler} ref={input => this.gotoPage = input} style={{ display: 'none' }}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <div className="d-flex justify-content-end">
                        <div className="form-check form-check-inline">&nbsp;&nbsp;
                            <input className="form-check-input" type="checkbox"
                                name="sunday"
                                value={this.state.sunday}
                                checked={this.state.sunday}
                                onChange={this.handleChange}
                                id="sunday" />
                            <label className="form-check-label" htmlFor="sunday">Sun</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="monday"
                                value={this.state.monday}
                                checked={this.state.monday}
                                onChange={this.handleChange}
                                id="monday" />
                            <label className="form-check-label" htmlFor="monday">Mon</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="tuesday"
                                value={this.state.tuesday}
                                checked={this.state.tuesday}
                                onChange={this.handleChange}
                                id="tuesday" />
                            <label className="form-check-label" htmlFor="tuesday">Tue</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="wednesday"
                                value={this.state.wednesday}
                                checked={this.state.wednesday}
                                onChange={this.handleChange}
                                id="wednesday" />
                            <label className="form-check-label" htmlFor="wednesday">Wed</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="thursday"
                                value={this.state.thursday}
                                checked={this.state.thursday}
                                onChange={this.handleChange}
                                id="thursday" />
                            <label className="form-check-label" htmlFor="thursday">Thu</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="friday"
                                value={this.state.friday}
                                checked={this.state.friday}
                                onChange={this.handleChange}
                                id="friday" />
                            <label className="form-check-label" htmlFor="friday">Fri</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="saturday"
                                value={this.state.saturday}
                                checked={this.state.saturday}
                                onChange={this.handleChange}
                                id="saturday" />
                            <label className="form-check-label" htmlFor="saturday">Sat</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="all"
                                value={this.state.all}
                                onChange={this.handleChange} checked={this.state.all}
                                id="all" />
                            <label className="form-check-label" htmlFor="all">All</label>
                        </div>
                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Add New" data-toggle="tooltip" data-placement="top" onClick={this.AddOpen} >Add Staff Availability</button>
                    </div>
                    <div style={{ display: (this.state.showModal ? 'block' : 'none') }} className="form-row col-auto my-1 d-flex justify-content-end">
                        <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Delete Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to delete staff availability for <b> {this.state.staffAvailabilityName}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.DeletestaffAvailability.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal show={this.state.showOverwriteModel} onHide={this.handleOverwriteModelClose.bind(this)}>
                            <ReactTooltip />
                            <Modal.Header closeButton>
                                <Modal.Title>Overwrite Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to overwrite staff availability for <b>{this.state.staffId.length === 0 || this.state.staffId === '0' ? '' : this.state.staffList.length === 0 ? '' : this.state.staffList.find(e => e.staffId == this.state.staffId).firstName + ' ' + this.state.staffList.find(e => e.staffId == this.state.staffId).lastName}</b>?</Modal.Body>
                            <Modal.Footer>
                                <Button variant="primary" data-tip="Yes" onClick={this.overwriteStaffAvailability.bind(this)}>
                                    Yes
                                </Button>
                                <Button variant="secondary" data-tip="No" onClick={this.handleOverwriteModelClose.bind(this)}>
                                    No
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                    <div>
                        {<BootstrapTable
                            remote={{
                                filter: false,
                                pagination: false,
                                sort: true,
                                cellEdit: false
                            }}
                            bootstrap4
                            keyField='staffAvailableId'
                            data={this.state.staffAvailabilityList}
                            columns={columns}
                            ref={(n) => (this.node = n)}
                            pagination={paginationFactory({})}
                            noDataIndication="No Record(s)"
                            striped hover condensed
                            rowClasses='table table-sm'
                            headerClasses="thread"
                            selectRow={ocsHighlighRow(UserProfile.getHightlightIds(), UserProfile.getHightlightType())}
                            onTableChange={this.handleTableChange}
                            sort={{ sortCaret: this.headerSortingClasses }}
                        />}
                    </div>
                </div>
            </div>
        )
    }
}

export default StaffAvailability
