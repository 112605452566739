import React, { Component } from 'react'
import history from '../../../history';
import { Form } from 'reactstrap';
import axios from 'axios'
import moment from 'moment'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip';
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class TimingsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timingsId: '',
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            dayOfWeek: '',
            clinicOpenTime: '',
            clinicCloseTime: '',
            treatmentRoomStartTime: '',
            treatmentRoomEndTime: '',
            labStartTime: '',
            labEndTime: '',
            clinicId: '',
            createdBy: '',
            createdDate: ''
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        var codesetitemid = this.props.match.params
        var id = codesetitemid.timingsId.replace(":", "")

        axios.get('/timings/' + id, { headers: authHeader() })
            .then(response => {
                this.setState({
                    timingsId: response.data.timingsId,
                    clinicId: response.data.clinicId,
                    dayOfWeek: response.data.dayOfWeek,
                    clinicOpenTime: moment(response.data.clinicOpenTime, 'HH:mm').format('HH:mm'),
                    clinicCloseTime: moment(response.data.clinicCloseTime, 'HH:mm').format('HH:mm'),
                    treatmentRoomStartTime: moment(response.data.treatmentRoomStartTime, 'HH:mm').format('HH:mm'),
                    treatmentRoomEndTime: moment(response.data.treatmentRoomEndTime, 'HH:mm').format('HH:mm'),
                    labStartTime: moment(response.data.labStartTime, 'HH:mm').format('HH:mm'),
                    labEndTime: moment(response.data.labEndTime, 'HH:mm').format('HH:mm'),
                    sunday: response.data.dayOfWeek === 1 ? true : false,
                    monday: response.data.dayOfWeek === 2 ? true : false,
                    tuesday: response.data.dayOfWeek === 3 ? true : false,
                    wednesday: response.data.dayOfWeek === 4 ? true : false,
                    thursday: response.data.dayOfWeek === 5 ? true : false,
                    friday: response.data.dayOfWeek === 6 ? true : false,
                    saturday: response.data.dayOfWeek === 7 ? true : false,
                    createdBy: response.data.createdBy,
                    createdDate: moment(response.data.createdDate).format('YYYY-MM-DD'),
                    clinicId: UserProfile.getClinicId()
                });

            })
            .catch(function (error) {
                console.log(error);
            })
    }

    addTimingsEdit = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        var today = new Date();
        if (this.dayOfWeekChecked().length > 0) {
            if (this.state.all == true) {
                for (var i = 1; i < 8; i++) {
                    axios.put('/timings/' + this.state.timings_id, {
                        timingsId: this.state.timingsId, clinicId: this.state.clinicId, dayOfWeek: i, clinicOpenTime: this.state.clinicOpenTime, clinicCloseTime: this.state.clinicCloseTime,
                        treatmentRoomStartTime: this.state.treatmentRoomStartTime, treatmentRoomEndTime: this.state.treatmentRoomEndTime,
                        labStartTime: this.state.labStartTime, labEndTime: this.state.labEndTime,
                        deletedYN: 'N', createdBy: this.state.createdBy, createdDate: this.state.createdDate,
                        lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD')
                    }, { headers: authHeader() })
                        .then(response => {
                            UserProfile.setHighlights([this.state.timingsId], 'success')
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
                history.push('/clinic/' + UserProfile.getClinicId() + '/3')
            } else {
                this.dayOfWeekChecked().map(d => {
                    const a = d
                    axios.put('/timings/' + this.state.timingsId, {
                        timingsId: this.state.timingsId, clinicId: this.state.clinicId, dayOfWeek: a, clinicOpenTime: this.state.clinicOpenTime, clinicCloseTime: this.state.clinicCloseTime,
                        treatmentRoomStartTime: this.state.treatmentRoomStartTime, treatmentRoomEndTime: this.state.treatmentRoomEndTime,
                        labStartTime: this.state.labStartTime, labEndTime: this.state.labEndTime,
                        deletedYN: 'N', createdBy: this.state.createdBy, createdDate: this.state.createdDate,
                        lastUpdatedBy: AuthService.getCurrentUserName(), lastUpdatedDate: moment(today).format('YYYY-MM-DD')
                    }, { headers: authHeader() })
                        .then(response => {
                            UserProfile.setHighlights([this.state.timingsId], 'success')
                            history.push('/clinic/' + UserProfile.getClinicId() + '/3')
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                })
            }
        }
    }
    dayOfWeekChecked() {
        var newArr = []
        if (this.state.sunday == true) newArr.push("1");
        if (this.state.monday == true) newArr.push("2");
        if (this.state.tuesday == true) newArr.push("3");
        if (this.state.wednesday == true) newArr.push("4");
        if (this.state.thursday == true) newArr.push("5");
        if (this.state.friday == true) newArr.push("6");
        if (this.state.saturday == true) newArr.push("7");
        if (this.state.all == true) newArr.push("8");
        return newArr;
    }
    cancelTimingsEdit = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([this.state.timingsId], 'info')
        history.push('/clinic/' + UserProfile.getClinicId() + '/3')
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        const type = target.type
        const checked = target.checked

        type == "checkbox" ?
            this.setState({
                [field]: checked,
            })
            :
            this.setState({
                [field]: value
            });
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Edit Timings</h4>
                </div>
                <RequiredTitle />
                <Form id="TimingsEdit" method="post">
                    <div className="section-title containerAdd">
                        <table className="table table-striped table-bordered">
                            <thead className="thead-dark">
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="col-auto"><b>Clinic Name</b></td>
                                    <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="col-auto">Day(s)</td>
                                    <td className="form-inline">
                                        <div className="form-row col-auto my-1 d-flex justify-content-start">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="sunday"
                                                    value={this.state.sunday}
                                                    onChange={this.handleChange}
                                                    id="sunday" checked={this.state.sunday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio3">Sun</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="monday"
                                                    value={this.state.monday}
                                                    onChange={this.handleChange}
                                                    id="monday" checked={this.state.monday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Mon</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="tuesday"
                                                    value={this.state.tuesday}
                                                    onChange={this.handleChange}
                                                    id="tuesday" checked={this.state.tuesday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio1">Tue</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="wednesday"
                                                    value={this.state.wednesday}
                                                    onChange={this.handleChange}
                                                    id="wednesday" checked={this.state.wednesday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio2">Wed</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="thursday"
                                                    value={this.state.thursday}
                                                    onChange={this.handleChange}
                                                    id="thursday" checked={this.state.thursday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio3">Thu</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="friday"
                                                    value={this.state.friday}
                                                    onChange={this.handleChange}
                                                    id="friday" checked={this.state.friday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio3">Fri</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" type="checkbox"
                                                    name="saturday"
                                                    value={this.state.saturday}
                                                    onChange={this.handleChange}
                                                    id="saturday" checked={this.state.saturday} disabled />
                                                <label className="form-check-label" htmlFor="inlineRadio3">Sat</label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="col-auto">Clinic Open time<span className='text-danger ml-1'>*</span></td>
                                    <td className="form-inline">
                                        <InputMask name="clinicOpenTime" value={this.state.clinicOpenTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" autoFocus/>
                                        {this.validator.message('clinicOpenTime', this.state.clinicOpenTime, 'requiredTime|isValidTime')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Clinic Close time<span className='text-danger ml-1'>*</span></td>
                                    <td className="form-inline">
                                        <InputMask name="clinicCloseTime" value={this.state.clinicCloseTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {this.validator.message('clinicCloseTime', this.state.clinicCloseTime, 'requiredTime|isValidTime|fromTimeToTimeIsValid:' + this.state.clinicOpenTime.replace(':', '`') + "," + this.state.clinicCloseTime.replace(':', '`'), { messages: { fromTimeToTimeIsValid: 'This value shall be after open time.' } })}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Treatment room (Start)<span className='text-danger ml-1'>*</span></td>
                                    <td className="form-inline">
                                        <InputMask name="treatmentRoomStartTime" value={this.state.treatmentRoomStartTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {this.validator.message('treatmentRoomStartTime', this.state.treatmentRoomStartTime, 'requiredTime|isValidTime')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Treatment room (End)<span className='text-danger ml-1'>*</span></td>
                                    <td className="form-inline">
                                        <InputMask name="treatmentRoomEndTime" value={this.state.treatmentRoomEndTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {this.validator.message('treatmentRoomEndTime', this.state.treatmentRoomEndTime, 'requiredTime|isValidTime|fromTimeToTimeIsValid:' + this.state.treatmentRoomStartTime.replace(':', '`') + "," + this.state.treatmentRoomEndTime.replace(':', '`'), { messages: { fromTimeToTimeIsValid: 'This value shall be after start time.' } })}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Lab Time (Start)<span className='text-danger ml-1'>*</span></td>
                                    <td className="form-inline">
                                        <InputMask name="labStartTime" value={this.state.labStartTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {this.validator.message('labStartTime', this.state.labStartTime, 'requiredTime|isValidTime')}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="col-auto">Lab Time (End)<span className='text-danger ml-1'>*</span></td>
                                    <td className="form-inline">
                                        <InputMask name="labEndTime" value={this.state.labEndTime}
                                            onChange={this.handleChange} className="col-md-2 form-control"
                                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                        {this.validator.message('labEndTime', this.state.labEndTime, 'requiredTime|isValidTime|fromTimeToTimeIsValid:' + this.state.labStartTime.replace(':', '`') + "," + this.state.labEndTime.replace(':', '`'), { messages: { fromTimeToTimeIsValid: 'This value shall be after start time.' } })}
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <ReactTooltip />
                                        <button type="button" onClick={this.addTimingsEdit} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                        <button type="button" onClick={this.cancelTimingsEdit} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                    </td>
                                </tr>

                            </tbody>
                        </table>

                    </div>
                </Form>
            </div>
        )
    }
}

export default TimingsEdit
