import React, { Component } from 'react';

const OptimizerContext = React.createContext();

class OptimizerContextProvider extends Component {
    state = {
        optimizerContext: {},
    }
    setOptimizerContext = (optimizerContext) => {
        this.setState((prevState) => ({ optimizerContext }))
    }

    render() {
        const { children } = this.props
        const { optimizerContext } = this.state
        const { setOptimizerContext } = this
    
        return (
          <OptimizerContext.Provider value={{optimizerContext, setOptimizerContext}}>
            {children}
          </OptimizerContext.Provider>
        )
      }
}

export const OptimizerContextConsumer = OptimizerContext.Consumer
export default OptimizerContext
export {OptimizerContextProvider}
