import React, { Component } from 'react'
import UserProfile from './UserProfile'

class Footer extends Component {
    render() {
        const currentyear = new Date().getFullYear();
        return (
            <div className="container-fluid footer-container">
                <div>
                    <p>&copy;2020-{currentyear} Smirta Innovations Inc. All Rights Reserved.</p>
                </div>
            </div>
        )
    }
}

export default Footer
