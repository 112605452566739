import React, { Component } from 'react'
import UserProfile from '../../UserProfile'
import axios from 'axios'
import '../../standardcode/Standardcodes.css'
import history from '../../../history';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import authHeader from '../../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import multisort from 'multisort';
class HighLevelFilter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            highlevelFilterList: [],
            codesetItemId: '0',
            servicesList: [],
            clinicservicesList: [],
            serviceName: '',
            serviceId: '',
            showList: false,
            showModal: false,
            editServiceId: 0,
            editServiceName: '',
            editType: '',
            showDeleteButton: false,
            showDeleteConfirmation: false,
            sortConfig: {
                'serviceName': { dataField: 'serviceName', order: 'asc' },
            },
        }
        this.callinghandleChange = this.callinghandleChange.bind(this)
        UserProfile.setPage(this.props.clinicId)
        this.handleTableChange = this.handleTableChange.bind(this)
        this.headerSortingClasses = this.headerSortingClasses.bind(this)
    }
    async componentDidMount() {
        if (UserProfile.getPage() > 0) {
            this.callinghandleChange(UserProfile.getPage())
        }
    }
    headerSortingClasses(order, column) {
        let i = 1
        if (this.state.sortConfig[column.dataField]) {
            for (const config in this.state.sortConfig) {
                if (config !== column.dataField) {
                    i++
                } else {
                    break
                }
            }
        }
        if (!this.state.sortConfig[column.dataField]) {
            return <span className="order-4" />;
        } else if (this.state.sortConfig[column.dataField].order === "asc") {
            return <span><span className="caret-4-asc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else if (this.state.sortConfig[column.dataField].order === "desc") {
            return <span><span className="caret-4-desc" />{i > -1 ? '[' + i + ']' : ''}</span>;
        } else {
            return null;
        }
    };
    handleTableChange(
        type, { page, sizePerPage, sortField, sortOrder, data }) {
        let sortCol = {};
        if (type === "sort") {
            if (this.state.sortConfig[sortField]) {
                sortCol = {
                    ...this.state.sortConfig,
                    [sortField]: {
                        ...this.state.sortConfig[sortField],
                        order: "desc"
                    }
                };
                if (this.state.sortConfig[sortField].order === "asc")
                    this.setState({ sortConfig: sortCol });
                else
                    delete this.state.sortConfig[sortField]
            } else {
                const sortObj = {
                    dataField: sortField,
                    order: 'asc'
                };
                sortCol = { ...this.state.sortConfig, [sortField]: sortObj };
                this.setState({ sortConfig: sortCol });
            }

            let criteria = [];
            for (const config in sortCol) {
                if (sortCol[config].order === "asc") {
                    criteria.push(config)
                } else {
                    criteria.push('~' + config)
                }
            }
            multisort(data, criteria)
        }
    };
    async getClinicService(clinicid) {
        await axios.get('/getClinicServices/' + clinicid, { headers: authHeader() })
            .then(response => {
                this.setState({ servicesList: response.data });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async callinghandleChange(clinicid) {
        this.getClinicService(clinicid)
        await axios.get('/clinicservicesforclinic/' + clinicid, { headers: authHeader() })
            .then(response => {
                let highlevelFilterListnew = []
                let hasFilterValues = false
                response.data.map((clinicser, i) => {
                    axios.get('/highlevelfilterforservice/' + clinicid + '/' + clinicser.servicesId, { headers: authHeader() })
                        .then(response => {
                            if (response.data.length > 0) {
                                response.data.map((x) => {
                                    highlevelFilterListnew.push({
                                        id: i,
                                        clinicId: x.clinicId,
                                        serviceId: x.serviceId,
                                        serviceName: this.state.servicesList.filter(services => services[1] === x.serviceId).map((x) => {
                                            return x[2]
                                        }),
                                        costNotassigningPDk: x.costNotassigningPDk,
                                        costStartingaservicePDStartk: x.costStartingaservicePDStartk,
                                        costNotassigningCD: x.costNotassigningCD
                                    })
                                    hasFilterValues = true
                                })
                            }
                            else {
                                highlevelFilterListnew.push({
                                    id: i,
                                    clinicId: clinicid,
                                    serviceId: clinicser.servicesId,
                                    serviceName: this.state.servicesList.filter(services => services[1] === clinicser.servicesId).map((x) => {
                                        return x[2]
                                    }),
                                    costNotassigningPDk: 0,
                                    costStartingaservicePDStartk: 0,
                                    costNotassigningCD: 0
                                })
                                hasFilterValues = hasFilterValues ? true : false
                            }
                            highlevelFilterListnew.sort((a, b) => { return a.serviceName < b.serviceName ? -1 : a.serviceName > b.serviceName ? 1 : 0 })
                            this.setState({
                                highlevelFilterList: highlevelFilterListnew,
                                codesetItemId: clinicid,
                                showList: true,
                                showDeleteButton: hasFilterValues
                            });
                        })
                        .catch(function (error) {
                            console.log(error);
                            history.push('/Errorpage')
                        })
                })
                this.setState({
                    highlevelFilterList: highlevelFilterListnew,
                    codesetItemId: clinicid,
                    showList: true,
                    showDeleteButton: hasFilterValues
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    ShowModel(id, val, servicename) {
        this.setState({
            editServiceName: servicename,
            editServiceId: id,
            editType: val,
            showModal: true
        })
    }
    handleClose() {
        this.setState({
            showModal: false
        })
    }
    Open() {
        if (this.state.editType === 'PD') {
            this.AddOpen()
        } else if (this.state.editType === 'PDStart') {
            this.AddStartkOpen()
        } else if (this.state.editType === 'CD') {
            this.AddCDOpen()
        }
    }
    AddOpen() {
        UserProfile.setClinicId(this.state.codesetItemId)
        UserProfile.setServiceName(this.state.editServiceName)
        UserProfile.setServiceId(this.state.editServiceId)
        history.push('/HighLevelFilteradd')
    }
    AddStartkOpen() {
        UserProfile.setClinicId(this.state.codesetItemId)
        UserProfile.setServiceName(this.state.editServiceName)
        UserProfile.setServiceId(this.state.editServiceId)
        history.push('/HighLevelFilterstartk')
    }
    AddCDOpen() {
        UserProfile.setClinicId(this.state.codesetItemId)
        UserProfile.setServiceName(this.state.editServiceName)
        UserProfile.setServiceId(this.state.editServiceId)
        history.push('/HighLevelFiltercd')
    }
    handleShowDeleteConfirmation() {
        this.setState({ showDeleteConfirmation: true })
    }
    handleDeleteConfirmationClose() {
        this.setState({ showDeleteConfirmation: false })
    }
    Delete() {
        axios.delete('/highlevelfilterbyclinic/' + UserProfile.getPage(), { headers: authHeader() })
            .then(response => {
                this.callinghandleChange(UserProfile.getPage())
                this.setState({ showDeleteConfirmation: false })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    render() {
        const columns = [
            {
                dataField: 'sno',
                text: '#',
                formatter: (cell, row, index) => (
                    index + 1
                ),
                headerStyle: { width: '50px' }
            },

            {
                dataField: 'serviceName',
                text: 'Service',
                sort: true,
                headerStyle: { width: '80px' },
            },
            {
                dataField: 'costNotassigningPDk',
                text: 'Score of not assigning a service to a timeslot k - P(D_k)',
                headerStyle: { width: '200px' },
                formatter: (cellContent, row) => (
                    row.costNotassigningPDk == '0' ?
                        <div><button type="button" className="btn btn-primary" onClick={this.ShowModel.bind(this, row.serviceId, 'PD', this.state.servicesList.filter(services => services[1] === row.serviceId).map((x, i) => {
                            return x[2]
                        }))} data-tip="Add New">Add</button><ReactTooltip /></div> :
                        <div><button type="button" className="btn btn-primary" onClick={this.ShowModel.bind(this, row.serviceId, 'PD', this.state.servicesList.filter(services => services[1] === row.serviceId).map((x, i) => {
                            return x[2]
                        }))} data-tip="Edit">Edit</button><ReactTooltip /></div>
                ),
                align: 'center',
            },
            {
                dataField: 'costStartingaservicePDStartk',
                text: 'Score of starting a service in a given valid timeslot k - P(D_Start_k)',
                headerStyle: { width: '200px' },
                formatter: (cellContent, row) => (
                    row.costStartingaservicePDStartk == '0' ?
                        <div><button type="button" className="btn btn-primary" onClick={this.ShowModel.bind(this, row.serviceId, 'PDStart', this.state.servicesList.filter(services => services[1] === row.serviceId).map((x, i) => {
                            return x[2]
                        }))} data-tip="Add New">Add</button><ReactTooltip /></div> :
                        <div><button type="button" className="btn btn-primary" onClick={this.ShowModel.bind(this, row.serviceId, 'PDStart', this.state.servicesList.filter(services => services[1] === row.serviceId).map((x, i) => {
                            return x[2]
                        }))} data-tip="Edit">Edit</button><ReactTooltip /></div>
                ),
                align: 'center',
            },
            {
                dataField: 'costNotassigningCD',
                text: 'Score of Not assigning a service to any valid timeslot - C(D)',
                headerStyle: { width: '200px' },
                formatter: (cellContent, row) => (
                    row.costNotassigningCD == '0' ?
                        <div><button type="button" className="btn btn-primary" onClick={this.ShowModel.bind(this, row.serviceId, 'CD', this.state.servicesList.filter(services => services[1] === row.serviceId).map((x, i) => {
                            return x[2]
                        }))} data-tip="Add New">Add</button><ReactTooltip /></div> :
                        <div><button type="button" className="btn btn-primary" onClick={this.ShowModel.bind(this, row.serviceId, 'CD', this.state.servicesList.filter(services => services[1] === row.serviceId).map((x, i) => {
                            return x[2]
                        }))} data-tip="Edit">Edit</button><ReactTooltip /></div>
                ),
                align: 'center',
            },
        ];
        return (
            <div>
                <div className="d-flex justify-content-end">
                    {this.state.showDeleteButton ?
                        <button type="button" className="btn btn-primary mb-2" align="right" data-tip="Delete High Level Filter" data-toggle="tooltip" data-placement="top" onClick={this.handleShowDeleteConfirmation.bind(this)}>Delete</button>
                        :
                        <button type="button" className="btn btn-secondary mb-2" align="right" data-tip="Delete High Level Filter" data-toggle="tooltip" data-placement="top" disabled>Delete</button>
                    }
                </div>
                <div style={{ display: (this.state.showList ? 'block' : 'none') }}>
                    <BootstrapTable
                        remote={{
                            filter: false,
                            pagination: false,
                            sort: true,
                            cellEdit: false
                        }}
                        keyField='id'
                        data={this.state.highlevelFilterList}
                        columns={columns}
                        pagination={paginationFactory()}
                        noDataIndication="No Record(s)"
                        striped hover condensed
                        rowClasses='table table-sm'
                        headerClasses="thread"
                        onTableChange={this.handleTableChange}
                        sort={{ sortCaret: this.headerSortingClasses }}
                    />
                    <Modal show={this.state.showModal} onHide={this.handleClose.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to make modifications to the High Level Filter?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.Open.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.handleClose.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={this.state.showDeleteConfirmation} onHide={this.handleDeleteConfirmationClose.bind(this)}>
                        <ReactTooltip />
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure you want to delete this High Level Filter?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" data-tip="Yes" onClick={this.Delete.bind(this)}>
                                Yes
                            </Button>
                            <Button variant="secondary" data-tip="No" onClick={this.handleDeleteConfirmationClose.bind(this)}>
                                No
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default HighLevelFilter
