import React, { Component } from 'react'
import _ from 'lodash';
import history from '../../../history';
import { Button, Form, FormGroup, Label, Input, Fade } from 'reactstrap';
import axios from 'axios'
import moment from 'moment'
import UserProfile from '../../UserProfile'
import authHeader from '../../authentication/authHeader'
import AuthService from '../../authentication/AuthService'
import ReactTooltip from 'react-tooltip'
import { RequiredTitle } from '../../utils/Util'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'

class ClinicServicesEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            servicesId: '',
            servicesList: [],
            serviceId: '',
            servicesTypeList: [],
            serviceTypeName: '',
            servicetypeId: '',
            clinicId: '',
            description: '',
            activeStatus: 'Y',
            errors: [], clinicServiceTypeList: []
        }
        this.setFocus = React.createRef();
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        var codesetitemid = this.props.match.params
        var id = codesetitemid.servicesId.replace(":", "")
        axios.get('/clinicservices/' + id, { headers: authHeader() })
            .then(response1 => {
                axios.get('/services', { headers: authHeader() })
                    .then(response2 => {
                        axios.get('/servicetypeslist', { headers: authHeader() })
                            .then(response3 => {
                                axios.get('/clinicserviceTypesforClinic/' + UserProfile.getClinicId(), { headers: authHeader() })
                                    .then(response4 => {
                                        this.setState({
                                            servicesId: response1.data.servicesId,
                                            clinicId: response1.data.clinicId,
                                            serviceId: response1.data.serviceId,
                                            servicetypeId: response1.data.servicetypeId,
                                            description: response1.data.description,
                                            activeStatus: response1.data.activeStatus,
                                            servicesList: response2.data,
                                            servicesTypeList: response3.data,
                                            clinicServiceTypeList: response4.data.filter(o => response3.data.find(o2 => o[2] === o2.codesetItemId)),
                                            clinicId: UserProfile.getClinicId()
                                        });
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        history.push('/Errorpage')
                                    })
                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        this.setFocus.current.focus();
    }
    addClinicServicesEdit = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }

        axios.put('/clinicservices/' + this.state.servicesId, {
            servicesId: this.state.servicesId, clinicId: this.state.clinicId, serviceId: this.state.serviceId, servicetypeId: this.state.servicetypeId, description: this.state.description,
            activeStatus: this.state.activeStatus, deletedYN: 'N', createdBy: AuthService.getCurrentUserName(),
            lastUpdatedBy: AuthService.getCurrentUserName()
        }, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights([this.state.servicesId], 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/6');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    cancelClinicServicesEdit = (event) => {
        event.preventDefault();
        UserProfile.setHighlights([this.state.servicesId], 'info')
        history.push('/clinic/' + UserProfile.getClinicId() + '/6');
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        this.setState({
            [field]: value
        });
    }
    handleChangeForResource = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value

        const d = this.state.servicesList.filter(servicesType => servicesType.resourceTypeId == value).map((x, i) => { return x.codesetItemId })
        this.setState({
            [field]: value,
            serviceId: d[0]
        });
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title">
                    <h4>Edit Service</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <thead className="thead-dark">
                        </thead>
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Service Type<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <select className="form-control mr-sm-1" value={this.state.servicetypeId} name="servicetypeId" id="servicetypeId" onChange={this.handleChangeForResource}
                                        ref={this.setFocus}>
                                        <option value="0">-- Service Type  --</option>
                                        {this.state.clinicServiceTypeList.length > 0
                                            && this.state.clinicServiceTypeList.map((services, i) => {
                                                return (
                                                    this.state.servicesTypeList.filter(serviceType => serviceType.codesetItemId === services[2]).map((x, i) => {
                                                        return (<option key={i} value={x.codesetItemId}>{x.name}</option>);
                                                    })
                                                )
                                            })}
                                    </select>
                                    {this.validator.message('servicetypeId', this.state.servicetypeId, 'required|not_in:0')}
                                </td>
                            </tr>
                            <tr>
                                <td>Service</td>
                                <td className="col-auto"><b>
                                    {this.state.servicesList.filter(servicesType => servicesType.resourceTypeId == this.state.servicetypeId).map((x, i) => { return (x.name) })}
                                </b>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Description<span className='text-danger ml-1'>*</span></td>
                                <td className="col-auto"><input type="text"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                    id="description" className="form-control" placeholder="Description" />
                                    {this.validator.message('Description', this.state.description, 'required|maxLength:200')}
                                </td>
                            </tr>
                            <tr>
                                <td>Active?<span className='text-danger ml-1'>*</span></td>
                                <td className="form-inline">
                                    <div className="form-group mx-sm-1 mb-1">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="yes" value="Y" checked={this.state.activeStatus === "Y"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="yes">Yes</label>
                                    </div>

                                    <div className="form-group mx-sm-4 mb-1 mx-lg-3">
                                        <input className="form-check-input" type="radio" name="activeStatus" id="no" value="N" checked={this.state.activeStatus === "N"} onChange={this.handleChange} />
                                        <label className="form-check-label" htmlFor="no">No</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.addClinicServicesEdit} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.cancelClinicServicesEdit} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ClinicServicesEdit
