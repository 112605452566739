import axios from "axios";

class AuthService {
  login(username, password) {
    return axios.post("auth/signin/", {username, password})
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
      return response.data;
    });
  }
  logout() {
    localStorage.removeItem("user");
  }
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getCurrentUserName() {
    return Object.values(JSON.parse(localStorage.getItem('user')))[4];
  }
}

export default new AuthService();