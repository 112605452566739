import React, { useState } from "react";
import Staff from './staff/Staff'
import StaffAvailability from './staffavailability/StaffAvailability'
import Timings from './timings/Timings'
import ClinicResources from './clinicresources/ClinicResources'
import ClinicServiceType from './clinicservicetype/ClinicServiceType'
import ClinicServices from './clinicservices/ClinicServices'
import HighLevelFilter from './highlevelfilter/HighLevelFilter'
import DonotbookPatients from './donotbookpatients/DonotbookPatients'
import DonotStart from './donotstart/DonotStart'
import MaxTreatStart from './maxtreatstart/MaxTreatStart'
import UserProfile from '../UserProfile'
const ClinicWrapper = (props) => {
    const clinicId = props.match.params.clinicid
    const type = props.match.params.type ? props.match.params.type.toString() : "0"
    const clinics = JSON.parse(localStorage.getItem('client')).clinics;
    const [selection, setSelection] = useState(type);
    let onSelectionChange = (e) => {
        UserProfile.setHighlights([], 'success')
        setSelection(e.target.value)
    }
    return (
        <div className="container-fluid">
            <div className="section-title" >
                <h4>
                {
                    clinics.map((clinic, key) => {
                        if (clinic.id == clinicId) 
                            return clinic.name
                    })
                }
                </h4>
            </div>
            <div className="form-row align-items-center my-1">
                <div className="col-auto my-1">
                    <select className="form-control" onChange={(e) => onSelectionChange(e)} value={selection}>
                        <option value="3">Timings</option>
                        <option value="4">Resources</option>
                        <option value="6">Services</option>
                        <option value="5">Service Types</option>
                        <option value="1">Staff</option>
                        <option value="2">Staff Availability</option>
                        <option value="9">Do Not Start</option>
                        <option value="8">Do Not Book Patients</option>
                        <option value="7">High Level Filter</option>
                        <option value="10">Max Treatment Start</option>
                    </select>
                </div>
            </div>
            {
                selection === "1" ? <Staff clinicId={clinicId}/> :
                selection === "2" ? <StaffAvailability clinicId={clinicId}/> :
                selection === "3" ? <Timings clinicId={clinicId}/> : 
                selection === "4" ? <ClinicResources clinicId={clinicId}/> : 
                selection === "5" ? <ClinicServiceType clinicId={clinicId}/> : 
                selection === "6" ? <ClinicServices clinicId={clinicId}/> : 
                selection === "7" ? <HighLevelFilter clinicId={clinicId}/> : 
                selection === "8" ? <DonotbookPatients clinicId={clinicId}/> : 
                selection === "9" ? <DonotStart clinicId={clinicId}/> : 
                selection === "10" ? <MaxTreatStart clinicId={clinicId}/> : 
                <Timings clinicId={clinicId}/>
            }
        </div>
    )
}
export default ClinicWrapper