import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment'
import UserProfile from '../UserProfile'
import axios from 'axios'
import history from './../../history';
import ProgressBar from 'react-bootstrap/ProgressBar'
import ProgBar from './ProgressBar'
import authHeader from '../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import ContentEditable from 'react-contenteditable'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import OptimizerContext from './OptimizerContext'
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange } from '../utils/Util'

class ViewSolutionList extends Component {
    static contextType = OptimizerContext
    constructor(props) {
        super(props)
        this.state = {
            numberofColumn: 13,
            numberofcolumnVal: 23,
            unassignedProfileList: [],
            unassignedProfileListnew: [],
            groupunassignedProfile: [],
            unassignedProfileColumnList: [],
            timings: [],
            unassignedProfileserviceColors: [],
            assignedProfileList: [],
            assignedProfileListnew: [],
            groupassignedProfile: [],
            assignedProfileserviceColors: [],
            cost: 0,
            assigncount: 0,
            unassigncount: 0,
            description: '',
            solutionid: 0,
            clinicid: 0,
            fromnum: '',
            tonum: '',
            random: '',
            identifier: '',
            showProgressbar: false,
            serviceUsage: [],
            keepalloptimal: 0,
            onlydecreasingdost: 1,
            deletionProfileId: '',
            deletionShowConfirmation: false,
            showAlert: false,
            alertMessage: '',
            alertVariant: 'success',
            swapstatus: 0,
            showAlertDetail: false,
            alertDetail: '',
            isWhatIf: 0,
            dayScheduleId: 0,
            disableApplyConstraintButton: false,
            resetClinicConfiguration: false,
            isBeingModified: 0,
        }
        this.contentEditable = React.createRef();
    }
    componentDidMount() {
        this.setState({
            clinicid: UserProfile.getClinicId(),
            solutionid: UserProfile.getSelectedId()
        })
        this.getClinicstimingrows()
        this.getsolution(UserProfile.getSelectedId())
        this.getusageReport()
    }
    getsolution(soluID) {
        axios.get('/solution/' + soluID, { headers: authHeader() })
            .then(response => {
                this.setState({
                    description: response.data[0][1] === null ? '' : response.data[0][1],
                    cost: response.data[0][2],
                    assigncount: response.data[0][3],
                    unassigncount: response.data[0][4],
                    isWhatIf: response.data[0][5],
                    dayScheduleId: response.data[0][6],
                    swapstatus: response.data[0][7],
                    resetClinicConfiguration: response.data[0][7] === -100,
                    solutionid: UserProfile.getSelectedId(),
                    isBeingModified: response.data[0][8],
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getListOfAssignedProfilesforSolutionID() {
        let assignList = []
        let assignColor = []
        await axios.get('/assignedprofileslist/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let d = { id: element[0], profileid: element[1], sid: element[2], servicename: element[3], startime: element[4], colspan: element[5] }
                    let dc = { servicename: element[3], color: element[6] }
                    assignList.push(d)

                    if (assignColor.length > 0) {
                        let bfound = assignColor.some(s => s.servicename == element[3])
                        if (!bfound) {
                            assignColor.push(dc)
                        }
                    } else {
                        assignColor.push(dc)
                    }
                }
                this.setState({
                    assignedProfileList: assignList,
                    assignedProfileserviceColors: assignColor
                });
                this.GenerateAssignedProfilesList()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getListOfUNAssignedProfilesforSolutionID() {
        let unassignList = []
        let unassignColor = []
        await axios.get('/unassignedprofileslist/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let d = { id: element[0], profileid: element[1], sid: element[2], servicename: element[3], startime: element[4], colspan: element[5] }
                    let dc = { servicename: element[3], color: element[6] }
                    unassignList.push(d)
                    if (unassignColor.length > 0) {
                        let bfound = unassignColor.some(s => s.servicename == element[3])
                        if (!bfound) {
                            unassignColor.push(dc)
                        }
                    } else {
                        unassignColor.push(dc)
                    }
                }
                this.setState({
                    unassignedProfileList: unassignList,
                    unassignedProfileserviceColors: unassignColor
                });
                this.GenerateUNAssignedProfilesList()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    GenerateUNAssignedProfilesList() {
        let objVal = []
        let j = 3
        let objValArr = []
        let colspanCount = 0
        for (let first = 0; first < this.state.groupunassignedProfile.length; first++) {
            const element = this.state.groupunassignedProfile[first];
            let unnass = this.state.unassignedProfileList.filter(x => x.profileid === element.profileid)
            for (let index = 0; index < unnass.length; index++) {
                let item = unnass[index]
                for (let index1 = 0; index1 < Object.values(item).length; index1++) {
                    if (index1 === 0) {
                        objVal[index1] = Object.values(item)[index1]
                    } else if (index1 === 1) {
                        objVal[index1] = Object.values(item)[index1]
                    } else if (index1 === 2) {
                        objVal[index1] = Object.values(item)[index1]
                    } else {
                        if (Object.keys(item)[index1] === 'colspan') {
                            colspanCount = colspanCount + Object.values(item)[index1]
                        }
                        objVal[j] = Object.values(item)[index1]
                    }
                    j += 1
                }
            }
            if (colspanCount === this.state.numberofColumn - 3) {
                objValArr[first] = objVal
                objVal = [this.state.numberofcolumnVal]
                j = 0
                colspanCount = 0
            } else {
                for (let index = colspanCount + 1; index <= this.state.numberofColumn - 3; index++) {
                    objVal[j] = "<td></td>"
                    j += 1
                }
                objValArr[first] = objVal
                objVal = [this.state.numberofcolumnVal]
                j = 0
                colspanCount = 0
            }
        }
        let index3 = 0
        let dd = ''
        let first = ''
        let second = ''
        let third = ''
        let td = ''
        let updatedTablerow = []
        let timingfound = false
        let buildtd = ''

        for (let index1 = 0; index1 < Object.values(objValArr).length; index1++) {
            let item = Object.values(objValArr)[index1]
            first = '<td>' + Object.values(item)[0] + '</td>'
            second = '<td>' + Object.values(item)[1] + '</td>'
            third = '<td>' + Object.values(item)[2] + '</td>'

            for (index3 = 0; index3 < this.state.timings.length; index3++) {
                const element = this.state.timings[index3];
                for (let index2 = 0; index2 < Object.values(item).length; index2++) {
                    if (element.t === Object.values(item)[index2]) {
                        let col = this.state.unassignedProfileserviceColors.filter(x => x.servicename === Object.values(item)[index2 - 1]).map(d => { return (d.color) })
                        var r = 'background-color:' + col + ';color:white'
                        dd = dd + "<td style=" + r + " colspan=" + Object.values(item)[index2 + 1] + '>' + Object.values(item)[index2 - 1] + '</td>'
                        timingfound = true
                        if (Object.values(item)[index2 + 1] > 1) {
                            index3 = index3 + Object.values(item)[index2 + 1]
                        }
                    }
                }

                if (timingfound == false) {
                    td = "<td></td>"
                }
                if (dd.length > 0) {
                    buildtd = buildtd + dd
                    dd = ''
                    timingfound = false
                    td = ''
                }
                if (td.length > 0) {
                    buildtd = buildtd + td
                    td = ''
                }
            }
            updatedTablerow[index1] = '<tr>' + first + second + third + buildtd + '</tr>'
            dd = ''
            td = ''
            timingfound = false
            buildtd = ''
        }
        this.setState({
            unassignedProfileListnew: updatedTablerow
        })
        this.getListOfAssignedProfileIDsforSolutionID()
    }
    GenerateAssignedProfilesList() {
        let objVal = []
        let j = 3
        let objValArr = []
        let colspanCount = 0
        for (let first = 0; first < this.state.groupassignedProfile.length; first++) {
            const element = this.state.groupassignedProfile[first];
            let unnass = this.state.assignedProfileList.filter(x => x.profileid === element.profileid)
            for (let index = 0; index < unnass.length; index++) {
                let item = unnass[index]
                for (let index1 = 0; index1 < Object.values(item).length; index1++) {
                    if (index1 === 0) {
                        objVal[index1] = Object.values(item)[index1]
                    } else if (index1 === 1) {
                        objVal[index1] = Object.values(item)[index1]
                    } else if (index1 === 2) {
                        objVal[index1] = Object.values(item)[index1]
                    } else {
                        if (Object.keys(item)[index1] === 'colspan') {
                            colspanCount = colspanCount + Object.values(item)[index1]
                        }
                        objVal[j] = Object.values(item)[index1]
                    }
                    j += 1
                }
            }
            if (colspanCount === this.state.numberofColumn - 3) {
                objValArr[first] = objVal
                objVal = [this.state.numberofcolumnVal]
                j = 0
                colspanCount = 0
            } else {
                for (let index = colspanCount + 1; index <= this.state.numberofColumn - 3; index++) {
                    objVal[j] = "<td></td>"
                    j += 1
                }
                objValArr[first] = objVal
                objVal = [this.state.numberofcolumnVal]
                j = 0
                colspanCount = 0
            }
        }
        let index3 = 0
        let dd = ''
        let first = ''
        let second = ''
        let third = ''
        let td = ''
        let updatedTablerow = []
        let timingfound = false
        let buildtd = ''
        for (let index1 = 0; index1 < Object.values(objValArr).length; index1++) {
            let item = Object.values(objValArr)[index1]
            first = '<th>' + Object.values(item)[0] + '</th>'
            second = '<td>' + Object.values(item)[1] + '</td>'
            third = '<td>' + Object.values(item)[2] + '</td>'

            for (index3 = 0; index3 < this.state.timings.length; index3++) {
                const element = this.state.timings[index3];
                for (let index2 = 0; index2 < Object.values(item).length; index2++) {
                    if (element.t === Object.values(item)[index2]) {
                        let col = this.state.assignedProfileserviceColors.filter(x => x.servicename === Object.values(item)[index2 - 1]).map(d => { return (d.color) })
                        var r = 'background-color:' + col + ';color:white'
                        dd = dd + "<td style=" + r + " colspan=" + Object.values(item)[index2 + 1] + '>' + Object.values(item)[index2 - 1] + '</td>'
                        timingfound = true
                        if (Object.values(item)[index2 + 1] > 1) {
                            index3 = index3 + Object.values(item)[index2 + 1] - 1
                        }
                    }
                }
                if (timingfound == false) {
                    td = "<td></td>"
                }
                if (dd.length > 0) {
                    buildtd = buildtd + dd
                    dd = ''
                    timingfound = false
                    td = ''
                }
                if (td.length > 0) {
                    buildtd = buildtd + td
                    td = ''
                }
            }
            updatedTablerow[index1] = '<tr>' + first + second + third + buildtd + '</tr>'
            dd = ''
            td = ''
            timingfound = false
            buildtd = ''
        }
        this.setState({
            assignedProfileListnew: updatedTablerow
        })
    }
    async getListOfAssignedProfileIDsforSolutionID() {
        let groupassignList = []
        await axios.get('/groupassignedprofiles/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let d = { profileid: element }
                    groupassignList.push(d)
                }
                this.setState({
                    groupassignedProfile: groupassignList
                });
                this.getListOfAssignedProfilesforSolutionID()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getListOfUNAssignedProfileIDsforSolutionID() {
        let groupunassignList = []
        await axios.get('/groupunassignedprofiles/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];
                    let d = { profileid: element }
                    groupunassignList.push(d)
                }
                this.setState({
                    groupunassignedProfile: groupunassignList
                });
                this.getListOfUNAssignedProfilesforSolutionID()
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getClinicstimingrows() {
        let timet = []
        let selectname = UserProfile.getSelectedName().split(",")
        await axios.get('/clinicstimingfordayrows/' + selectname[0] + '/' + selectname[1], { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    let starttime = response.data[0][0]
                    let timeint = response.data[0][2]
                    let cols = response.data[0][4]
                    for (let index = 0; index < response.data[0][4]; index++) {
                        let d = { t: starttime }
                        timet.push(d)
                        starttime = moment(starttime, 'HH:mm')
                            .add(timeint, 'minutes')
                            .format('HH:mm:ss');
                    }
                    this.setState({
                        timings: timet,
                        numberofColumn: cols + 3,
                        numberofcolumnVal: (cols * 2) + 3
                    });
                    this.getListOfUNAssignedProfileIDsforSolutionID()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    async getusageReport() {
        let usageReportList = []
        await axios.get('/getusageReport/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                for (let index1 = 0; index1 < this.state.timings.length; index1++) {
                    const element1 = this.state.timings[index1];
                    for (let index = 0; index < response.data.length; index++) {
                        const element = response.data[index];
                        if (element1.t === element[0]) {
                            let d = { t: element[0], servicename: element[6], percentage: element[3], color: element[5], chairsUsage: element[7], staffUsage: element[8] }
                            usageReportList.push(d)
                        }
                    }
                }
                this.setState({
                    serviceUsage: usageReportList,
                });
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    ViewBreakdown() {
        if (this.state.isWhatIf && this.state.swapstatus === -100) {
            this.props.history.push({
                pathname: '/viewbreakdown',
                data: {
                    dayScheduleId: this.state.dayScheduleId,
                }
            })
        } else {
            axios.get('/cloneSolution/' + this.state.solutionid, { headers: authHeader() })
                .then(response => {
                    this.props.history.push({
                        pathname: '/viewbreakdown',
                        data: {
                            dayScheduleId: response.data[0],
                        }
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }
    }
    handleChange = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        const type = target.type
        const checked = target.checked
        let ident = ''
        if (field == 'random') {
            if (value > 0) {
                ident = 'GFF on ' + moment().format('YYYY-MM-DD HH:mm:ss') + ' with random swap: ' + value
                this.setState({
                    fromnum: '',
                    tonum: '',
                });
            }
        }
        type == "checkbox" ?
            this.setState({
                [field]: checked ? 1 : 0,
            })
            :
            this.setState({
                [field]: value,
                identifier: ident,
            });
    }
    handleChangeIdentifier = (event) => {
        const target = event.target;
        const field = target.name;
        const value = target.value
        this.setState({
            [field]: value,
        });
    }
    checkSwap() {
        if (this.state.fromnum.trim() == '' || this.state.tonum.trim() == '') {
            alert('Enter swap value(s)')
        } else {
            let SelectedName = UserProfile.getSelectedName().split(',')
            axios.get('/checkProfilesSwap/' + this.state.clinicid + '/' + this.state.solutionid + '/' + 0 + '/' + this.state.fromnum + '/' + this.state.tonum + '/' + SelectedName[1], { headers: authHeader() })
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            identifier: 'GFF on ' + moment().format('YYYY-MM-DD HH:mm:ss') + ' with manual swap from P(' + this.state.fromnum + ') to ' + this.state.tonum,
                            showAlert: true,
                            alertMessage: response.data[0][0] === 1 ? 'Valid Move' : 'Invalid Move: ' + response.data[0][2],
                            alertVariant: response.data[0][0] === 1 ? 'success' : 'danger',
                            alertDetail: response.data[0][3]
                        })
                        UserProfile.setSelectedId(response.data[0][1])
                        this.getClinicstimingrows()
                        this.getsolution(response.data[0][1])
                        this.getusageReport()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }
    }
    calculateCost() {
        if (this.state.random > 0 || this.state.isBeingModified === 1) {
            const { optimizerContext } = this.context
            optimizerContext.enableTimer();
            this.setState({
                showProgressbar: true,
            })
            let SelectedName = UserProfile.getSelectedName().split(',')
            let swaptype = 0
            let fromnum = 0
            let tonum = 0

            if (this.state.random > 0) {
                swaptype = 1
                fromnum = this.state.random
            }

            axios.get('/calculatecostswap/' + this.state.solutionid + '/' + SelectedName[1] + '/' + this.state.identifier + '/' + swaptype + '/' + fromnum + '/' + tonum + '/' + this.state.keepalloptimal + '/' + this.state.onlydecreasingdost, { headers: authHeader() })
                .then(response => {
                    if (response.data.length > 0) {
                        try {
                            if (response.data[0] > 0)
                                UserProfile.setHighlights(response.data, 'success')
                            else
                                UserProfile.setHighlights([this.state.solutionid], 'info')
                        } catch (e) {
                            console.log(e)
                            UserProfile.setHighlights([this.state.solutionid], 'info')
                        }
                        UserProfile.setSelectedId(this.state.solutionid)
                        history.push('/solutionlist')
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        } else {
            alert('No change has been made in Profiles to calculate score')
        }
    }
    findSlots() {
        const { optimizerContext } = this.context
        optimizerContext.enableTimer();
        this.setState({ disableApplyConstraintButton: true })
        if (this.state.resetClinicConfiguration) {
            axios.get('/findslotsfresh/' + this.state.solutionid, { headers: authHeader() })
                .then(response => {
                    if (response.data === 'CANCELLED') {
                        history.push('/scheduler')
                    } else if (response.data === 'STOPPED') {
                        history.push('/scheduler')
                    } else {
                        this.getClinicstimingrows()
                        this.getsolution(UserProfile.getSelectedId())
                        this.getusageReport(UserProfile.getSelectedId())
                        this.setState({
                            showAlert: true,
                            alertDetail: '',
                            alertMessage: 'Apply Constraints/Generate Solution completed.',
                            alertVariant: 'success',
                            disableApplyConstraintButton: false,
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        } else {
            axios.get('/findslots/' + this.state.solutionid, { headers: authHeader() })
                .then(response => {
                    if (response.data === 'CANCELLED') {
                        history.push('/scheduler')
                    } else if (response.data === 'STOPPED') {
                        history.push('/scheduler')
                    } else {
                        this.getClinicstimingrows()
                        this.getsolution(UserProfile.getSelectedId())
                        this.getusageReport(UserProfile.getSelectedId())
                        this.setState({
                            showAlert: true,
                            alertDetail: '',
                            alertMessage: 'Assign unassigned completed.',
                            alertVariant: 'success',
                            disableApplyConstraintButton: false,
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }

    }
    handleInlineChange = evt => {
        this.setState({ description: evt.target.value });
    };
    showAlertDetail() {
        this.setState({ showAlertDetail: !this.state.showAlertDetail });
    }
    handleInlineBlur = evt => {
        axios.put('/solutionsummaryidentifier/' + this.state.solutionid, this.state.description, { headers: authHeader() })
            .then(response => {
                //do nothing
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    };
    preDeleteProfile() {
        if (this.state.deletionProfileId === '')
            alert('Please enter a Profile Id to delete')
        else
            this.setState({ deletionShowConfirmation: true });
    }
    handleWarningClose() {
        this.setState({ deletionShowConfirmation: false });
    }
    handleConfirmedDelete() {
        let SelectedName = UserProfile.getSelectedName().split(',')
        axios.get('/removeprofilesequence/' + this.state.clinicid + '/' + this.state.solutionid + '/' + this.state.deletionProfileId + '/' + SelectedName[1], { headers: authHeader() })
            .then(response => {
                if (response.data.length > 0) {
                    this.setState({
                        identifier: 'GFF on ' + moment().format('YYYY-MM-DD HH:mm:ss') + ' with P(' + this.state.deletionProfileId + ') deletion ',
                        deletionShowConfirmation: false,
                        showAlert: true,
                        alertDetail: '',
                        alertMessage: 'Profile ' + this.state.deletionProfileId + ' is deleted.',
                        alertVariant: 'success',
                        deletionProfileId: '',
                    })
                    UserProfile.setSelectedId(response.data[0][1])
                    this.getClinicstimingrows()
                    this.getsolution(response.data[0][1])
                    this.getusageReport()
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    BackClickHandler(e) {
        UserProfile.setHighlights([UserProfile.getNavParams().selectedId], 'info')
        history.push(UserProfile.getNavigatedFrom())
    }
    render() {
        const { optimizerContext } = this.context
        return (
            <div><p></p>
                <ReactTooltip />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="section-title">
                                <h4>{this.state.isWhatIf ? 'What-If Solution' : 'Optimized Solution'} <small className="text-muted">{UserProfile.getClinicName()} [{moment(UserProfile.getScheduleDate()).format("DD/MM/YYYY")} {moment(UserProfile.getScheduleDate()).format('dddd')}]</small></h4>
                            </div>
                        </div>
                        <div className="col">
                            <div className="float-right">
                                <button type="submit" className={this.state.isWhatIf ? 'btn btn-primary btn-sm mr-2' : 'd-none'} align="right" onClick={this.ViewBreakdown.bind(this)} data-tip="View Breakdown">View Breakdown</button>
                                <button className="btn btn-sm btn-secondary" data-tip="Go Back" onClick={this.BackClickHandler.bind(this)}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Alert show={this.state.showAlert} variant={this.state.alertVariant} onClose={() => this.setState({ showAlert: false })} dismissible>
                    <p>{this.state.alertMessage}</p>
                    <a href="#" style={{ display: (this.state.alertDetail.length > 0 ? 'inline-block' : 'none') }} onClick={() => this.showAlertDetail()}>Toggle Possible Constraints</a>
                    <div style={{ display: (this.state.showAlertDetail ? 'block' : 'none') }}>{this.state.alertDetail}</div>
                </Alert>
                <div style={{ display: (this.state.showProgressbar && !optimizerContext.isRunning ? 'block' : 'none') }}>
                    <ProgressBar striped variant="info" animated now={100} label={'Please wait...'} className="form-check-label font-weight-bold" />
                </div>
                <div className="alert alert-info" role="alert" style={{ "marginBottom": 0 + 'px' }}>
                    <div className="row" role="alert">
                        <div className="col col-5" >
                            <ContentEditable
                                innerRef={this.contentEditable}
                                html={this.state.description}
                                disabled={false}
                                onChange={this.handleInlineChange}
                                tagName='article'
                                onBlur={this.handleInlineBlur}
                            />
                        </div>
                        <div className="col col-3">
                            <a href="#assigned">Assigned Profiles:{this.state.assigncount}</a>
                        </div>
                        <div className="col col-2">
                            <a href="#unassigned">Unassigned Profiles:{this.state.unassigncount}</a>
                        </div>
                        <div className={this.state.isWhatIf ? 'd-none' : 'col-2 text-center'}>
                            <b>Score: {this.state.cost}</b>
                        </div>
                    </div>
                </div>
                <div className="alert alert-secondary form-row form-inline" role="alert" style={{ "marginBottom": 0 + 'px', "display": this.state.isWhatIf ? 'none' : 'block' }}>
                    <div className="col-md-12 form-inline">
                        <label className="form-check-label font-weight-bold mx-1">Swap &#62;&#62; Manual:</label>
                        <input type="number"
                            name="fromnum"
                            value={this.state.fromnum}
                            onChange={this.handleChange}
                            style={{ width: '100px' }}
                            id="fromnum" className="form-control mx-1" placeholder="Enter #" maxLength="3" />
                        <InputMask name="tonum" value={this.state.tonum} style={{ width: '80px' }}
                            onChange={this.handleChange} className="form-control form-group mx-1"
                            placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                        <button type="button" className="btn btn-primary mx-1" onClick={() => this.checkSwap()} data-tip="Move" disabled={optimizerContext.isRunning}>Move</button>

                        <label className="form-check-label font-weight-bold mx-1" htmlFor="random">(OR) Random:</label>
                        <input type="text"
                            name="random"
                            value={this.state.random}
                            onChange={this.handleChange}
                            id="random" className="form-control form-group ml-1 mr-3" placeholder="Number of random swaps" pattern="[0-9]*" maxLength="7" />
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="keepalloptimal"
                                value={this.state.keepalloptimal}
                                onChange={this.handleChange}
                                disabled={true} //cmr08
                                id="keepalloptimal" />
                            <label className="form-check-label" htmlFor="keepalloptimal">Keep Top 5 Score</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="checkbox"
                                name="onlydecreasingdost"
                                value={this.state.onlydecreasingdost}
                                checked={this.state.onlydecreasingdost}
                                onChange={this.handleChange}
                                id="onlydecreasingdost" />
                            <label className="form-check-label" htmlFor="onlydecreasingdost">Only Decreasing Score</label>
                        </div>
                    </div>
                </div>
                <div className="alert alert-secondary" role="alert">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-inline">
                                <label className="form-check-label font-weight-bold mx-1" htmlFor="deletionProfileId">Delete Profile:</label>
                                <input type="text"
                                    name="deletionProfileId"
                                    value={this.state.deletionProfileId}
                                    onChange={this.handleChange}
                                    id="deletionProfileId" className="form-control col-md-2 form-group mx-1" placeholder="Enter #" maxLength="3" />
                                <button type="button" className="btn btn-danger mx-1" onClick={() => this.preDeleteProfile()} data-tip="Delete Profile" disabled={optimizerContext.isRunning}>Delete Profile</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="float-right">
                                <div className={this.state.isWhatIf ? "d-none" : "form-inline font-weight-bolder"}>
                                    <label htmlFor="identifier" className="mx-1">Identifier:</label>
                                    <input type="text"
                                        name="identifier"
                                        value={this.state.identifier}
                                        onChange={this.handleChangeIdentifier}
                                        style={{ minWidth: 300 + 'px' }}
                                        id="identifier" className="form-control mx-1" placeholder="Identifier of new solution" maxLength="100" />
                                    <button type="button" className="btn btn-primary mx-1" onClick={() => this.calculateCost()} data-tip="Calculate Score" disabled={optimizerContext.isRunning}>Calculate Score</button>
                                </div>
                                <div className={this.state.isWhatIf ? "form-inline font-weight-bolder" : "d-none"}>
                                    <input className="form-check-input" type="checkbox" value={this.state.resetClinicConfiguration}
                                        onChange={this.handleChange} checked={this.state.resetClinicConfiguration} name="resetClinicConfiguration" id="resetClinicConfiguration" />
                                    <label className="form-check-label" htmlFor="resetClinicConfiguration" data-tip="Reset Clinic Configurations for this Solution">Reset Clinic Configuration </label>
                                    <button type="button" className="btn btn-primary mx-1" onClick={() => this.findSlots()} data-tip={this.state.resetClinicConfiguration ? "Apply constraints for original & assigned profiles and Find slots for unassigned (added) profiles" : "Assign Unassigned Profiles for this Solution"} disabled={this.state.disableApplyConstraintButton}>
                                        {this.state.resetClinicConfiguration ? "Apply Constraints & Generate Solution" : "Assign Unassigned Profiles"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="h-scroll-container unassigned-container" id="unassigned">
                    <Table className="table table-striped table-sm table-bordered table-hover">
                        <thead className="alert-danger">
                            <tr>
                                <th scope="col" colSpan={this.state.numberofColumn}>Unassigned Profiles:</th>
                            </tr>
                        </thead>
                        <thead className="thread"><tr>
                            <th scope="col">#</th>
                            <th scope="col" data-sortable="true">Profile ID</th>
                            <th scope="col" data-sortable="true">SID</th>
                            {
                                this.state.timings.length > 0 && this.state.timings.map((item, key) => {
                                    return (
                                        <th key={key}>{moment(item.t, 'HH:mm').format('HH:mm')}</th>
                                    )
                                })
                            }
                        </tr></thead>
                        <tbody>
                            {
                                this.state.unassignedProfileListnew.length == 0 ? <tr><td colSpan={this.state.numberofColumn}></td></tr>
                                    :
                                    this.state.unassignedProfileListnew.map((item, key) => {
                                        return (
                                            ReactHtmlParser(item)
                                        )
                                    })
                            }
                            <tr>
                                <td colSpan={this.state.numberofColumn} className="alert-secondary"></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className="h-scroll-container assigned-container">
                    <Table className="table table-striped table-sm table-bordered table-hover">
                        <thead className="alert-warning" id="assigned">
                            <tr>
                                <th scope="col" colSpan='3'>Assigned Profiles:</th>
                                {
                                    this.state.timings.length > 0 && this.state.timings.map((item, key) => {
                                        let ss = item
                                        return (
                                            <th key={key}>{
                                                this.state.serviceUsage.length > 0 && this.state.serviceUsage.map((item1, key) => {
                                                    if (ss.t === item1.t) {
                                                        return (
                                                            <ProgBar key={key} bgcolor={item1.color} completed={Math.round(item1.percentage)} title={item1.servicename} percentage={Math.round(item1.percentage)} chairsUsage={item1.chairsUsage} staffUsage={item1.staffUsage} />
                                                        )
                                                    }
                                                })
                                            }</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <thead className="thread"><tr>
                            <th scope="col">#</th>
                            <th scope="col" data-sortable="true">Profile ID</th>
                            <th scope="col" data-sortable="true">SID</th>
                            {

                                this.state.timings.length > 0 && this.state.timings.map((item, key) => {

                                    return (
                                        <th key={key}>{moment(item.t, 'HH:mm').format('HH:mm')}</th>
                                    )
                                })

                            }
                        </tr></thead>
                        <tbody>
                            {
                                this.state.assignedProfileListnew.length > 0 && this.state.assignedProfileListnew.map((item, key) => {

                                    return (
                                        ReactHtmlParser(item)
                                    )
                                })

                            }
                        </tbody>
                    </Table>
                </div>
                <div className="container-fluid">
                    <div className="row"><div className="col"><div className="float-right" style={{ "marginBottom": 5 + "px" }}>
                        <button type="submit" className="btn btn-primary btn-sm mr-1" align="right" onClick={this.ViewBreakdown.bind(this)} data-tip="View Breakdown">View Breakdown</button>
                        <button className="btn btn-sm btn-secondary" data-tip="Go Back" onClick={this.BackClickHandler.bind(this)}>Back</button>
                    </div></div></div>
                </div>
                <Modal show={this.state.deletionShowConfirmation} onHide={this.handleWarningClose.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                        <Modal.Title>Delete Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete the profile <b>{this.state.deletionProfileId}</b>?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" data-tip="Yes" onClick={this.handleConfirmedDelete.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="secondary" data-tip="No" onClick={this.handleWarningClose.bind(this)}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ViewSolutionList
