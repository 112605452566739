import React from 'react';
import './App.css';
import 'react-notifications-component/dist/theme.css'
import Navigation from './components/Navbar';
import Routes from './Routes';
import history from './history';
import { Router} from "react-router-dom";
import Footer from './components/Footer'
import UserProfile from './components/UserProfile'
import axios from 'axios'
import { OptimizerContextProvider } from './components/scheduler/OptimizerContext'
import { Helmet } from 'react-helmet';
import { ReactNotifications } from 'react-notifications-component'
axios.defaults.baseURL=window['runConfig'].apiUrl

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>{window['runConfig'].title}</title>
      </Helmet>
      <div className="app-container">
        <Router history={history} >
          <OptimizerContextProvider>
            <ReactNotifications />
            <Navigation UserProfile={UserProfile}/>
            <Routes/>
          </OptimizerContextProvider>
        </Router>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
