import React from 'react';
import './Navbar.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { withRouter } from 'react-router-dom';
import OptimizerAlert from '../components/scheduler/OptimizerAlert';
import UserProfile from './UserProfile'

import "react-bootstrap-submenu/dist/index.css"

const Navigation = (props) => {
    const organizations = UserProfile.getGlobalClientParams()?.organizations
    const clients = UserProfile.getGlobalClientParams()?.clients
    const clinics = UserProfile.getGlobalClientParams()?.clinics
    const allClinics = UserProfile.getGlobalClientParams()?.allClinics
    const shortName = UserProfile.getGlobalClientParams()?.shortName
    return (
        <div>
            <Navbar bg="primary" variant="dark" id="navbar" >
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/home">Home</Nav.Link>
                        <Nav.Link href="/scheduler">Scheduler</Nav.Link>
                        <NavDropdownMenu title={shortName} id="collasible-nav-dropdown">
                            {
                                organizations?.map((organization, key) => {
                                    return (
                                        clients?.filter(e => e.parentId === organization.id)?.length > 0
                                            ? <DropdownSubmenu href="#" key={key} title={`${organization.name} (${clients?.filter(e => e.parentId === organization.id).length})`}>
                                                {
                                                    clients?.map((client, key) => {
                                                        return (
                                                            allClinics?.filter(e => e.parentId === client.id && e.activeStatus === 'Y').length > 0 && client.parentId === organization.id
                                                                ? <NavDropdown.Item key={key} href={'/switchclient/' + client.id}>{`${client.name} (${allClinics?.filter(e => e.parentId === client.id && e.activeStatus === 'Y')?.length})`}</NavDropdown.Item>
                                                                : client.parentId === organization.id
                                                                    ? <NavDropdown.Item key={key} href='#' disabled>{`${client.name} (${allClinics?.filter(e => e.parentId === client.id && e.activeStatus === 'Y')?.length})`}</NavDropdown.Item>
                                                                    : null
                                                        )
                                                    })
                                                }
                                            </DropdownSubmenu>
                                            : <NavDropdown.Item key={key} href='#' disabled>{`${organization.name} (${clients?.filter(e => e.parentId === organization.id).length})`}</NavDropdown.Item>
                                    )
                                })
                            }
                        </NavDropdownMenu>
                        <NavDropdownMenu title="Clinics" id="collasible-nav-dropdown">
                            {
                                clinics?.map((clinic, key) => {
                                    return (
                                        <NavDropdown.Item href={"/clinic/" + clinic.id} key={key}>{clinic.name}</NavDropdown.Item>
                                    )
                                })
                            }
                        </NavDropdownMenu>
                        <Nav.Link href="/standardcode">Standard Codes</Nav.Link>
                        <Nav.Link href="/">Logout</Nav.Link>
                    </Nav>
                    <Navbar.Text className={window['runConfig'].ribbon.class} style={window['runConfig'].ribbon.style}>
                        {window['runConfig'].ribbon.text}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
            <OptimizerAlert />
        </div>
    )
}

export default withRouter(Navigation);
