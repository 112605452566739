import React, { Component, Fragment } from "react";
import UserProfile from '../UserProfile'
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import history from './../../history';
import axios from 'axios'
import authHeader from '../authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import OptimizerContext from './OptimizerContext'
import ProgressBar from 'react-bootstrap/ProgressBar'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../utils/ocsvalidators'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

class ViewBreakdown extends Component {
    static contextType = OptimizerContext
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
        this.state = {
            ViewBreakdownList: [],
            showGlobal: true,
            showGroup: false,
            durationOpt: 'Global',
            subtable: 0,
            subtableflag: false,
            solutionid: 0,
            showProgressbar: false,
            gffSuccessMessage: '',
            scheduleDate: '',
            vbSummaryList: [],
            vbSummaryListBak: [],
            vbBucketList: [],
            distinctTimeCount: 0,
            whatIfType: 'Increase',
            whatIfBucket: 'All Buckets',
            whatIfPercent: 0,
            showApplyModal: false,
            showResetModal: false,
            showSpinner: true,
            resetEnabled: false,
            showWhatIfSpinner: false,
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
        this.handleChange = this.handleChange.bind(this)
    }
    controller = new AbortController();
    isMounting = true;
    componentDidMount() {
        const { data } = this.props.location;
        if (data !== undefined) { UserProfile.setSelectedId(data.dayScheduleId) }
        this.setState({
            ViewBreakdownList: [],
            showList: false,
            vbSummaryList: [],
            vbBucketList: [],
            showSpinner: true,
        });
        axios.get('/getdistinctstarttime/' + UserProfile.getSelectedId(), { headers: authHeader(), signal: this.controller.signal })
            .then(response => {
                axios.get('/clinics/' + UserProfile.getClinicId(), { headers: authHeader() })
                    .then(cresponse => {
                        if (this.isMounting) {
                            this.setState({
                                oncoSmartClinicId: cresponse.data.oncosmartSystemId,
                                distinctTimeCount: response.data.length,
                                scheduleDate: UserProfile.getScheduleDate()
                            });
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
        if (data === undefined) {
            axios.get('/getviewbreakdown/' + UserProfile.getSelectedId(), { headers: authHeader() })
                .then(response => {
                    if (this.isMounting) {
                        this.prepareUI(response, '');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        } else {
            axios.get('/solutionForViewBreakdown/' + UserProfile.getSelectedId(), { headers: authHeader() })
                .then(response => {
                    if (this.isMounting) {
                        this.prepareUI(response, '');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    history.push('/Errorpage')
                })
        }
    }
    componentWillUnmount() {
        this.controller.abort();
        this.isMounting = false;
    }
    prepareUI = (response, incdec) => {
        let vbList = []
        let vbSummaryList = []
        let vbBucketList = []
        let soluid = 0

        for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            soluid = element[0]
            let d = { id: index + 1, pid: element[1], name: element[2], time: element[3] }
            vbList.push(d)
            const services = element[2].split(',')
            let newBucket = ''
            let newParentBucket = ''
            services.forEach((e) => {
                if (newBucket.length > 0) newBucket += ', '
                if (newParentBucket.length > 0) newParentBucket += ', '
                if (e.includes('TTCNP')) {
                    newBucket += e.replace(/\s+/g, ' ').replaceAll(' ,', ',').replace('[:]','').trim()
                    newParentBucket += e.replace(/[0-9]/g, '').replace(/\s+/g, ' ').replace(/min/g, '').replaceAll(' ,', ',').replace('[:]', '').trim()
                } else {
                    newBucket += e.replace(/[0-9]/g, '').replace(/\s+/g, ' ').replace(/min/g, '').replaceAll(' ,', ',').replace('[:]', '').trim()
                    newParentBucket += e.replace(/[0-9]/g, '').replace(/\s+/g, ' ').replace(/min/g, '').replaceAll(' ,', ',').replace('[:]', '').trim()
                }
            })

            if (newParentBucket.includes('TTCNP')) {
                if (vbSummaryList.find(s => s.bucket === newParentBucket)) {
                    for (const o of vbSummaryList) {
                        if (o.bucket === newParentBucket) {
                            o.count = o.count + 1
                            break
                        }
                    }
                } else {
                    vbSummaryList.push({ bucket: newParentBucket, count: 1, isParent: newBucket.includes('TTCNP'), parent: '', class: '', expcol: '+', firstSort: newParentBucket, secondSort: 0 })
                }
            }

            if (vbSummaryList.find(s => s.bucket === newBucket)) {
                for (const o of vbSummaryList) {
                    if (o.bucket === newBucket) {
                        o.count = o.count + 1
                        break
                    }
                }
            } else {
                vbSummaryList.push({ bucket: newBucket, count: 1, isParent: false, parent: newBucket.includes('TTCNP') ? newParentBucket.replaceAll(' ', '').replaceAll(',', '') : '', class: newBucket.includes('TTCNP') ? 'd-none' : '', expcol: '', firstSort: newParentBucket, secondSort: newBucket.includes('TTCNP') ? newBucket.match(/\d+/)[0] * 1 : 0 })
            }
        }
        vbSummaryList.sort((a, b) => a.firstSort.localeCompare(b.firstSort) || a.secondSort - b.secondSort)
        vbSummaryList.forEach((s) => {
            if (s.parent === '') {
                const t = s.bucket.split(',')
                t.forEach((e) => {
                    if (vbBucketList.find(s => s.bucket === e.trim())) {
                        for (const o of vbBucketList) {
                            if (o.bucket === e.trim()) {
                                o.count = o.count + 1
                                break
                            }
                        }
                    } else {
                        vbBucketList.push({ bucket: e.trim(), count: 1 })
                    }
                })
            }
        })
        let vbSummaryListCurrent = []
        if (this.state.whatIfBucket === 'All Buckets') {
            vbSummaryListCurrent = vbSummaryList
        } else {
            vbSummaryList.forEach((s) => {
                if (s.bucket.includes(this.state.whatIfBucket)) {
                    vbSummaryListCurrent.push(s)
                }
            })
        }
        if (incdec.length > 0) {
            if (incdec === 'inc') {
                this.setState({
                    ViewBreakdownList: vbList,
                    solutionid: soluid,
                    showList: true,
                    vbSummaryList: vbSummaryListCurrent,
                    vbSummaryListBak: vbSummaryList,
                    vbBucketList: vbBucketList,
                    showSpinner: false,
                    showApplyModal: false, showResetModal: false, resetEnabled: true
                });
            } else {
                this.setState({
                    ViewBreakdownList: vbList,
                    solutionid: soluid,
                    showList: true,
                    vbSummaryList: vbSummaryListCurrent,
                    vbSummaryListBak: vbSummaryList,
                    vbBucketList: vbBucketList,
                    showSpinner: false,
                    showApplyModal: false, showResetModal: false, resetEnabled: false
                });
            }
        } else {
            this.setState({
                ViewBreakdownList: vbList,
                solutionid: soluid,
                showList: true,
                vbSummaryList: vbSummaryListCurrent,
                vbSummaryListBak: vbSummaryList,
                vbBucketList: vbBucketList,
                showSpinner: false,
                showApplyModal: false, showResetModal: false, resetEnabled: false
            });
        }
    }
    handleChange = (event) => {
        if (event.target.type === 'radio') {
            if (event.target.value === 'Global') {
                this.setState({
                    showGlobal: true,
                    showGroup: false,
                    durationOpt: event.target.value,
                })
            } else {
                this.setState({
                    showGlobal: false,
                    showGroup: true,
                    durationOpt: event.target.value,
                })
            }
        } else if (event.target.name === 'whatIfBucket') {
            let vbSummaryListNew = []
            if (event.target.value === 'All Buckets') {
                vbSummaryListNew = this.state.vbSummaryListBak
            } else {
                this.state.vbSummaryListBak.forEach((s) => {
                    if (s.bucket.includes(event.target.value)) {
                        vbSummaryListNew.push(s)
                    }
                })
            }
            this.setState({
                vbSummaryList: vbSummaryListNew,
                [event.target.name]: event.target.value
            });
        } else {
            this.setState({
                [event.target.name]: event.target.value
            });
        }
    }
    toggle(id, status) {
        this.setState((state) => ({ subtable: state.subtable === id ? 0 : id }));
    }
    GenerateSolution() {
        var tabulator = this.tableRef.current.table;
        let index = 1

        const { optimizerContext } = this.context
        optimizerContext.enableTimer();
        this.setState({
            showProgressbar: true
        })

        var sortedField = ''
        var sorted = ''
        var sortedVal = []
        for (let index = 0; index < tabulator.getSorters().length; index++) {
            const element = tabulator.getSorters()[index];
            sortedField = Object.values(element)[1]
            sorted = Object.values(element)[2]
        }

        if (sorted === 'desc') {
            sortedVal = this.state.ViewBreakdownList.sort(function (obj1, obj2) {
                if (sortedField === 'id') {
                    return obj2.id - obj1.id;
                } else if (sortedField === 'pid') {
                    return obj2.pid - obj1.pid;
                } else if (sortedField === 'name') {
                    return obj2.name - obj1.name;
                } else {
                    return obj2.time - obj1.time;
                }
            });
        } else {
            sortedVal = this.state.ViewBreakdownList.sort(function (obj2, obj1) {
                if (sortedField === 'id') {
                    return obj2.id - obj1.id;
                } else if (sortedField === 'pid') {
                    return obj2.pid - obj1.pid;
                } else if (sortedField === 'name') {
                    return obj2.name - obj1.name;
                } else {
                    return obj2.time - obj1.time;
                }
            });
        }

        let updatedbreakdownlist = []
        sortedVal.forEach(element => {
            var rowData = element
            let d = { id: index, solutionid: this.state.solutionid, pid: rowData.pid }
            updatedbreakdownlist.push(d)
            index = index + 1
        });

        axios.post('/updatesolutionassignment/' + UserProfile.getSelectedName(), updatedbreakdownlist, { headers: authHeader() })
            .then(response => {
                if (response.data === 'DONE') {
                    UserProfile.setHighlights([this.state.solutionid], 'success')
                    UserProfile.setSelectedId(this.state.solutionid)
                    const { optimizerContext } = this.context
                    this.props.history.push({
                        pathname: '/solutionlist',
                        data: {
                            gffResponse: response.data,
                            clinicname: optimizerContext.clinicname
                        }
                    })
                } else {
                    let tt = UserProfile.getSelectedName().split(",")
                    let arr = [tt[1]]
                    axios.get('/getschudule/' + tt[2] + "/" + arr, { headers: authHeader() })
                        .then(response => {
                            UserProfile.setHighlights([response.data[0].solutionId], 'suucess')
                            UserProfile.setSelectedId(response.data[0].solutionId)
                            const { optimizerContext } = this.context
                            this.props.history.push({
                                pathname: '/solutionlist',
                                data: {
                                    gffResponse: response.data,
                                    clinicname: optimizerContext.clinicname
                                }
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                            history.push('/Errorpage')
                        })
                }
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    ApplyConfirmation(event) {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        this.setState({ showApplyModal: true })
    }
    ResetConfirmation(event) {
        event.preventDefault();
        this.setState({ showResetModal: true })
    }
    closeApplyConfirmation() {
        this.setState({ showApplyModal: false })
    }
    closeResetConfirmation() {
        this.setState({ showResetModal: false })
    }
    Apply(event) {
        event.preventDefault();
        this.setState({
            ViewBreakdownList: [],
            showList: false,
            vbSummaryList: [],
            vbBucketList: [],
            showSpinner: true,
        });
        axios.get('/incdecprofiles/' + UserProfile.getSelectedId() + '/' + this.state.whatIfType + '/' + this.state.whatIfBucket + '/' + this.state.whatIfPercent, { headers: authHeader() })
            .then(response => {
                this.prepareUI(response, 'inc');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    Reset(event) {
        event.preventDefault();
        this.setState({
            ViewBreakdownList: [],
            showList: false,
            vbSummaryList: [],
            vbBucketList: [],
            showSpinner: true,
        });
        axios.get('/getviewbreakdown/' + UserProfile.getSelectedId(), { headers: authHeader() })
            .then(response => {
                this.prepareUI(response, '');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    ViewSolution(id) {
        this.setState({ showWhatIfSpinner: true })
        axios.get('/saveoriginalprofiles/' + this.state.solutionid, { headers: authHeader() })
            .then(response => {
                UserProfile.setSelectedName(UserProfile.getClinicId() + "," + (new Date(UserProfile.getScheduleDate()).getDay() + 1) + ","+this.state.oncoSmartClinicId)
                UserProfile.setClinicId(UserProfile.getClinicId())
                UserProfile.setSelectedId(this.state.solutionid)
                history.push('/viewsolutionlist')
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    BackClickHandler(e) {
        UserProfile.setHighlights([UserProfile.getNavParams().selectedId], 'info')
        history.push(UserProfile.getNavigatedFrom())
    }
    render() {
        const { optimizerContext } = this.context
        const columns = [
            {
                title: "#",
                field: "id",
                width: "15%",
            },
            {
                title: "Profile ID",
                field: "pid",
                width: "15%",
            },
            {
                title: "Profile",
                field: "name",
                width: "55%",
            },
            {
                title: "Total Time (minutes)",
                field: "time",
                sorter: "number"
            }
        ]
        const options = {
            movableRows: true
        }
        const rowClasses = (row, rowIndex) => { return row.class }
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (row.isParent) {
                    const t = row.bucket.replaceAll(' ', '').replaceAll(',', '')
                    let newSummary = this.state.vbSummaryList
                    newSummary.forEach(e => {
                        if (e.parent.length > 0) {
                            if (e.parent === t)
                                e.class = e.class === 'd-none' ? 'table-row' : 'd-none'
                        } else {
                            if (e.bucket === row.bucket)
                                e.expcol === '+' ? e.expcol = '-' : e.expcol = '+'
                        }
                    })
                    this.setState({ vbSummaryList: newSummary })
                }
            }
        };
        const summaryColumns = [
            {
                text: "", dataField: "expcol", headerClasses: 'thead-light', headerAlign: 'center', align: 'center', headerStyle: { width: '5%' },
                formatter: (cellContent, row) => (<span role="button">{row.expcol}</span>)
            },
            {
                text: "Bucket", dataField: "bucket", headerClasses: 'thead-light', headerStyle: { width: '70%' },
                formatter: (cellContent, row) => (row.parent.length > 0 ? <span className="ml-3">{row.bucket}</span> : <span>{row.bucket}</span>)
            },
            { text: "Profiles", dataField: "count", headerClasses: 'thead-light', headerAlign: 'right', align: 'right' },
        ]
        return (
            <div>
                <div className="container-fluid">
                    <div className="section-title" >
                        <h4>View Breakdown <small className="text-muted">{UserProfile.getClinicName()} [{moment(this.state.scheduleDate).format('DD/MM/YYYY')} {moment(this.state.scheduleDate).format('dddd')}]</small></h4>
                    </div>
                </div>
                <div className="alert alert-info" role="alert" style={{ display: (this.state.ViewBreakdownList.length === 0 ? 'block' : 'none') }}>
                    Loading data...
                </div>
                <div className="alert alert-info" role="alert" style={{ display: (this.state.ViewBreakdownList.length > 0 ? 'block' : 'none') }}>
                    Showing {this.state.ViewBreakdownList.length} schedules forecasted for {moment(this.state.scheduleDate).format('dddd')}
                </div>
                <div style={{ display: (this.state.showProgressbar ? 'block' : 'none') }}>
                    <ProgressBar striped variant="info" animated now={100} label={'Please wait...'} className="form-check-label font-weight-bold" />
                </div>
                <div className="container-fluid">
                    <div className="row mb-3">
                        <div className="col-6">
                            <div>
                                <div className="card">
                                    <div className="card-header font-weight-bold">
                                        What-If Analysis
                                    </div>
                                    <div className="card-body">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <select className="form-control" name="whatIfType" value={this.state.whatIfType} onChange={this.handleChange}>
                                                    <option value="Increase">Increase</option>
                                                    <option value="Decrease">Decrease</option>
                                                </select>
                                            </div>
                                            <select className="form-control" id="selectedbucket" name="whatIfBucket" value={this.state.whatIfBucket} onChange={this.handleChange}>
                                                <option value="All Buckets">All Buckets</option>
                                                {this.state.vbBucketList.length > 0
                                                    && this.state.vbBucketList.map((clinic, i) => {
                                                        return (
                                                            <option key={i} value={clinic.bucket}>{clinic.bucket} ({clinic.count} {clinic.count > 1 ? 'Buckets' : 'Bucket'})</option>
                                                        )
                                                    })}
                                            </select>
                                            <span className="input-group-text">by</span>
                                            <input type="number" className="form-control text-right" min="0" max="100" placeholder="0" style={{ maxWidth: '90px' }} name="whatIfPercent" value={this.state.whatIfPercent} onChange={this.handleChange} />
                                            <div className="input-group-append">
                                                <span className="input-group-text">%</span>
                                            </div>
                                            <button className="btn btn-primary mx-1" type="button" onClick={this.ApplyConfirmation.bind(this)} data-tip="Increase or Decrease Profiles" disabled={optimizerContext.isRunning}>Apply</button>
                                            <button className="btn btn-secondary" type="button" onClick={this.ResetConfirmation.bind(this)} data-tip="Reset Profile Changes" disabled={(!this.state.resetEnabled) || optimizerContext.isRunning}>Reset</button>
                                        </div>
                                        {this.validator.message('Percentage', this.state.whatIfPercent, 'required|numeric|min:1,num|numeric|max:100,num', { messages: { min: 'Percentage value is invalid.', max: 'Percentage value is invalid.' } })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 offset-2">
                            <div className="card">
                                {<BootstrapTable bootstrap4 keyField='bucket' data={this.state.vbSummaryList} columns={summaryColumns} hover condensed noDataIndication={<Spinner animation="border" />} headerClasses="thead-light" bordered={false} rowClasses={rowClasses} rowEvents={rowEvents} />}
                            </div>
                        </div>
                    </div>
                    <div className="form-row align-items-center">
                        <div className="col-auto my-1">Sort the profile:</div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="durationOpt" id="durationOpt" value="Global" checked={this.state.durationOpt === "Global"} onChange={this.handleChange} />
                            <label className="form-check-label" htmlFor="inlineRadio1">Duration (Global)</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="durationOpt" id="durationOpt" value="Group" checked={this.state.durationOpt === "Group"} onChange={this.handleChange} />
                            <label className="form-check-label" htmlFor="inlineRadio1">Duration (Group)</label>
                        </div>
                        <div className="col">
                            <div className="float-right" style={{ "marginBottom": 5 + "px" }}>
                                <ReactTooltip />
                                <button className="btn btn-primary"
                                    data-tip="View What-if Solution"
                                    disabled={optimizerContext.isRunning || this.state.showWhatIfSpinner}
                                    onClick={this.ViewSolution.bind(this, 1)}
                                    style={{ display: window['runConfig'].whatIf.isEnabled ? 'inline-block' : 'none' }}>
                                    {this.state.showWhatIfSpinner ? <Spinner animation="border" size="sm" /> : null}
                                    What-if Solution
                                </button>
                                <button className="btn btn-primary mx-1" onClick={this.GenerateSolution.bind(this)} data-tip="Generate Solution" disabled={optimizerContext.isRunning}>Generate Solution</button>
                                <button className="btn btn-secondary" data-tip="Go Back" onClick={this.BackClickHandler.bind(this)} disabled={optimizerContext.isRunning}>Back</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: (this.state.showGlobal ? 'block' : 'none') }}>
                        <ReactTabulator
                            ref={this.tableRef}
                            columns={columns}
                            layout="fitColumns"
                            data={this.state.ViewBreakdownList} // here is the state of the table
                            options={options}
                            initialSort={[{ column: "time", dir: "desc" }]}
                        />
                    </div>
                    <div style={{ display: (this.state.showGroup ? 'block' : 'none') }}>
                        <Fragment>
                            <div >
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" type="button" onClick={this.toggle.bind(this, 1, this.state.subtableflag)}>
                                                Lab, MA, MD, Treatment - 30 (35.72%)
                                            </button>
                                        </h5>
                                    </div>
                                </div>
                                {(this.state.subtable === 1) && (
                                    <div id="collapseTwo">
                                        <div className="card-body">
                                            <div className="card-body  table-bordered">
                                                Lab 15 min, MA 15 min, MD 15 min, Treatment 180 min- 5 (16.72%)
                                            </div>
                                            <div className="card-body  table-bordered">
                                                Lab 15 min, MA 15 min, MD 15 min, Treatment 90 min - 10 (33.72%)
                                            </div>
                                            <div className="card-body table-bordered">
                                                Lab 15 min, MA 15 min, MD 15 min, Treatment 30 min - 15 (50.00%)
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link" type="button" onClick={this.toggle.bind(this, 2, this.state.subtableflag)}>
                                                Lab, Treatment - 14 (16.67%)
                                            </button>
                                        </h5>
                                    </div>
                                </div>
                                {(this.state.subtable === 2) && (
                                    <div id="collapseThree">
                                        <div className="card-body">
                                            <div className="card-body  table-bordered">
                                                Lab 15 min, Treatment 120 min - 5 (16.72%)
                                            </div>
                                            <div className="card-body  table-bordered">
                                                Lab 15 min, Treatment 90 min - 10 (33.72%)
                                            </div>
                                            <div className="card-body table-bordered">
                                                Lab 15 min, Treatment 60 min - 15 (50.00%)
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Fragment>
                    </div>
                    <div className="my-1 d-flex justify-content-end">
                        <ReactTooltip />
                        <button className="btn btn-primary"
                            data-tip="View What-if Solution"
                            disabled={optimizerContext.isRunning || this.state.showWhatIfSpinner}
                            onClick={this.ViewSolution.bind(this, 1)}
                            style={{ display: window['runConfig'].whatIf.isEnabled ? 'block' : 'none' }}>
                            {this.state.showWhatIfSpinner ? <Spinner animation="border" size="sm" /> : null}
                            What-if Solution
                        </button>
                        <button type="submit" className="btn btn-primary mx-1" onClick={this.GenerateSolution.bind(this)} data-tip="Generate Solution" disabled={optimizerContext.isRunning}>Generate Solution</button>
                        <button className="btn btn-secondary" data-tip="Go Back" onClick={this.BackClickHandler.bind(this)} disabled={optimizerContext.isRunning}>Back</button>
                    </div>
                </div>
                <Modal show={this.state.showApplyModal} onHide={this.closeApplyConfirmation.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to <b>{this.state.whatIfType}</b> "<b>{this.state.whatIfBucket}</b>" by <b>{this.state.whatIfPercent}</b>%?
                        <p style={{ display: this.state.whatIfType === 'Decrease' && parseInt(this.state.whatIfPercent) > 95 ? 'block' : 'none' }} className="text-warning mt-3">Decreasing profiles by {this.state.whatIfPercent}% may remove all of them</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" data-tip="Yes" onClick={this.Apply.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="secondary" data-tip="No" onClick={this.closeApplyConfirmation.bind(this)}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showResetModal} onHide={this.closeResetConfirmation.bind(this)}>
                    <ReactTooltip />
                    <Modal.Header closeButton>
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to reset profiles?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" data-tip="Yes" onClick={this.Reset.bind(this)}>
                            Yes
                        </Button>
                        <Button variant="secondary" data-tip="No" onClick={this.closeResetConfirmation.bind(this)}>
                            No
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default ViewBreakdown
