import React, { Component } from 'react'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import './Navigation.css'
import AuthService from './authentication/AuthService'
import authHeader from './authentication/authHeader'
import ReactTooltip from 'react-tooltip';
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators from './utils/ocsvalidators'
import axios from "axios";
import UserProfile from './UserProfile'
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            notmatch: '',
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: 'col-sm-12 px-0 text-danger',
            validators: ocsvalidators,
        });
    }
    componentDidMount() {
        AuthService.logout()
        document.getElementById('navbar').style.display = "none"
        document.getElementById('optimizer-alert').style.display = "none"

    }
    componentWillUnmount() {
        document.getElementById('navbar').style.display = "flex";
        document.getElementById('optimizer-alert').style.display = "flex"
    }
    login = (event) => {
        event.preventDefault();
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            return;
        }
        AuthService.login(this.state.email, this.state.password).then(() => {
            let client = JSON.parse(localStorage.getItem('client'));
            if (client === null)
                client = window['runConfig'].fallbackClient

            axios.get('/organizations', { headers: authHeader() })
                .then(orgresp => {
                    let orgs = []
                    const aorgs = orgresp.data.filter(e => e.activeStatus === 'Y')
                    aorgs.forEach(e => {
                        orgs.push({ id: e.codesetItemId, name: e.name, displayOrder: e.displayOrder })
                    })
                    orgs.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                    axios.get('/clients', { headers: authHeader() })
                        .then(resp => {
                            let clients = []
                            const aclients = resp.data.filter(e => e.activeStatus === 'Y')
                            aclients.forEach(e => {
                                clients.push({ id: e.codesetItemId, name: e.name, displayOrder: e.displayOrder, parentId: e.parentId })
                            })
                            clients.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                            axios.get('/aclinicslist/' + client.id, { headers: authHeader() })
                                .then(response => {
                                    axios.get('/clinics', { headers: authHeader() })
                                        .then(res => {
                                            let clinics = []
                                            response.data.forEach(element => {
                                                clinics.push({ id: element.codesetItemId, name: element.name, displayOrder: element.displayOrder })
                                            });
                                            clinics.sort((a, b) => { return a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0 })
                                            client.organizations = orgs
                                            client.clients = clients
                                            client.clinics = clinics
                                            client.allClinics = res.data
                                            UserProfile.setGlobalClientParams(client)
                                            this.props.history.push("/Home");
                                            window.location.reload();
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                        })
                                })
                                .catch(function (error) {
                                    console.log(error);
                                })
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                })
                .catch(function (error) {
                    console.log(error);
                })
        },
            error => {
                this.setState({
                    notmatch: 'Email or Password incorrect'
                });
            }
        );
    }
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    render() {
        return (
            <div>
                <ReactTooltip />
                <nav>
                    <ul>
                        <li>OncoSmart Schedule Optimizer</li>
                    </ul>
                </nav>
                <div className="jumbotron">
                    <div className="container">
                        <Form id="loginForm" method="post" className="login-form" onSubmit={this.login}>
                            <Label for="error" style={{ color: 'red' }}>{this.state.notmatch}</Label>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    id="email"
                                    placeholder="Enter your email address."
                                />
                                {this.validator.message('Email', this.state.email, 'required')}
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    id="password"
                                    placeholder="Enter your password."
                                />
                                {this.validator.message('Password', this.state.password, 'required')}
                            </FormGroup>
                            <Button data-tip="Login">Login</Button>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
