import React, { Component } from 'react'
import axios from 'axios'
import authHeader from '../../authentication/authHeader'
import history from '../../../history';
import moment from 'moment'
import ReactTooltip from 'react-tooltip';
import UserProfile from '../../UserProfile'
import SimpleReactValidator from 'simple-react-validator';
import ocsvalidators, { messages, errorMessageClassName } from '../../utils/ocsvalidators'
import InputMask from 'react-input-mask';
import { beforeMaskedValueChange, RequiredTitle } from '../../utils/Util'
class DonotStartEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            donotstartId: props.match.params.id,
            clinicId: UserProfile.getClinicId(),
            dayOfWeek: 0,
            servicetypeId: 0,
            servicetypeName: '',
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
            durationTiming: [],
        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: errorMessageClassName,
            validators: ocsvalidators,
            messages: messages
        });
    }
    componentDidMount() {
        axios.get('/clinics/' + this.state.clinicId, { headers: authHeader() })
            .then(response1 => {
                axios.get('/timingsforclinic/' + this.state.clinicId, { headers: authHeader() })
                    .then(response2 => {
                        axios.get('/donotstartfor/' + this.state.donotstartId, { headers: authHeader() })
                            .then(response => {
                                let timing = {};
                                response2.data.forEach(element => {
                                    if (element.dayOfWeek === response.data[0].dayOfWeek) {
                                        timing = element;
                                    }
                                });
                                let durationTiming = [];
                                let block = 1;
                                while (moment(timing.clinicOpenTime, "HH:mm") < moment(timing.clinicCloseTime, "HH:mm")) {
                                    let found = false;
                                    response.data.forEach(element => {
                                        if (element.scheduleBlockDuration === block) {
                                            found = true;
                                            durationTiming.push({
                                                donotstartId: element.donotstartId,
                                                block: block,
                                                duration: moment(timing.clinicOpenTime, "HH:mm").format('HH:mm'),
                                                startTime: element.startTime,
                                                endTime: element.endTime
                                            });
                                        }
                                    });
                                    if (found === false) {
                                        durationTiming.push({
                                            block: block,
                                            duration: moment(timing.clinicOpenTime, "HH:mm").format('HH:mm'),
                                            startTime: '',
                                            endTime: ''
                                        });
                                    }
                                    block++;
                                    timing.clinicOpenTime = moment(timing.clinicOpenTime, "HH:mm").add(response1.data.scheduleTimeBlock, 'minutes').format('HH:mm');
                                }
                                axios.get('/servicetypes/' + response.data[0].servicetypeId, { headers: authHeader() })
                                    .then(responsea => {
                                        this.setState({
                                            durationTiming: durationTiming,
                                            clinicScheduleBlock: response1.data.scheduleTimeBlock,
                                            dayOfWeek: response.data[0].dayOfWeek,
                                            servicetypeId: response.data[0].servicetypeId,
                                            servicetypeName: responsea.data.name,
                                            monday: response.data[0].dayOfWeek === 2,
                                            tuesday: response.data[0].dayOfWeek === 3,
                                            wednesday: response.data[0].dayOfWeek === 4,
                                            thursday: response.data[0].dayOfWeek === 5,
                                            friday: response.data[0].dayOfWeek === 6,
                                            saturday: response.data[0].dayOfWeek === 7,
                                            sunday: response.data[0].dayOfWeek === 1,
                                        });
                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                        history.push('/Errorpage')
                                    })
                            })
                            .catch(function (error) {
                                console.log(error);
                                history.push('/Errorpage')
                            })
                    })
                    .catch(function (error) {
                        console.log(error);
                        history.push('/Errorpage')
                    })
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleChange = (event) => {
        let e = event.target;
        this.setState((state) => {
            let ev = e;
            const list = state.durationTiming.map((item) => {
                if (ev.dataset.block == item.block) {
                    if (ev.dataset.name === 'startTime')
                        item.startTime = ev.value;
                    else
                        item.endTime = ev.value;
                }
            });
            return {
                list,
            };
        });
    }
    handleSave = (event) => {
        if (!this.validator.allValid()) {
            this.validator.showMessages();
            alert('There seems to be invalid inputs, please review')
            return;
        }
        let arr = []
        if (this.state.all === true) {
            arr = ['1', '2', '3', '4', '5', '6', '7']
        } else {
            if (this.state.sunday === true) arr.push("1");
            if (this.state.monday === true) arr.push("2");
            if (this.state.tuesday === true) arr.push("3");
            if (this.state.wednesday === true) arr.push("4");
            if (this.state.thursday === true) arr.push("5");
            if (this.state.friday === true) arr.push("6");
            if (this.state.saturday === true) arr.push("7");
        }
        if (arr.length === 0) {
            document.getElementById('checkbox-group').style.borderColor = "red";
            return;
        }

        let donotstarts = []
        arr.forEach((ar) => {
            this.state.durationTiming.map((item, key) => {
                if (document.getElementById('startTime' + key).value.length > 0 && document.getElementById('endTime' + key).value.length > 0) {
                    donotstarts.push({
                        donotstartId: item.donotstartId,
                        clinicId: this.state.clinicId,
                        dayOfWeek: ar,
                        servicetypeId: this.state.servicetypeId,
                        scheduleBlockDuration: key + 1,
                        startTime: document.getElementById('startTime' + key).value,
                        endTime: document.getElementById('endTime' + key).value,
                        createdBy: 'administrator',
                        lastUpdatedBy: 'administrator',
                    })
                }
            })
        })

        axios.put('/donotstart', donotstarts, { headers: authHeader() })
            .then(response => {
                UserProfile.setHighlights(response.data, 'success')
                history.push('/clinic/' + UserProfile.getClinicId() + '/9');
            })
            .catch(function (error) {
                console.log(error);
                history.push('/Errorpage')
            })
    }
    handleCancel = (event) => {
        event.preventDefault();
        UserProfile.setHighlights(this.state.durationTiming.map(e => e.donotstartId), 'info')
        history.push('/clinic/' + UserProfile.getClinicId() + '/9')
    }
    render() {
        return (
            <div className="container-fluid">
                <div className="section-title" >
                    <h4>Edit Do not Start</h4>
                </div>
                <RequiredTitle />
                <div className="section-title containerAdd">
                    <table className="table table-striped table-bordered">
                        <tbody>
                            <tr>
                                <td className="col-auto"><b>Clinic Name</b></td>
                                <td className="col-auto"><b>{UserProfile.getClinicName()}</b></td>
                            </tr>
                            <tr>
                                <td>Service Type</td>
                                <td>
                                    {this.state.servicetypeName}
                                </td>
                            </tr>
                            <tr>
                                <td className="col-auto">Day(s)</td>
                                <td className="form-inline" id="checkbox-group">
                                    <div className="form-row col-auto my-1 d-flex justify-content-start">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="sunday" checked={this.state.sunday}
                                                value={this.state.sunday}
                                                onChange={this.handleChange}
                                                id="sunday" disabled />
                                            <label className="form-check-label" htmlFor="sunday">Sun</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="monday" checked={this.state.monday}
                                                value={this.state.monday}
                                                onChange={this.handleChange}
                                                id="monday" disabled />
                                            <label className="form-check-label" htmlFor="monday">Mon</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="tuesday" checked={this.state.tuesday}
                                                value={this.state.tuesday}
                                                onChange={this.handleChange}
                                                id="tuesday" disabled />
                                            <label className="form-check-label" htmlFor="tuesday">Tue</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="wednesday" checked={this.state.wednesday}
                                                value={this.state.wednesday}
                                                onChange={this.handleChange}
                                                id="wednesday" disabled />
                                            <label className="form-check-label" htmlFor="wednesday">Wed</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="thursday" checked={this.state.thursday}
                                                value={this.state.thursday}
                                                onChange={this.handleChange}
                                                id="thursday" disabled />
                                            <label className="form-check-label" htmlFor="thursday">Thu</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="friday" checked={this.state.friday}
                                                value={this.state.friday}
                                                onChange={this.handleChange}
                                                id="friday" disabled />
                                            <label className="form-check-label" htmlFor="friday">Fri</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="saturday" checked={this.state.saturday}
                                                value={this.state.saturday}
                                                onChange={this.handleChange}
                                                id="saturday" disabled />
                                            <label className="form-check-label" htmlFor="saturday">Sat</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input" type="checkbox"
                                                name="all"
                                                value={this.state.all}
                                                onChange={this.handleChange}
                                                id="all" disabled />
                                            <label className="form-check-label" htmlFor="all">All</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            {
                                this.state.durationTiming.length > 0 && this.state.durationTiming.map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{key + 1} X {this.state.clinicScheduleBlock} = {(key + 1) * this.state.clinicScheduleBlock} Minutes</td>
                                            <td>
                                                <div className="input-group">
                                                    <InputMask value={this.state.durationTiming[key].startTime}
                                                        name={'startTime' + key} onChange={this.handleChange} data-name="startTime" data-block={item.block}
                                                        id={'startTime' + key} className="col-lg-2 form-control" placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">and</span>
                                                    </div>
                                                    <InputMask value={this.state.durationTiming[key].endTime}
                                                        name={'endTime' + key} onChange={this.handleChange} data-name="enndTime" data-block={item.block}
                                                        id={'endTime' + key} className="col-lg-2 form-control" placeholder="HH:MM" beforeMaskedValueChange={beforeMaskedValueChange} mask="99:99" />
                                                </div>
                                                {
                                                    this.validator.message('Timings' + key, this.state.durationTiming[key].startTime || this.state.durationTiming[key].endTime,
                                                        'fromTimeToTimeIsValid:' + this.state.durationTiming[key].startTime.replace(':', '`') + "," + this.state.durationTiming[key].endTime.replace(':', '`'))
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <td colSpan="2">
                                    <ReactTooltip />
                                    <button type="button" onClick={this.handleSave} className="btn btn-primary" data-tip="Save">Save</button>&nbsp;
                                    <button type="button" onClick={this.handleCancel} className="btn btn-secondary" data-tip="Cancel">Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default DonotStartEdit